import React, { useState, createContext, useEffect } from 'react';
import useListAllSettings from '../Hooks/Settings/useListAllSettings';
import useCreateStatistics from '../Hooks/Statistics/useCreateStatistics';
import useReadProfile from '../Hooks/Users/useReadProfile';
import useListLocations from '../Hooks/Settings/useListLocations';
import useListServices from '../Hooks/Settings/useListServices';
import useListChannels from '../Hooks/Settings/useListChannels';
import useListTypes from '../Hooks/Settings/useListTypes';
import useListTimeOfDays from '../Hooks/Settings/useListTimeOfDays';
//import { useMsal } from "@azure/msal-react";

export const StateContext = createContext();

const StateContextProvider = ({children}) => {

    //const { accounts } = useMsal();
    const { profile, fetchingProfile, readProfile } = useReadProfile();

    // return route for user detail (back button returns to this route)
    const [ userDetailBackRoute, setUserDetailBackRoute ] = useState('/')

    // data for value lists etc
    const { locations, listLocations } = useListLocations();
    const { services, listServices } = useListServices();
    const { channels, listChannels } = useListChannels();
    const { types, listTypes } = useListTypes();
    const { timeOfDays, listTimeOfDays } = useListTimeOfDays();

    // filters in shift search
    const [ shiftMonth, setShiftMonth ] = useState(new Date().getMonth() + 1);
    const [ shiftYear, setShiftYear ] = useState(new Date().getFullYear());
    const [ shiftStartDate, setShiftStartDate ] = useState(new Date().toISOString());

    const [ shiftService, setShiftService ] = useState('');
    const [ shiftChannel, setShiftChannel ] = useState('');
    const [ shiftType, setShiftType ] = useState('');
    const [ shiftLocations, setShiftLocations ] = useState([]);
    const [ ownShiftsOnly, setOwnShiftsOnly ] = useState(false);

    // array of shifts for alerts

    const [ selectedShifts, setSelectedShifts ] = useState([]);

    // filters in user search
    //const [ userActiveOnly, setUserActiveOnly ] = useState(true);
    const [ userName, setUserName ] = useState('');
    const [ userLocation, setUserLocation ] = useState('');
    const [ userService, setUserService ] = useState('');
    const [ userType, setUserType ] = useState('');
    const [ userActivityType, setUserActivityType ] = useState('');
    const [ userRemote, setUserRemote ] = useState('');

    // for statistics

    const { createStatistics } = useCreateStatistics();

    const { settings, listAllSettings } = useListAllSettings();
    const [ aforism, setAforism ] = useState('');

    const [ service, setService ] = useState('');
    const [ channel, setChannel ] = useState('');

    const [ contactType, setContactType ] = useState('');
    const [ contactorAge, setContactorAge ] = useState('');
    const [ contactorCount, setContactorCount ] = useState('');
    const [ contactorGender, setContactorGender ] = useState('');
    const [ contactorLanguage, setContactorLanguage ] = useState('');
    const [ previousContact, setPreviousContact ] = useState('');
    const [ contactorRelationToFamily, setContactorRelationToFamily ] = useState('');
    const [ familyChildAgeState, setFamilyChildAgeState ] = useState([]);
    const [ familyForm, setFamilyForm ] = useState('');
    const [ familySocialNetwork, setFamilySocialNetwork ] = useState('');
    const [ dialogue, setDialogue ] = useState(true);
    const [ dialogueDescription, setDialogueDescription ] = useState('');

    const [ previousSupportSought, setPreviousSupportSought ] = useState('');
    const [ previousSupportSources, setPreviousSupportSources ] = useState([]);
    const [ contactorID, setContactorID ] = useState('');
    const [ authorID, setAuthorID ] = useState('');

    const [ subjects, setSubjects ] = useState([]);

    const [ primarySubject, setPrimarySubject ] = useState('');
    const [ contactRelatedToMedia, setContactRelatedToMedia ] = useState('');
    const [ contactDescription, setContactDescription ] = useState('');
    const [ contactPrimaryPurpose, setContactPrimaryPurpose ] = useState([]);

    const [ referralGiven, setReferralGiven ] = useState('');
    const [ referralTargets, setReferralTargets ] = useState([]);

    const [ contactLength, setContactLength ] = useState('');
    const [ userExperience, setUserExperience ] = useState('');
    const [ contactorGaveFeedback, setContactorGaveFeedback ] = useState('');
    const [ contactorFeedbackType, setContactorFeedbackType ] = useState('');
    const [ contactorFeedbackDescriptions, setContactorFeedbackDescriptions ] = useState([]);
    const [ contactorFeedback, setContactorFeedback ] = useState('');

    const [ highlightUnfilledRequiredFields, setHighlightUnfilledRequiredFields ] = useState(false);

    useEffect(() => {
        (async () => {
          await listAllSettings();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async() => {
            //await readUserProfile();
            await readProfile();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const name = (profile.FirstName || '') + ' ' + (profile.LastName || '')

    // Fetch locations
    useEffect(() => {
        (async () => {
            await listLocations();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    
    // Fetch services
    useEffect(() => {
        (async () => {
            await listServices();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // Fetch channels
    useEffect(() => {
        (async () => {
            await listChannels();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // Fetch types
    useEffect(() => {
        (async () => {
            await listTypes();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // Fetch types
    useEffect(() => {
        (async () => {
            await listTimeOfDays();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // set default locations for shift search
    useEffect(() => {
        let tmp = []
        locations.map(item => {
            if(item.Id === profile.LocationId || item.Reference === 'remote'){
                tmp.push(item.Id)
            }
        })
        setShiftLocations(tmp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[locations,profile]);

    const globalState = {
        name,
        settings, listAllSettings,
        aforism, setAforism,

        service, setService,
        channel, setChannel,
        contactType, setContactType,

        contactorAge, setContactorAge,
        contactorCount, setContactorCount,
        contactorGender, setContactorGender,
        contactorLanguage, setContactorLanguage,
        previousContact, setPreviousContact,
        contactorRelationToFamily, setContactorRelationToFamily,
        familyChildAgeState, setFamilyChildAgeState,
        familyForm, setFamilyForm,
        familySocialNetwork, setFamilySocialNetwork,
        dialogue, setDialogue,
        dialogueDescription, setDialogueDescription,

        previousSupportSought, setPreviousSupportSought,
        previousSupportSources, setPreviousSupportSources,
        contactorID, setContactorID,
        authorID, setAuthorID,

        subjects, setSubjects,

        primarySubject, setPrimarySubject,
        contactRelatedToMedia, setContactRelatedToMedia,
        contactDescription, setContactDescription,
        contactPrimaryPurpose, setContactPrimaryPurpose,

        referralGiven, setReferralGiven,
        referralTargets, setReferralTargets,

        contactLength, setContactLength,
        userExperience, setUserExperience,
        contactorGaveFeedback, setContactorGaveFeedback,
        contactorFeedbackType, setContactorFeedbackType,
        contactorFeedbackDescriptions, setContactorFeedbackDescriptions,
        contactorFeedback, setContactorFeedback,

        shiftMonth, setShiftMonth,
        shiftYear, setShiftYear,
        shiftStartDate, setShiftStartDate,
        shiftService, setShiftService,
        shiftChannel, setShiftChannel,
        shiftType, setShiftType,
        shiftLocations, setShiftLocations,
        ownShiftsOnly, setOwnShiftsOnly,

        selectedShifts, setSelectedShifts,

        //userActiveOnly, setUserActiveOnly,
        userName, setUserName,
        userLocation, setUserLocation,
        userService, setUserService,
        userType, setUserType,
        userActivityType, setUserActivityType,
        userRemote, setUserRemote
    }

    const clearContactData = () => {
        setContactType('');
        setContactorAge('');
        setContactorCount('');
        setContactorGender('');
        setContactorLanguage('');
        setPreviousContact('');
        setContactorRelationToFamily('');
        setFamilyChildAgeState([]);
        setFamilyForm('');
        setFamilySocialNetwork('');
        setDialogue(true);
        setDialogueDescription('');

        setPreviousSupportSought('');
        setPreviousSupportSources([]);
        setContactorID('');
        setAuthorID('');

        setSubjects([]);

        setPrimarySubject('');
        setContactRelatedToMedia('');
        setContactDescription('');
        setContactPrimaryPurpose([]);

        setReferralGiven('');
        setReferralTargets([]);

        setContactLength('');
        setUserExperience('');
        setContactorGaveFeedback('');
        setContactorFeedbackType('');
        setContactorFeedbackDescriptions([]);
        setContactorFeedback('');

        setHighlightUnfilledRequiredFields(false);
    }

    const contactInProgress = () => {
        if(contactType) return true;
        
        if(contactorAge) return true;
        if(contactorCount) return true;
        if(contactorGender) return true;
        if(contactorLanguage) return true;
        if(previousContact) return true;
        if(contactorRelationToFamily) return true;
        if(familyChildAgeState.length>0) return true;
        if(familyForm) return true;
        if(familySocialNetwork) return true;
        if(!dialogue) return true;
        if(dialogueDescription !== '') return true;

        if(previousSupportSought) return true;
        if(previousSupportSources.length>0) return true;
        if(contactorID !== '') return true;
        if(authorID !== '') return true;

        if(subjects.length>0) return true;

        if(primarySubject) return true;
        if(contactRelatedToMedia) return true;
        if(contactDescription !== '') return true;
        if(contactPrimaryPurpose.length>0) return true;

        if(referralGiven) return true;
        if(referralTargets.length>0) return true;

        if(contactLength) return true;
        if(userExperience) return true;
        if(contactorGaveFeedback) return true;
        if(contactorFeedbackType) return true;
        if(contactorFeedbackDescriptions.length>0) return true;
        if(contactorFeedback !== '') return true;

        return false;
    }

    const contactDataOK = () => {
        
        if(service){
            let questions = []
            if(service.type === 'vpn') questions = settings.find(item => item.type === 'vpn') || [];
            if(service.type === 'lnpn') questions = settings.find(item => item.type === 'lnpn') || [];
        
            //contact type
            if(questions.contactType.required===true && !contactType) return { result: false, route: '/contact/contacttype' };

            if(contactType.ref==='silentContact') return { result: true }
            if(contactType.ref==='excludedContact') return { result: true }

            //background information
            if(questions.contactorAge.required===true && !contactorAge) return { result: false, route: '/contact/backgroundinformation' };
            if(questions.contactorGender.required===true && !contactorGender) return { result: false, route: '/contact/backgroundinformation' };
            switch(service.type) {
                case "vpn":
                    if(questions.contactorRelationToFamily.required===true && !contactorRelationToFamily) return { result: false, route: '/contact/backgroundinformation' };
                    if(questions.familyChildAgeState.required===true && familyChildAgeState.length===0) return { result: false, route: '/contact/backgroundinformation' };
                    if(questions.familyForm.required===true && !familyForm) return { result: false, route: '/contact/backgroundinformation' };
                    if(questions.familySocialNetwork.required===true && !familySocialNetwork) return { result: false, route: '/contact/backgroundinformation' };
                    break;
                case "lnpn":
                    if(questions.contactorCount.required===true && !contactorCount) return { result: false, route: '/contact/backgroundinformation' };
                    if(service.language==='sv' && questions.contactorLanguage.required===true && !contactorLanguage) return { result: false, route: '/contact/backgroundinformation' };
                    if(channel.ref === 'phone' && questions.previousContact.required===true && !previousContact) return { result: false, route: '/contact/backgroundinformation' };
                    if(channel.ref === 'chat' && questions.previousContact.required===true && !previousContact) return { result: false, route: '/contact/backgroundinformation' };
                    break;
                default:
                    break;
            }

            if(!dialogue) return { result: true }

            //previous support
            if(questions.previousSupportSought.required===true && !previousSupportSought) return { result: false, route: '/contact/previoussupport' };
            if(previousSupportSought.ref==='yes' && questions.previousSupportSources.required===true && previousSupportSources.length===0) return { result: false, route: '/contact/previoussupport' };
            if(channel.ref==='internetletter' && questions.authorID.required===true && authorID==='') return { result: false, route: '/contact/previoussupport' };

            //subjects
            if(subjects.length===0) return { result: false, route: '/contact/themes' };

            if(service.type==='vpn') {
                //subject applies to
                if(questions.subjectAppliesTo.required===true && subjects.find(item => item.subjectAppliesTo.length===0)) return { result: false, route: '/contact/themes' };
                //if subject applies to child and child age is required
                if(questions.subjectChildAge.required===true && 
                    subjects.find(item =>
                        item.subjectAppliesTo.findIndex(item => item.ref==='child')!==-1 && 
                        item.childAge.length===0 
                    )
                ) return { result: false, route: '/contact/themes' };
                //if subject applies to child and child gender is required
                if(questions.subjectChildGender.required===true && 
                    subjects.find(item =>
                        item.subjectAppliesTo.findIndex(item => item.ref==='child')!==-1 && 
                        item.childGender.length===0 
                    )
                ) return { result: false, route: '/contact/themes' };
                //if subject applies to children and children group is required
                if(questions.subjectChildrenGroup.required===true && 
                    subjects.find(item =>
                        item.subjectAppliesTo.findIndex(item => item.ref==='children')!==-1 && 
                        item.childrenGroup.length===0 
                    )
                ) return { result: false, route: '/contact/themes' };
                //if subject applies to adult and adult is required
                if(questions.subjectAdult.required===true && 
                    subjects.find(item =>
                        item.subjectAppliesTo.findIndex(item => item.ref==='adult')!==-1 && 
                        item.adult.length===0 
                    )
                ) return { result: false, route: '/contact/themes' };
            }

            //specific information
            if(questions.primarySubject.required===true && !primarySubject) return { result: false, route: '/contact/specificinformation' };
            if(questions.contactRelatedToMedia.required===true && !contactRelatedToMedia) return { result: false, route: '/contact/specificinformation' };
            if(contactRelatedToMedia.ref==='yes' && subjects.filter(item => item.isRelatedToMedia===true).length===0) return { result: false, route: '/contact/specificinformation' };
            if(questions.contactDescription.required===true && contactDescription==='') return { result: false, route: '/contact/specificinformation' };
            if(contactRelatedToMedia.ref==='yes' && contactDescription==='') return { result: false, route: '/contact/specificinformation' };
            if(subjects.filter(item => item.descriptionRequired===true).length>0 && contactDescription==='') return { result: false, route: '/contact/specificinformation' };
            if(questions.contactPrimaryPurpose.required===true && contactPrimaryPurpose.length===0) return { result: false, route: '/contact/specificinformation' };

            //referral
            if(questions.referralGiven.required===true && !referralGiven) return { result: false, route: '/contact/referral' };
            if(referralGiven.ref==='yes' && questions.referralTargets.required===true && referralTargets.length===0) return { result: false, route: '/contact/referral' };

            //retrospective
            if(questions.contactLength.required===true && !contactLength) return { result: false, route: '/contact/retrospective' };
            if(questions.userExperience.required===true && !userExperience) return { result: false, route: '/contact/retrospective' };
            if(questions.contactorGaveFeedback.required===true && !contactorGaveFeedback) return { result: false, route: '/contact/retrospective' };
            if(contactorGaveFeedback.ref==='yes') {
                if(questions.contactorFeedbackType.required===true && !contactorFeedbackType) return { result: false, route: '/contact/retrospective' };
                if(contactorFeedbackType.ref==='positive' && questions.contactorFeedbackDescriptions.required===true && contactorFeedbackDescriptions.length===0) return { result: false, route: '/contact/retrospective' };
                if(questions.contactorFeedback.required===true && contactorFeedback==='') return { result: false, route: '/contact/retrospective' };
            };

            return { result: true };
        } else { 
            return { result: false }
        }
    }

    const saveContactData = async (history) => {
        try {
        
            // Validate
            if(contactDataOK().result){
    
                // Create object
                let statistic = {};
                let result = null;

                statistic.service = service
                statistic.channel = channel
                statistic.contactType = contactType || {"ref": "", "name": { "fi": "" }}

                //if contact type is not conversation contact, no other information is needed -> clear data
                //if(contactType.ref!=='conversationContact') clearContactData();

                if(contactType.ref==='conversationContact'){
                    statistic.contactorAge = contactorAge || {"ref": "", "name": { "fi": "" }}
                    statistic.contactorGender = contactorGender || {"ref": "", "name": { "fi": "" }}

                    if(service.type==='vpn'){
                        if(contactorRelationToFamily && !contactorRelationToFamily.description) contactorRelationToFamily.description = "";
                        if(familyForm && !familyForm.description) familyForm.description = "";
                        
                        statistic.contactorRelationToFamily = contactorRelationToFamily || {"ref": "", "name": { "fi": "" }, "description": ""}
                        statistic.familyChildAgeState = familyChildAgeState
                        statistic.familyForm = familyForm || {"ref": "", "name": { "fi": "" }, "description": ""}
                        statistic.familySocialNetwork = familySocialNetwork || {"ref": "", "name": { "fi": "" }}
                    }
                    if(service.type==='lnpn'){
                        statistic.contactorCount = contactorCount || {"ref": "", "name": { "fi": "" }}
                        statistic.previousContact = previousContact || {"ref": "", "name": { "fi": "" }}
                        statistic.language = contactorLanguage || {"ref": "", "name": { "fi": "" }}
                        statistic.dialogue = dialogue
                        statistic.dialogueDescription = dialogueDescription
                    }
                } else {
                    statistic.contactorAge = {"ref": "", "name": { "fi": "" }}
                    statistic.contactorGender = {"ref": "", "name": { "fi": "" }}

                    if(service.type==='vpn'){
                        statistic.contactorRelationToFamily = {"ref": "", "name": { "fi": "" }, "description": ""}
                        statistic.familyChildAgeState = []
                        statistic.familyForm = {"ref": "", "name": { "fi": "" }, "description": ""}
                        statistic.familySocialNetwork = {"ref": "", "name": { "fi": "" }}
                    }
                    if(service.type==='lnpn'){
                        statistic.contactorCount = {"ref": "", "name": { "fi": "" }}
                        statistic.previousContact = {"ref": "", "name": { "fi": "" }}
                        statistic.language = {"ref": "", "name": { "fi": "" }}
                        statistic.dialogue = true
                        statistic.dialogueDescription = ""
                    }
                }

                //if there was no dialog in contact, no other information is needed -> clear data
                //if(!dialogue) clearContactData();

                if(contactType.ref==='conversationContact' && dialogue){

                    statistic.previousSupportSought = previousSupportSought || {"ref": "", "name": { "fi": "" }}
                    statistic.previousSupportSources = previousSupportSources
                    statistic.previousSupportContactorId = contactorID ? contactorID : '0'
                    statistic.previousSupportAuthorId = authorID ? authorID : '0'

                    // if subject is not related to media, remove media classification
                    let subs = JSON.parse(JSON.stringify(subjects));
                    for(let i=0;i<subs.length;i++){
                        if(!subs[i].isRelatedToMedia){
                            subs[i].mediaClassification.ref="";
                            subs[i].mediaClassification.name.fi="";
                        }
                    }
                    if(service.type==='vpn'){
                        for(let i=0;i<subs.length;i++){
                            // if subject is not related to children, remove child theme
                            if(subs[i].subjectAppliesTo.findIndex(item => item.ref==='child' || item.ref==='children')===-1){
                                subs[i].childTheme.ref="";
                                subs[i].childTheme.name.fi="";
                            }
                            // if subject is not related to child, remove additional information about child
                            if(subs[i].subjectAppliesTo.findIndex(item => item.ref==='child')===-1){
                                subs[i].childAge=[];
                                subs[i].childGender=[];
                            }
                            // if subject is not related to child group, remove additional information about child group
                            if(subs[i].subjectAppliesTo.findIndex(item => item.ref==='children')===-1){
                                subs[i].childrenGroup=[];
                            }
                            // if subject is not related to adult, remove adult theme
                            if(subs[i].subjectAppliesTo.findIndex(item => item.ref==='adult')===-1){
                                subs[i].adultTheme.ref="";
                                subs[i].adultTheme.name.fi="";
                                subs[i].adult=[];
                            }
                        }
                    }
                    statistic.themeSubjects = subs
                    statistic.primarySubject = primarySubject || {"ref": "", "name": { "fi": "" }}
                    statistic.contactIsRelatedToMedia = contactRelatedToMedia || {"ref": "", "name": { "fi": "" }}
                    statistic.contactDescription = contactDescription
                    statistic.contactPrimaryPurpose = contactPrimaryPurpose
                    statistic.referralGiven = referralGiven || {"ref": "", "name": { "fi": "" }}
                    statistic.referralTargets = referralTargets
                    statistic.contactLength = contactLength || {"ref": "", "name": { "fi": "" }}
                    statistic.userExperience = userExperience || {"ref": "", "name": { "fi": "" }}
                    statistic.contactorGaveFeedback = contactorGaveFeedback || {"ref": "", "name": { "fi": "" }}
                    statistic.contactorFeedbackType =  contactorFeedbackType || {"ref": "", "name": {"fi": ""}}
                    statistic.contactorFeedbackDescriptions = contactorFeedbackDescriptions
                    statistic.contactorFeedback = contactorFeedback
                } else {
                    statistic.previousSupportSought = {"ref": "", "name": { "fi": "" }}
                    statistic.previousSupportSources = []
                    statistic.previousSupportContactorId = '0'
                    statistic.previousSupportAuthorId = '0'
                    statistic.themeSubjects = []
                    statistic.primarySubject = {"ref": "", "name": { "fi": "" }}
                    statistic.contactIsRelatedToMedia = {"ref": "", "name": { "fi": "" }}
                    statistic.contactDescription = ""
                    statistic.contactPrimaryPurpose = []
                    statistic.referralGiven = {"ref": "", "name": { "fi": "" }}
                    statistic.referralTargets = []
                    statistic.contactLength = {"ref": "", "name": { "fi": "" }}
                    statistic.userExperience = {"ref": "", "name": { "fi": "" }}
                    statistic.contactorGaveFeedback = {"ref": "", "name": { "fi": "" }}
                    statistic.contactorFeedbackType =  {"ref": "", "name": {"fi": ""}}
                    statistic.contactorFeedbackDescriptions = []
                    statistic.contactorFeedback = ""
                }
                statistic.createdBy = profile.AzureADUserId || ""
                statistic.creatorName = name || ""
                statistic.creatorRole = profile.IsOwner ? 'työntekijä' : 'päivystäjä'
                statistic.created = new Date().toISOString()
                statistic.city = profile.LocationName || ""
        
                if(service.type==='vpn') result = await createStatistics('vpn', statistic)
                if(service.type==='lnpn') result = await createStatistics('lnpn', statistic)
        
                //console.log("statistic");
                //console.log(statistic);
        
                //console.log("result");
                //console.log(result);
        
                if(result.status === 201) {
                    history.push("/contact/contacttype");
                    clearContactData();
                    setHighlightUnfilledRequiredFields(false);
                }
            } else {
                history.push(contactDataOK().route);
            }
    
        } catch (error) {
            if(error && error.response) return(error.response);
            return(error);
        }
    }

    return (
        <StateContext.Provider value={{ profile, fetchingProfile, userDetailBackRoute, setUserDetailBackRoute, locations, services, channels, types, timeOfDays, globalState, highlightUnfilledRequiredFields, setHighlightUnfilledRequiredFields, clearContactData, contactDataOK, contactInProgress, saveContactData }}>
            {children}
        </StateContext.Provider>
    );

}

export default StateContextProvider;

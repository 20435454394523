import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Divider, Button, Message, Grid } from 'semantic-ui-react';
import useReadUser from '../../Hooks/Users/useReadUser';
import useReadBadgeOfMerit from '../../Hooks/Users/BadgeOfMerit/useReadBadgeOfMerit';
import useDeleteBadgeOfMerit from '../../Hooks/Users/BadgeOfMerit/useDeleteBadgeOfMerit';
import { useParams } from 'react-router-dom';
import { YesNoModal } from '../../Reusable/Modals/GeneralModal';

const BadgeOfMeritDetail = (props) => {

    const { t } = useTranslation();
    const { badgeOfMerit, readBadgeOfMerit } = useReadBadgeOfMerit();
    const { user, readUser } = useReadUser();
    const { deleteBadgeOfMerit } = useDeleteBadgeOfMerit();
    const { userId, badgeOfMeritId } = useParams();
    const [ modalOpen, setModalOpen ] = useState(false);

    //console.log(badgeOfMerit)

    useEffect(() => {
        (async() => {
            await readBadgeOfMerit(userId,badgeOfMeritId);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async() => {
            if(badgeOfMerit && badgeOfMerit.data && badgeOfMerit.data.UserId){
                await readUser(badgeOfMerit.data.UserId);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [badgeOfMerit]);

    const delBadgeOfMerit = () => {
        (async () => {
            let result = null;
            result = await deleteBadgeOfMerit(userId,badgeOfMeritId);
            if(result.status === 204){
                props.history.goBack();
            }
        })();
    }

    return (
        <div>
            <YesNoModal 
                type='delete'
                header={t('modal.confirmDelete')}
                text={t('modal.confirmDeleteBadgeOfMerit')}
                method={delBadgeOfMerit}
                open={modalOpen}
                setOpen={setModalOpen}
            />
           <Container>
                <h1>{t('heading.badgeOfMerit')}</h1>
                { badgeOfMerit && badgeOfMerit.data &&
                    <>
                    <h2>{t('heading.user')}</h2>
                    <div>{user.data && user.data.FirstName} {user.data && user.data.LastName}</div>

                    <h2>{t('badgeOfMerit.date')}</h2>
                    <div>{new Date(badgeOfMerit.data.Date).toLocaleDateString()}</div>

                    <h2>{t('badgeOfMerit.name')}</h2>
                    <div>{badgeOfMerit.data.BadgeOfMeritName}</div>

                    <h2>{t('badgeOfMerit.description')}</h2>
                    <div>{badgeOfMerit.data.Description}</div>
                    </>
                }

            </Container>
            <Divider/>
            <Button onClick={() => props.history.push('/user/' + userId + '/badgeofmerit/edit/' + badgeOfMeritId)}>{t('general.edit')}</Button>
            <Button onClick={() => setModalOpen(true)}>{t('general.delete')}</Button>
            <Button onClick={() => props.history.goBack()}>{t('general.back')}</Button>
        </div>
    )

}

export default BadgeOfMeritDetail;
import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import SelectService from '../Pages/SelectService';
import ContactType from '../Pages/Contact/ContactType';
import BackgroundInformation from '../Pages/Contact/BackgroundInformation';
import PreviousSupport from '../Pages/Contact/PreviousSupport';
import Themes from '../Pages/Contact/Themes';
import SpecificInformation from '../Pages/Contact/SpecificInformation';
import Referral from '../Pages/Contact/Referral';
import Retrospective from '../Pages/Contact/Retrospective';
import UserList from '../Pages/User/UserList';
import UserDetail from '../Pages/User/UserDetail';
import CreateUser from '../Pages/User/CreateUser';
import EditUser from '../Pages/User/EditUser';
import PageNotFound from '../Pages/PageNotFound';
import AccessDenied from '../Pages/AccessDenied';
import ShiftManagement from '../Pages/Shift/ShiftManagement';
import ShiftList from '../Pages/Shift/ShiftList';
import CreateShift from '../Pages/Shift/CreateShift';
import UpdateShift from '../Pages/Shift/UpdateShift';
import ShiftDetail from '../Pages/Shift/ShiftDetail';
import AddUserToShift from '../Pages/Shift/AddUserToShift';
import Start from '../Pages/Alert/Start';
import Alerts from '../Pages/Alert/Alerts';
import AddShifts from '../Pages/Alert/AddShifts';
import OneToOneList from '../Pages/OneToOne/OneToOneList';
import CreateOneToOne from '../Pages/OneToOne/CreateOneToOne';
import OneToOneDetail from '../Pages/OneToOne/OneToOneDetail';
import EditOneToOne from '../Pages/OneToOne/EditOneToOne';
import BadgeOfMeritList from '../Pages/BadgeOfMerit/BadgeOfMeritList';
import CreateBadgeOfMerit from '../Pages/BadgeOfMerit/CreateBadgeOfMerit';
import BadgeOfMeritDetail from '../Pages/BadgeOfMerit/BadgeOfMeritDetail';
import EditBadgeOfMerit from '../Pages/BadgeOfMerit/EditBadgeOfMerit';
import BOMUserList from '../Pages/BadgeOfMerit/UserList';
import OtherParticipationList from '../Pages/OtherParticipation/OtherParticipationList';
import CreateOtherParticipation from '../Pages/OtherParticipation/CreateOtherParticipation';
import OtherParticipationDetail from '../Pages/OtherParticipation/OtherParticipationDetail';
import EditOtherParticipation from '../Pages/OtherParticipation/EditOtherParticipation';
import ReminderList from '../Pages/Reminder/ReminderList';
import CreateReminder from '../Pages/Reminder/CreateReminder';
import ReminderDetail from '../Pages/Reminder/ReminderDetail';
import EditReminder from '../Pages/Reminder/EditReminder';
import ActivityStatus from '../Pages/User/ActivityStatus';
import { StateContext } from '../Context/StateContext';

function Routes() {

  const { profile } = useContext(StateContext);

  return (
    <>
    <Switch>
      <Route exact path='/' component={SelectService}></Route>

      <Route exact path='/contact/contacttype' component={ContactType}></Route>
      <Route exact path='/contact/backgroundinformation' component={BackgroundInformation}></Route>
      <Route exact path='/contact/previoussupport' component={PreviousSupport}></Route>
      <Route exact path='/contact/themes' component={Themes}></Route>
      <Route exact path='/contact/specificinformation' component={SpecificInformation}></Route>
      <Route exact path='/contact/referral' component={Referral}></Route>
      <Route exact path='/contact/retrospective' component={Retrospective}></Route>

      <Route exact path='/user/profile/:id'><UserDetail back={true} /></Route>
      <Route exact path='/user/detail/:id' component={UserDetail}></Route>

      {/* user views for admins only */}
      <Route exact path='/user/list' component={profile.IsOwner ? UserList : AccessDenied}></Route>
      <Route exact path='/user/new' component={profile.IsOwner ? CreateUser : AccessDenied}></Route>
      <Route exact path='/user/edit/:id' component={profile.IsOwner ? EditUser : AccessDenied}></Route>

      {/* one to ones for admins only */}
      <Route exact path='/user/:id/onetoone/list' component={profile.IsOwner ? OneToOneList : AccessDenied}></Route>
      <Route exact path='/user/:id/onetoone/new' component={profile.IsOwner ? CreateOneToOne : AccessDenied}></Route>
      <Route exact path='/user/:userId/onetoone/detail/:oneToOneId' component={profile.IsOwner ? OneToOneDetail : AccessDenied}></Route>
      <Route exact path='/user/:userId/onetoone/edit/:oneToOneId' component={profile.IsOwner ? EditOneToOne : AccessDenied}></Route>

      {/* badge of merits for admins only */}
      <Route exact path='/user/:id/badgeofmerit/list' component={profile.IsOwner ? BadgeOfMeritList : AccessDenied}></Route>
      <Route exact path='/user/:id/badgeofmerit/new' component={profile.IsOwner ? CreateBadgeOfMerit : AccessDenied}></Route>
      <Route exact path='/user/:userId/badgeofmerit/detail/:badgeOfMeritId' component={profile.IsOwner ? BadgeOfMeritDetail : AccessDenied}></Route>
      <Route exact path='/user/:userId/badgeofmerit/edit/:badgeOfMeritId' component={profile.IsOwner ? EditBadgeOfMerit : AccessDenied}></Route>
      <Route exact path='/user/badgeofmerit/list' component={profile.IsOwner ? BOMUserList : AccessDenied}></Route>

      {/* other participations for admins only */}
      <Route exact path='/user/:id/participation/list' component={profile.IsOwner ? OtherParticipationList : AccessDenied}></Route>
      <Route exact path='/user/:id/participation/new' component={profile.IsOwner ? CreateOtherParticipation : AccessDenied}></Route>
      <Route exact path='/user/:userId/participation/detail/:participationId' component={profile.IsOwner ? OtherParticipationDetail : AccessDenied}></Route>
      <Route exact path='/user/:userId/participation/edit/:participationId' component={profile.IsOwner ? EditOtherParticipation : AccessDenied}></Route>

      {/* reminders for admins only */}
      <Route exact path='/user/:id/reminder/list' component={profile.IsOwner ? ReminderList : AccessDenied}></Route>
      <Route exact path='/user/:id/reminder/new' component={profile.IsOwner ? CreateReminder : AccessDenied}></Route>
      <Route exact path='/user/:userId/reminder/detail/:reminderId' component={profile.IsOwner ? ReminderDetail : AccessDenied}></Route>
      <Route exact path='/user/:userId/reminder/edit/:reminderId' component={profile.IsOwner ? EditReminder : AccessDenied}></Route>

      {/* activity status for admins only */}
      <Route exact path='/user/:id/activity' component={profile.IsOwner ? ActivityStatus : AccessDenied}></Route>

      <Route exact path='/shift/list' component={ShiftList}></Route>

      {/* shift views for admins only */}
      <Route exact path='/shift/admin' component={profile.IsOwner ? ShiftManagement : AccessDenied}></Route>
      <Route exact path='/shift/new' component={profile.IsOwner ? CreateShift : AccessDenied}></Route>
      <Route exact path='/shift/new/:id' component={profile.IsOwner ? CreateShift : AccessDenied}></Route>
      <Route exact path='/shift/edit/:id' component={profile.IsOwner ? UpdateShift : AccessDenied}></Route>
      <Route exact path='/shift/detail/:id' component={profile.IsOwner ? ShiftDetail : AccessDenied}></Route>
      <Route exact path='/shift/:shiftId/adduser/:typeId' component={profile.IsOwner ? AddUserToShift : AccessDenied}></Route>

      {/* alerts for admins only */}
      <Route exact path='/shift/alert/start' component={profile.IsOwner ? Start : AccessDenied}></Route>
      <Route exact path='/shift/alert/create' component={profile.IsOwner ? Alerts : AccessDenied}></Route>
      <Route exact path='/shift/alert/add' component={profile.IsOwner ? AddShifts : AccessDenied}></Route>

      <Route exact path='/accessdenied' component={AccessDenied}></Route>

      <Route component={PageNotFound} />
    </Switch>
    </>
  );
}


export default Routes;

import React, { useState, useEffect, useContext } from 'react';
import { Form, Checkbox, Divider, Button, Grid, Popup } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { DateInput } from 'semantic-ui-calendar-react';
import { useTranslation } from 'react-i18next';
import useReadShift from '../../Hooks/Shifts/useReadShift';
import useListShiftTypes from '../../Hooks/Shifts/useListShiftTypes';
import useListUsers from '../../Hooks/Users/useListUsers';
import { StateContext } from '../../Context/StateContext';
import useCreateShift from '../../Hooks/Shifts/useCreateShift';
import useCreateShiftType from '../../Hooks/Shifts/useCreateShiftType';
import { MessageContext } from '../../Context/MessageContext';
import Select from 'react-select';

const CreateShift = (props) => {

    const { t } = useTranslation()
    const { id } = useParams();
    const [ date, setDate ] = useState(null)
    const [ timing, setTiming ] = useState('')
    const [ startTime, setStartTime ] = useState('')
    const [ endTime, setEndTime ] = useState('')
    const [ shiftTypesSelection, setShiftTypesSelection ] = useState([])
    const [ location, setLocation ] = useState('')
    const [ supervisor, setSupervisor ] = useState('')
    const [ description, setDescription ] = useState('')
    const [ isSaving, setIsSaving ] = useState(false);
    const { shift, readShift } = useReadShift();
    const { shiftTypes, listShiftTypes } = useListShiftTypes();
    const { users, listUsers } = useListUsers();
    const { locations, types, timeOfDays } = useContext(StateContext)
    const { addMessage, removeMessage, messages } = useContext(MessageContext);
    const { createShift } = useCreateShift();
    const { createShiftType } = useCreateShiftType();

    // Fetch shift
    useEffect(() => {
        (async () => {
            if(id){
                await readShift(id);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[id]);

   // Fetch shift types
   useEffect(() => {
        (async () => {
            if(id){
                await listShiftTypes(id);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[shift]);

    // Set shift data
    useEffect(() => {
        (async () => {
            if (shift && shift.Id) {
                const startTime = new Date(shift.StartTime).getHours() + '.' + new Date(shift.StartTime).getMinutes().toString().padStart(2,'0')
                const endTime = new Date(shift.EndTime).getHours() + '.' + new Date(shift.EndTime).getMinutes().toString().padStart(2,'0')
                setDate(new Date(shift.Date).toLocaleDateString());
                setStartTime(startTime);
                setEndTime(endTime);
                setSupervisor(shift.InstructorId);
                setLocation(shift.LocationId);
                setDescription(shift.Description)
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shift]);

    // Set shift types to shift data
    useEffect(() => {
        (async () => {
            let newArray = []
            shiftTypes.map(item => {
                newArray.push({type: item.TypeId, amount: item.ShiftUserCount})
                return item;
            })
            setShiftTypesSelection(newArray)
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[shiftTypes]);

    // Fetch users
    useEffect(() => {
        (async () => {
            await listUsers();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    let timeOfDayOptions = []
    timeOfDays.map(i => {
        const option = {
            key: i.Id,
            text: t('shift.' + i.Rerefence),
            value: i.Rerefence
        }
        timeOfDayOptions.push(option);
        return i;
    })

    let locationOptions = []
    locations.map(i => {
        const option = {
            key: i.Id,
            text: i.Name,
            value: i.Id
        }
        locationOptions.push(option);
        return i;
    })

    let typeOptions = []
    types.map(i => {
        const option = {
            key: i.Id,
            text: i.Name,
            value: i.Id
        }
        typeOptions.push(option);
        return i;
    })

    let supervisors = []
    users.filter(item => item.IsEmployee).map(item => {
        const supervisor = {
            key: item.Id,
            label: item.LastName + ' ' + item.FirstName,
            value: item.Id
        }
        supervisors.push(supervisor);
        return item;
    })

    const setTimes = (value) => {
        setTiming(value);
        if(value==='other'){
            setStartTime('')
            setEndTime('')
        } else {
            const startDate = timeOfDays.find(item => item.Rerefence===value).StartTime
            const startTime = new Date(startDate).getUTCHours().toString().padStart(2,'0') + '.' + new Date(startDate).getUTCMinutes().toString().padStart(2,'0')
            const endDate = timeOfDays.find(item => item.Rerefence===value).EndTime
            const endTime = new Date(endDate).getUTCHours().toString().padStart(2,'0') + '.' + new Date(endDate).getUTCMinutes().toString().padStart(2,'0')
            setStartTime(startTime)
            setEndTime(endTime)
        }
    }

    const setTypes = (value) => {
        if(shiftTypesSelection.find(item => item.type === value)){
            const newArray = shiftTypesSelection.filter(item => item.type !== value)
            // uncheck -> remove item from array
            setShiftTypesSelection(newArray)
        } else {
            //check -> add item to array
            const newObj = {type: value }
            setShiftTypesSelection(shiftTypesSelection.concat(newObj))
        }
    }

    const setPersonsOnCall = (type,value) => {
        const obj = shiftTypesSelection.filter(item => item.type !== type)
        if(value){
            obj.push({type: type, amount: parseInt(value)})
        } else {
            obj.push({type: type})
        }
        setShiftTypesSelection(obj)
    }

    const validateTime = (time) => {
        const timeReg = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):|.[0-5][0-9]$/
        return time.match(timeReg)
    }

    const handleSave = async () => {


        if(isSaving) return false;
        setIsSaving(true)

        messages.map(msg => removeMessage(msg.id));

        //validate
        if(!date || !startTime || !endTime || !location || !supervisor || shiftTypesSelection.length === 0 || shiftTypesSelection.find(item => item.amount === undefined)) {
            addMessage({type: "error", header: t('message.fillInRequiredInformation'), body: t('message.requiredMarkedWithAsterisk')})
            window.scrollTo(0, 0)
        } else if(!validateTime(startTime) || !validateTime(endTime)) {
            addMessage({type: "error", header: t('message.checkTimeFormat'), body: t('message.rightTimeFormat')})
            window.scrollTo(0, 0)
        } else {
            //create shift data
            const dates = date + '.' + startTime.replace(':','.') + '.' + endTime.replace(':','.')
            const dateArray = dates.split('.')
            let data = {
                "date": new Date(dateArray[2] + '-' + dateArray[1].padStart(2,'0') + '-' + dateArray[0].padStart(2,'0')).toISOString(),
                "startTime": new Date(dateArray[2],dateArray[1]-1,dateArray[0],dateArray[3] || '00',dateArray[4] || '00').toISOString(),
                "endTime": new Date(dateArray[2],dateArray[1]-1,dateArray[0],dateArray[5] || '00',dateArray[6] || '00').toISOString(),
                "locationId": location,
                "instructorId": supervisor,
                "description": description
            }

            //save shift
            let result = null;
            result = await createShift(data);

            const shiftId = result.data.id

            if(result.status === 201 && shiftId) {
                // create shift types
                shiftTypesSelection.map(item => {
                    if(item.type && item.amount){
                        data = {
                            "typeId": item.type,
                            "shiftUserCount": item.amount
                        }
                        result = createShiftType(shiftId, data, false);
                    }
                    return item;
                })
                props.history.goBack();
            }
        }
        setIsSaving(false)
    }

    return (
        <div className='shiftform'>
            <h1>{t('heading.shift')}</h1>
            <Form>
                <h2>{t('shift.date')}<sup><i className='icon asterisk'></i></sup></h2>
                <DateInput
                    localization='fi'
                    dateFormat={'DD.MM.YYYY'}
                    value={date || ""}
                    iconPosition="right"
                    onChange={(ev, {value}) => setDate(value) }
                    closable={true}
                    popupPosition='bottom left'
                />
                <div className='field'>
                    <h2>{t('shift.timing')}</h2>
                    <Form.Select
                        value={timing}
                        onChange={(e,{value}) => setTimes(value)}
                        options={timeOfDayOptions}
                    />
                    <Grid>
                        <Grid.Column width={8}>
                            <div><h2>{t('shift.starttime')}<sup><i className='icon asterisk'></i></sup></h2></div>
                            <Form.Input
                                onChange={(e) => setStartTime(e.target.value)}
                                value={startTime}
                            />
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <h2>{t('shift.endtime')}<sup><i className='icon asterisk'></i></sup></h2>
                            <Form.Input
                                onChange={(e) => setEndTime(e.target.value)}
                                value={endTime}
                            />
                        </Grid.Column>
                    </Grid>
                </div>
                
                <div className='field'>
                    <h2>{t('shift.type')}<sup><i className='icon asterisk'></i></sup></h2>
                
                    {typeOptions.map(item =>
                        <Form.Group key={item.key}>
                            <Form.Field
                                label={t(item.text)}
                                control={Checkbox}
                                width={8}
                                onChange={(e,{value}) => setTypes(value)}
                                value={item.value}
                                checked={shiftTypesSelection.find(t => t.type === item.value)!==undefined}
                            />
                            {shiftTypesSelection.find(t => t.type === item.value) ?
                                <>
                                    <div>Max.</div>
                                    <Form.Input 
                                        value={shiftTypesSelection.find(t => t.type === item.value).amount}
                                        onChange={(e) => setPersonsOnCall(item.value,e.target.value)}
                                        className='small-text'
                                    />
                                    <div>{t('shift.numberOfPersonsOnCall')}<sup><i className='icon asterisk'></i></sup><Popup content={t('shift.insertUserCount')} trigger={<i className='info icon'></i>} /></div>
                                </>
                            :
                                <><div className="hidden"><Form.Input /></div><div className="hidden">{t('shift.numberOfPersonsOnCall')}</div></>
                            }
                        </Form.Group>
                    )}
                </div>
                
                <h2>{t('shift.location')}<sup><i className='icon asterisk'></i></sup></h2>
                <Form.Select
                    value={location}
                    onChange={(e,{value}) => setLocation(value)}
                    options={locationOptions}
                />

                <div className='field'>
                    <h2>{t('shift.supervisor')}<sup><i className='icon asterisk'></i></sup></h2>
                    <Select
                        value={supervisors.filter(item => item.value === supervisor)}
                        onChange={person => setSupervisor(person.value)}
                        options={supervisors}
                        placeholder={t('user.searchWithLastnameOrFirstname')}
                        menuPlacement='top'
                    />
                </div>
                <Form.TextArea
                    label={t('shift.description')}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </Form>
            <Divider/>
            {isSaving ?
                <Button disabled>{t('general.save')}</Button>
            :
                <Button onClick={() => handleSave()}>{t('general.save')}</Button>
            }
            <Button onClick={() => props.history.goBack()}>{t('general.cancel')}</Button>
        </div>
    )

}

export default CreateShift;
import React, { useContext, useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Segment, Menu, Sidebar, Image } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useMsal } from "@azure/msal-react";
import { StateContext } from '../Context/StateContext';
import { MessageContext } from '../Context/MessageContext';
import { YesNoModal } from '../Reusable/Modals/GeneralModal';

const MobileNavigation = (props) => {

    const { children, mobileMenuOpen, setMobileMenuOpen } = props;
    const { instance } = useMsal();
    const { globalState, clearContactData, contactInProgress, profile } = useContext(StateContext);
    const { addMessage } = useContext(MessageContext);
    const { t } = useTranslation();
    const [ cancelModalOpen, setCancelModalOpen] = useState(false);
    const [ warningModalOpen, setWarningModalOpen] = useState(false);
    const [ adminOpen, setAdminOpen ] = useState(false);

    const cancelContact = () => {
        props.history.push('/contact/contacttype');
        clearContactData();
        addMessage({type: "success", header: t('message.contactCanceled'), body: t('message.continueToNextContact')});
    }

    const cancelContactToChangeService = () => {
        props.history.push('/');
        clearContactData();
    }

    return (
        <div className="mobile-navigation">

            <YesNoModal
                type='cancel'
                header={t('modal.confirmCancelContact')}
                text={t('modal.wantToCancelContact')}
                method={cancelContact}
                open={cancelModalOpen}
                setOpen={setCancelModalOpen}
            />
            <YesNoModal
                type='warning'
                header={t('modal.contactInProgress')}
                text={t('modal.wantToCancelContactToChangeService')}
                method={cancelContactToChangeService}
                open={warningModalOpen}
                setOpen={setWarningModalOpen}
            />


            {/* Sticky side bar wont work if transform is not set to none !!! */}
            {/* But If style={{ transform: 'none' }} is added then the app wont work :( 
                These is a patch: add patch class to pusahble and sidebar and add css classes
                .pushable:not(body).patch { -webkit-transform: unset; transform: unset;} .pushable:not(body) .patch.ui.sidebar {position: fixed;}
                (popup visibility caused by this is fxed with z-index in div after body)
                https://topic.alibabacloud.com/a/how-to-use-the-fixed-menu-and-sidebar-of-semantic-ui-in-react_1_51_31205875.html
            */}
            <Sidebar.Pushable as={Segment} className='patch'>

                <Sidebar as={Menu} fixed='right' animation='overlay' className='patch' onHide={() => setMobileMenuOpen(false)} vertical visible={mobileMenuOpen}>

                    { globalState.service && globalState.channel && contactInProgress() &&
                        <Menu.Item><NavLink to='/contact/contacttype' exact onClick={(e) => {e.preventDefault();setMobileMenuOpen(false);setCancelModalOpen(true)}}>{t('navigation.cancelContact')}</NavLink></Menu.Item>
                    }
                    { globalState.service && globalState.channel ?
                        <Menu.Item><NavLink to ='/contact/contacttype' onClick={()=>setMobileMenuOpen(false)} exact>{t('navigation.contact')}</NavLink></Menu.Item>
                    :
                        <Menu.Item><NavLink to ='/' onClick={()=>setMobileMenuOpen(false)} exact>{t('navigation.contact')}</NavLink></Menu.Item>
                    }
                    {profile.IsOwner ?
                        <>
                        <Menu.Item onClick={() => setAdminOpen(!adminOpen)} header className={adminOpen ? 'open' : 'close'}>Hallinta</Menu.Item>
                        {adminOpen &&
                            <>
                            <Menu.Item onClick={() => {props.history.push('/user/list');setMobileMenuOpen(false)}}>
                                {t('navigation.users')}                       
                            </Menu.Item>
                            <Menu.Item onClick={() => {props.history.push('/shift/admin');setMobileMenuOpen(false)}}>
                                {t('navigation.shifts')}
                            </Menu.Item>
                            <Menu.Item className='last-in-group' onClick={() => {props.history.push('/shift/alert/start');setMobileMenuOpen(false)}}>
                                {t('navigation.alerts')}
                            </Menu.Item>
                            </>
                        }
                        </>
                    :
                      <Menu.Item><NavLink to='/shift/list' onClick={()=>setMobileMenuOpen(false)}>{t('navigation.shifts')}</NavLink></Menu.Item>
                    }
                    
                    { globalState.service && globalState.channel &&
                      <>
                        <Menu.Item header>{globalState.service.name.fi} - {globalState.channel.name.fi}</Menu.Item>
                        {contactInProgress() ?
                            <Menu.Item><NavLink to='/' onClick={(e) => {e.preventDefault();setMobileMenuOpen(false);setWarningModalOpen(true)}}>{t('navigation.changeservice')}</NavLink></Menu.Item>
                        :
                            <Menu.Item><NavLink to='/' onClick={()=>setMobileMenuOpen(false)}>{t('navigation.changeservice')}</NavLink></Menu.Item>
                        }
                      </>
                    }
                    <Menu.Item header>{globalState.name}</Menu.Item>
                    <Menu.Item><NavLink to={'/user/profile/' + profile.Id} onClick={()=>setMobileMenuOpen(false)}>{t('navigation.userinfo')}</NavLink></Menu.Item>
                    <Menu.Item><NavLink to='/' onClick={() => instance.logout()}>{t('navigation.logout')}</NavLink></Menu.Item>
                    
                    {/* Nav logo */}
                    <Menu.Item header>
                        <NavLink className="navlink" to={(globalState.service && globalState.channel) ? '/contact/contacttype' : '/'} onClick={()=>setMobileMenuOpen(false)} exact>
                            <Image size='medium' src='/images/logo.png' alt="logo" className="logo"/>
                        </NavLink>
                    </Menu.Item>

                </Sidebar>
                
                <Sidebar.Pusher dimmed={mobileMenuOpen}>
                    {children}
                </Sidebar.Pusher>

            </Sidebar.Pushable>
        </div>
    );
}

export default withRouter(MobileNavigation);

import React, { useContext, useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Menu, Dropdown, Container, Icon, Image, Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { StateContext } from '../Context/StateContext';
import { MessageContext } from '../Context/MessageContext';
import { YesNoModal } from '../Reusable/Modals/GeneralModal';
import { useMsal } from "@azure/msal-react";

function Navigation(props) {

  const {mobile, setMobileMenuOpen} = props;

  const { t } = useTranslation();
  const { instance } = useMsal(); 

  const { profile, globalState, clearContactData, contactInProgress } = useContext(StateContext);
  const { addMessage } = useContext(MessageContext);
  const [ cancelModalOpen, setCancelModalOpen] = useState(false);
  const [ warningModalOpen, setWarningModalOpen] = useState(false);

  const cancelContact = () => {
    props.history.push('/contact/contacttype');
    clearContactData();
    addMessage({type: "success", header: t('message.contactCanceled'), body: t('message.continueToNextContact')});
  }

  const cancelContactToChangeService = () => {
    props.history.push('/');
    clearContactData();
  }

  return (

    <div className="navigation">

      <YesNoModal
        type='cancel'
        header={t('modal.confirmCancelContact')}
        text={t('modal.wantToCancelContact')}
        method={cancelContact}
        open={cancelModalOpen}
        setOpen={setCancelModalOpen}
      />
      <YesNoModal
        type='warning'
        header={t('modal.contactInProgress')}
        text={t('modal.wantToCancelContactToChangeService')}
        method={cancelContactToChangeService}
        open={warningModalOpen}
        setOpen={setWarningModalOpen}
      />

      {/* Desktop */}
      { !mobile && 
        <Container>
          <Grid columns={2}>
            <Grid.Row stretched>
              <Grid.Column width={5}>
                <Menu>
                  <Menu.Menu position='left'>
                    <Menu.Item>
                      <NavLink className="navlink" to ={(globalState.service && globalState.channel) ? '/contact/contacttype' : '/'} exact>
                        <Image size='medium' src='/images/logo.png' alt="logo" className="logo"/>
                      </NavLink>
                    </Menu.Item>
                  </Menu.Menu>
                </Menu>
              </Grid.Column>
              <Grid.Column width={11}>
                <Menu className='navi1'>
                  <Menu.Menu position='right'>
                    { globalState.service && globalState.channel &&
                      <>
                      <Menu.Item header>{globalState.service.name.fi} - {globalState.channel.name.fi}</Menu.Item>
                      {contactInProgress() ?
                        <Menu.Item><NavLink to='/' onClick={(e) => {e.preventDefault();setWarningModalOpen(true)}}>{t('navigation.changeservice')}</NavLink></Menu.Item>
                      :
                        <Menu.Item><NavLink to='/'>{t('navigation.changeservice')}</NavLink></Menu.Item>
                      }
                      </>
                    }
                    <Menu.Item header>{globalState.name}</Menu.Item>
                    <Menu.Item><NavLink to={'/user/profile/' + profile.Id}>{t('navigation.userinfo')}</NavLink></Menu.Item>
                    <Menu.Item><NavLink to='/' onClick={() => instance.logout()}>{t('navigation.logout')}</NavLink></Menu.Item>
                  </Menu.Menu>
                </Menu>
                <Menu className='navi2'>
                  <Menu.Menu position='right'>
                    { globalState.service && globalState.channel && contactInProgress() &&
                      <Menu.Item><NavLink to='/contact/contacttype' onClick={(e) => {e.preventDefault();setCancelModalOpen(true)}}>{t('navigation.cancelContact')}</NavLink></Menu.Item>
                    }
                    { globalState.service && globalState.channel ?
                      <Menu.Item><NavLink to='/contact/contacttype'>{t('navigation.contact')}</NavLink></Menu.Item>
                    :
                      <Menu.Item><NavLink to='/'>{t('navigation.contact')}</NavLink></Menu.Item>
                    }
                    {profile.IsOwner ?
                      <Dropdown item text='Hallinta'>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => props.history.push('/user/list')}><NavLink to='/user/list'>{t('navigation.users')}</NavLink></Dropdown.Item>
                          <Dropdown.Item onClick={() => props.history.push('/shift/admin')}><NavLink to='/shift/admin'>{t('navigation.shifts')}</NavLink></Dropdown.Item>
                          <Dropdown.Item onClick={() => props.history.push('/shift/alert/start')}><NavLink to='/shift/alert/start'>{t('navigation.alerts')}</NavLink></Dropdown.Item>
                          <Dropdown.Item onClick={() => props.history.push('/shift/list')}><NavLink to='/shift/list'>{t('navigation.shifts')} päivystäjä</NavLink></Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    :
                      <Menu.Item><NavLink to='/shift/list'>{t('navigation.shifts')}</NavLink></Menu.Item>
                    }
                  </Menu.Menu>
                </Menu>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      }

      {/* Mobile */}
      { mobile && 
          <Menu>
              <Menu.Item>{t('navigation.MLLStatistics')}</Menu.Item>
              <Menu.Item onClick={() => setMobileMenuOpen(true)} position='right'><Icon name='sidebar' /></Menu.Item>
          </Menu>
      }

    </div>
  );
}


export default withRouter(Navigation);

import React, { useContext, useState } from 'react';
import SubNavigation from '../../Layout/SubNavigation';
import { Form, Grid, Segment, Container, Divider, Button, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { StateContext } from '../../Context/StateContext';
import Question from '../../Reusable/Question';

function PreviousSupport(props) {

  const { t } = useTranslation();
  const [ validationError, setValidationError ] = useState('');
  
  const { globalState, contactDataOK, saveContactData } = useContext(StateContext);

  let questions = []
  if(globalState.service.type === 'vpn') questions = globalState.settings.find(item => item.type === 'vpn') || [];
  if(globalState.service.type === 'lnpn') questions = globalState.settings.find(item => item.type === 'lnpn') || [];

  const setPreviousSupportSources = (obj) => {
    let newObj = {};
    if(globalState.previousSupportSources.find(item => item.ref === obj.ref)){
      // uncheck -> remove item from array
      newObj = globalState.previousSupportSources.filter(item => item.ref !== obj.ref)
    } else {
      //check -> add item to array
      newObj = globalState.previousSupportSources.concat(obj)
    }
    globalState.setPreviousSupportSources(newObj)
  }

  const isAuthorIDValid = (val) => {
    // example: 01234567-0123-0123-0123-0123456789AB
    if(val){
      if(val.length!==36) return false;
      if(val.charAt(8)!=='-') return false;
      if(val.charAt(13)!=='-') return false;
      if(val.charAt(18)!=='-') return false;
      if(val.charAt(23)!=='-') return false;

      const regex = /^[a-zA-Z0-9]*$/g;
      let part = val.substr(0,8)
      let match = part.match(regex)
      if(!match || match[0]!==part) return false;

      part = val.substr(9,4)
      match = part.match(regex)
      if(!match || match[0]!==part) return false;

      part = val.substr(14,4)
      match = part.match(regex)
      if(!match || match[0]!==part) return false;

      part = val.substr(19,4)
      match = part.match(regex)
      if(!match || match[0]!==part) return false;

      part = val.substr(24,12)
      match = part.match(regex)
      if(!match || match[0]!==part) return false;
    }
    return true;
  }

  const handleAuthorID = (val) => {
    setValidationError('');
    globalState.setAuthorID(val);
    if(!isAuthorIDValid(val)) setValidationError(t('errors.invalidInput'));
  }

  const handlePaste = () => {
    navigator.clipboard.readText().then(
      clipText => {
        document.getElementById('authorID').value = clipText
        setValidationError('');
        globalState.setAuthorID(clipText);
        if(!isAuthorIDValid(clipText)) setValidationError(t('errors.invalidInput'));
      }
    );
  }

  return (
    <div>
      <SubNavigation activePage='previoussupport' />
      <Form>
        <Grid stackable columns={2}>
          <Grid.Column>
            <Segment>
              <Question
                data={questions.previousSupportSought || []} 
                selectedValue={globalState.previousSupportSought.ref} 
                setSelectedValue={val => {
                  const x = questions.previousSupportSought.options.find(s => s.ref === val)
                  globalState.setPreviousSupportSought(x)
                }}
              />

              {globalState.channel.ref === 'internetletter' &&
                <Question
                  data={questions.authorID || []} 
                  selectedValue={globalState.authorID} 
                  setSelectedValue={(val) => handleAuthorID(val)}
                  id='authorID'
                  icon={<Icon name='paste'  circular link onClick={() => handlePaste()} />}
                />
              }
              {validationError &&
                <div className='validation-error'>{validationError}</div>
              }

            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              {globalState.previousSupportSought.ref === 'yes' &&
                <Question
                  data={questions.previousSupportSources || []} 
                  selectedValue={globalState.previousSupportSources} 
                  setSelectedValue={val => {
                    const x = questions.previousSupportSources.options.find(s => s.ref === val)
                    setPreviousSupportSources(x)
                  }}
                />
              }
            </Segment>
          </Grid.Column>
        </Grid>
        
        <Container>
            <Divider/>
            {contactDataOK().result ?
              <Button floated='right' onClick={() => saveContactData(props.history)}>{t('general.savecontact')}</Button>
            :
              <Button floated='right' onClick={() => props.history.push("/contact/themes")}>{t('general.nextstep')}</Button>
            }
        </Container>
      </Form>
    </div>
  );
}

export default PreviousSupport;

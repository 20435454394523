import React, { useContext } from 'react';
import SubNavigation from '../../Layout/SubNavigation';
import { Form, Grid, Segment, Container, Divider, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { StateContext } from './../../Context/StateContext';
import Question from '../../Reusable/Question';

const Retrospective = (props) => {

  const { t } = useTranslation();
  const { globalState, contactDataOK, saveContactData, setHighlightUnfilledRequiredFields } = useContext(StateContext);

  let questions = []
  if(globalState.service.type === 'vpn') questions = globalState.settings.find(item => item.type === 'vpn') || [];
  if(globalState.service.type === 'lnpn') questions = globalState.settings.find(item => item.type === 'lnpn') || [];

  //console.log("globalState");
  //console.log(globalState);

  const setContactorFeedbackDescriptions = (obj) => {
    let newObj = {};
    if(globalState.contactorFeedbackDescriptions.find(item => item.ref === obj.ref)){
      // uncheck -> remove item from array
      newObj = globalState.contactorFeedbackDescriptions.filter(item => item.ref !== obj.ref)
    } else {
      //check -> add item to array
      newObj = globalState.contactorFeedbackDescriptions.concat(obj)
    }
    globalState.setContactorFeedbackDescriptions(newObj)
  }

  const handleReturnToMissingInformation = (path) => {
    setHighlightUnfilledRequiredFields(true);
    props.history.push(path);
    window.scrollTo(0, 0);
  }

  return (
    <div>
      <SubNavigation activePage='retrospective' />
      <Form>
        <Grid stackable columns={2}>
          <Grid.Column>
            <Segment>
              <Question
                data={questions.contactLength || []} 
                selectedValue={globalState.contactLength.ref} 
                setSelectedValue={val => {
                  const x = questions.contactLength.options.find(s => s.ref === val)
                  globalState.setContactLength(x)
                }}
              />
              <Question
                data={questions.userExperience || []} 
                selectedValue={globalState.userExperience.ref} 
                setSelectedValue={val => {
                  const x = questions.userExperience.options.find(s => s.ref === val)
                  globalState.setUserExperience(x)
                }}
              />
              <Question
                data={questions.contactorGaveFeedback || []} 
                selectedValue={globalState.contactorGaveFeedback.ref} 
                setSelectedValue={val => {
                  const x = questions.contactorGaveFeedback.options.find(s => s.ref === val)
                  globalState.setContactorGaveFeedback(x)
                }}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              {globalState.contactorGaveFeedback.ref === 'yes' &&
              <>
                <Question
                  data={questions.contactorFeedbackType || []} 
                  selectedValue={globalState.contactorFeedbackType.ref} 
                  setSelectedValue={val => {
                    const x = questions.contactorFeedbackType.options.find(s => s.ref === val)
                    globalState.setContactorFeedbackType(x)
                  }}
                />
                {globalState.contactorFeedbackType.ref === 'positive' &&
                <Question
                  data={questions.contactorFeedbackDescriptions || []} 
                  selectedValue={globalState.contactorFeedbackDescriptions} 
                  setSelectedValue={val => {
                    const x = questions.contactorFeedbackDescriptions.options.find(s => s.ref === val)
                    setContactorFeedbackDescriptions(x)
                  }}
                />
                }
                <Question
                  data={questions.contactorFeedback || []} 
                  selectedValue={globalState.contactorFeedback} 
                  setSelectedValue={globalState.setContactorFeedback}
                />
                </>
              }
            </Segment>
          </Grid.Column>
        </Grid>
        
        <Container>
            <Divider/>
            {contactDataOK().result ?
              <Button floated='right' onClick={() => saveContactData(props.history)}>{t('general.savecontact')}</Button>
            :
              <Button floated='right' onClick={() => handleReturnToMissingInformation(contactDataOK().route)}>{t('general.returnToMissingInformation')}</Button>
            }
        </Container>
      </Form>
    </div>
  );
}

export default Retrospective;

import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Form,  Divider, Button } from 'semantic-ui-react';
import { MessageContext } from '../../Context/MessageContext';
import useCreateOneToOne from '../../Hooks/Users/OneToOne/useCreateOneToOne';
import { DateInput } from 'semantic-ui-calendar-react';
import { useParams } from 'react-router-dom';
import useReadUser from '../../Hooks/Users/useReadUser';

const CreateOneToOne = (props) => {

    const { t } = useTranslation();
    const { createOneToOne } = useCreateOneToOne();
    const { addMessage, removeMessage, messages } = useContext(MessageContext);
    const { id } = useParams();
    const { user, readUser } = useReadUser();
    
    const [ date, setDate ] = useState(null);
    const [ description, setDescription ] = useState('');

    useEffect(() => {
        (async() => {
            await readUser(id);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSave = async () => {
        messages.map(msg => removeMessage(msg.id));

        //validate
        if(!date) {
            addMessage({type: "error", header: t('message.fillInRequiredInformation'), body: t('message.requiredMarkedWithAsterisk')})
            window.scrollTo(0, 0)
        } else {
            // Format date
            let dateParts = []
            let dateISO = null;
            if(date) {
                dateParts=date.split('.');
                dateISO=new Date(dateParts[2] + "-" + dateParts[1].padStart(2,"0") + "-" + dateParts[0].padStart(2,"0")).toISOString();
            }

            //create data
            let data = {
                "date": dateISO,
                "description": description
            }

            //save
            let result = null;
            result = await createOneToOne(id,data);

            if(result.status === 201) {
                props.history.goBack();
            }
        }
    }

    return (
        <div>
            <Form>
                <Container>
                    <h1>{t('heading.oneToOne')}</h1>

                    <h2>{t('heading.user')}</h2>
                    <div>{user.data && user.data.FirstName} {user.data && user.data.LastName}</div>
                
                    <h2>{t('oneToOne.date')}<sup><i className='icon asterisk'></i></sup></h2>
                    <DateInput
                        localization='fi'
                        dateFormat={'DD.MM.YYYY'}
                        value={date || ""}
                        iconPosition="right"
                        onChange={(ev, {value}) => setDate(value) }
                        closable={true}
                        popupPosition='bottom left'
                    />
                
                    <h2>{t('oneToOne.description')}</h2>
                    <Form.TextArea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </Container>
                <Divider/>
                <Button onClick={() => handleSave()}>{t('general.save')}</Button>
                <Button onClick={() => props.history.goBack()}>{t('general.cancel')}</Button>
            </Form>
        </div>
    )

}

export default CreateOneToOne;
import React from 'react';
import { Header, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { YesButton, NoButton, ApproveButton } from './../Buttons/Buttons';

const YesNoModal = ({ type, header, text, method, item, open, setOpen }) => {

    const { t } = useTranslation();

    return (
        <Modal
            closeIcon
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
        >
            {type==='delete' &&
                <Header icon='delete' content={header || t('general.delete')} />
            }
            {type==='save' &&
                <Header icon='save' content={header || t('general.save')} />
            }
            {type==='cancel' &&
                <Header icon='cancel' content={header || t('general.cancel')} />
            }
            {type==='warning' &&
                <Header icon='warning' content={header || t('general.warning')} />
            }

            <Modal.Content>
                <p>{text || t('general.areYouSure')}</p>
            </Modal.Content>
            
            <Modal.Actions>
                <NoButton onClick={() => {
                    setOpen(false);
                }}/>
                <YesButton onClick={() => {
                    setOpen(false);
                    if (method) {
                        item ? method(item) : method();
                    }
                }}/>
            </Modal.Actions>

        </Modal>
    );
}

const ApproveModal = ({ type, header, text, open, setOpen }) => {

    const { t } = useTranslation();

    return (
        <Modal
            closeIcon
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
        >
            {type==='info' &&
                <Header icon='info' content={header || t('general.info')} />
            }
            {type==='warning' &&
                <Header icon='warning' content={header || t('general.warning')} />
            }

            <Modal.Content>
                <p>{text || t('general.areYouSure')}</p>
            </Modal.Content>
            
            <Modal.Actions>
                <ApproveButton onClick={() => {
                    setOpen(false);
                }}/>
            </Modal.Actions>

        </Modal>
    );
}

export { YesNoModal, ApproveModal };
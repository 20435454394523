import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Divider, Button, Message, Grid } from 'semantic-ui-react';
import useReadUser from '../../Hooks/Users/useReadUser';
import useReadOneToOne from '../../Hooks/Users/OneToOne/useReadOneToOne';
import useDeleteOneToOne from '../../Hooks/Users/OneToOne/useDeleteOneToOne';
import { useParams } from 'react-router-dom';
import { YesNoModal } from '../../Reusable/Modals/GeneralModal';

const OneToOneDetail = (props) => {

    const { t } = useTranslation();
    const { oneToOne, readOneToOne } = useReadOneToOne();
    const { user, readUser } = useReadUser();
    const { deleteOneToOne } = useDeleteOneToOne();
    const { userId, oneToOneId } = useParams();
    const [ modalOpen, setModalOpen ] = useState(false);

    //console.log(oneToOne)

    useEffect(() => {
        (async() => {
            await readOneToOne(userId,oneToOneId);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async() => {
            if(oneToOne && oneToOne.data && oneToOne.data.UserId){
                await readUser(oneToOne.data.UserId);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oneToOne]);

    const delOneToOne = () => {
        (async () => {
            let result = null;
            result = await deleteOneToOne(userId,oneToOneId);
            if(result.status === 204){
                props.history.goBack();
            }
        })();
    }

    return (
        <div>
            <YesNoModal 
                type='delete'
                header={t('modal.confirmDelete')}
                text={t('modal.confirmDeleteOneToOne')}
                method={delOneToOne}
                open={modalOpen}
                setOpen={setModalOpen}
            />
           <Container>
                <h1>{t('heading.oneToOne')}</h1>
                { oneToOne && oneToOne.data &&
                    <>
                    <h2>{t('heading.user')}</h2>
                    <div>{user.data && user.data.FirstName} {user.data && user.data.LastName}</div>

                    <h2>{t('oneToOne.date')}</h2>
                    <div>{new Date(oneToOne.data.Date).toLocaleDateString()}</div>

                    <h2>{t('oneToOne.description')}</h2>
                    <div>{oneToOne.data.Description}</div>
                    </>
                }

            </Container>
            <Divider/>
            <Button onClick={() => props.history.push('/user/' + userId + '/onetoone/edit/' + oneToOneId)}>{t('general.edit')}</Button>
            <Button onClick={() => setModalOpen(true)}>{t('general.delete')}</Button>
            <Button onClick={() => props.history.goBack()}>{t('general.back')}</Button>
        </div>
    )

}

export default OneToOneDetail;
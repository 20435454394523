import React, { useState, useEffect, useContext } from 'react';
import { Form, Checkbox, Divider, Button } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useReadShift from '../../Hooks/Shifts/useReadShift';
import useListShiftTypes from '../../Hooks/Shifts/useListShiftTypes';
import useListUsers from '../../Hooks/Users/useListUsers';
import { StateContext } from '../../Context/StateContext';
import useUpdateShift from '../../Hooks/Shifts/useUpdateShift';
import useCreateShiftType from '../../Hooks/Shifts/useCreateShiftType';
import useUpdateShiftType from '../../Hooks/Shifts/useUpdateShiftType';
import useDeleteShiftType from '../../Hooks/Shifts/useDeleteShiftType';
import useListShiftUsers from '../../Hooks/ShiftUsers/useListShiftUsers';
import { MessageContext } from '../../Context/MessageContext';
import Select from 'react-select';

const UpdateShift = (props) => {

    const { t } = useTranslation()
    const { id } = useParams();
    const { shift, readShift } = useReadShift();
    const { shiftTypes, listShiftTypes } = useListShiftTypes();
    const { users, listUsers } = useListUsers();
    const { locations, types } = useContext(StateContext)
    const [ date, setDate ] = useState('')
    const [ startTime, setStartTime ] = useState('')
    const [ endTime, setEndTime ] = useState('')
    const [ shiftTypesSelection, setShiftTypesSelection ] = useState([{}])
    const [ location, setLocation ] = useState('')
    const [ supervisor, setSupervisor ] = useState('')
    const [ description, setDescription ] = useState('')
    const { addMessage, removeMessage, messages } = useContext(MessageContext);
    const { updateShift } = useUpdateShift();
    const { createShiftType } = useCreateShiftType();
    const { updateShiftType } = useUpdateShiftType();
    const { deleteShiftType } = useDeleteShiftType();
    const { shiftUsers, listShiftUsers } = useListShiftUsers();

    // Fetch shift
    useEffect(() => {
        (async () => {
            await readShift(id);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

   // Fetch shift types
   useEffect(() => {
        (async () => {
            await listShiftTypes(id);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[shift]);

    // Fetch shift users
    useEffect(() => {
        (async () => {
            await listShiftUsers(id);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[shift]);

    // Set shift data
    useEffect(() => {
        (async () => {
            if (shift && shift.Id) {
                const startTime = new Date(shift.StartTime).getHours() + '.' + new Date(shift.StartTime).getMinutes().toString().padStart(2,'0')
                const endTime = new Date(shift.EndTime).getHours() + '.' + new Date(shift.EndTime).getMinutes().toString().padStart(2,'0')
                setDate(new Date(shift.Date).toLocaleDateString());
                setStartTime(startTime);
                setEndTime(endTime);
                setSupervisor(shift.InstructorId);
                setLocation(shift.LocationId);
                setDescription(shift.Description)
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shift]);

    let d = null;
    let time = null;
    if(shift.StartTime){
        d = new Date(shift.StartTime)
        time = d.getHours() + '.' + d.getMinutes().toString().padStart(2,'0')
        d = new Date(shift.EndTime)
        time += '-' + d.getHours() + '.' + d.getMinutes().toString().padStart(2,'0')
    }

    // Set shift types to shift data
    useEffect(() => {
        (async () => {
            let newArray = []
            shiftTypes.map(item => {
                newArray.push({type: item.TypeId, amount: item.ShiftUserCount})
                return item;
            })
            setShiftTypesSelection(newArray)
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[shiftTypes]);

    // Fetch users
    useEffect(() => {
        (async () => {
            await listUsers();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    let locationOptions = []
    locations.map(i => {
        const option = {
            key: i.Id,
            text: i.Name,
            value: i.Id
        }
        locationOptions.push(option);
        return i;
    })

    let typeOptions = []
    types.map(i => {
        const option = {
            key: i.Id,
            text: i.Name,
            value: i.Id
        }
        typeOptions.push(option);
        return i;
    })

    let supervisors = []
    users.filter(item => item.IsEmployee).map(item => {
        const supervisor = {
            key: item.Id,
            label: item.LastName + ' ' + item.FirstName,
            value: item.Id
        }
        supervisors.push(supervisor);
        return item;
    })

    const setTypes = (value) => {
        if(shiftTypesSelection.find(item => item.type === value)){
            const newArray = shiftTypesSelection.filter(item => item.type !== value)
            // uncheck -> remove item from array
            setShiftTypesSelection(newArray)
        } else {
            //check -> add item to array
            const newObj = {type: value }
            setShiftTypesSelection(shiftTypesSelection.concat(newObj))
        }
    }

    const setPersonsOnCall = (type,value) => {
        const obj = shiftTypesSelection.filter(item => item.type !== type)
        if(value){
            obj.push({type: type, amount: parseInt(value)})
        } else {
            obj.push({type: type})
        }
        setShiftTypesSelection(obj)
    }

    const handleSave = async () => {
        messages.map(msg => removeMessage(msg.id));

        //validate
        if(!date || !startTime || !endTime || !location || !supervisor || shiftTypesSelection.length === 0 || shiftTypesSelection.find(item => item.amount === undefined)) {
            addMessage({type: "error", header: t('message.fillInRequiredInformation'), body: t('message.requiredMarkedWithAsterisk')})
            window.scrollTo(0, 0)
        } else {
            let errors = false
            let errTypes = []
            shiftTypes.map(item => {
                const usersOnCall = shiftUsers.filter(user => user.ShiftTypeId === item.Id)
                const selectedType = shiftTypesSelection.find(t => t.type === item.TypeId)
                if(selectedType === undefined) {
                    if(usersOnCall.length>0){
                        // there are people enrolled and type is being deleted
                        errors=true
                        errTypes.push(item.TypeName)
                    }
                } else if(usersOnCall.length>selectedType.amount){
                    // there are more people enrolled than available places
                    errors=true
                    errTypes.push(item.TypeName)
                }
                return item;
            })


            if(errors){
                addMessage({type: "error", header: t('errors.usersEnrolled'), body: t('errors.usersEnrolledError') + ' ' + errTypes.toString()})
                window.scrollTo(0, 0)
            } else {

                //create data
                const dates = date + '.' + startTime + '.' + endTime
                const dateArray = dates.split('.')
                let data = {
                    "id": parseInt(id),
                    "date": new Date(dateArray[2] + '-' + dateArray[1].padStart(2,'0') + '-' + dateArray[0].padStart(2,'0')).toISOString(),
                    "startTime": new Date(dateArray[2],dateArray[1]-1,dateArray[0],dateArray[3],dateArray[4]).toISOString(),
                    "endTime": new Date(dateArray[2],dateArray[1]-1,dateArray[0],dateArray[5],dateArray[6]).toISOString(),
                    "locationId": location,
                    "instructorId": supervisor,
                    "description": description || ''
                }

                //save
                let result = null;
                result = await updateShift(data);

                if(result.status === 200) {

                    types.map(type => {
                        const sts = shiftTypesSelection.find(item => item.type === type.Id)
                        if (sts !== undefined) {
                            // type is selected
                            if (shiftTypes.find(item => item.TypeId === type.Id) !== undefined) {
                                // type was selected before => update shift type
                                data = {
                                    "shiftTypeId": shiftTypes.find(item => item.TypeId === type.Id).Id,
                                    "typeId": sts.type,
                                    "shiftUserCount": sts.amount
                                }
                                result = updateShiftType(id, data, false);
                            } else {
                                // type was not selected before => create new shift type
                                data = {
                                    "typeId": sts.type,
                                    "shiftUserCount": sts.amount
                                }
                                result = createShiftType(id, data, false);
                            }
                        } else {
                            // type is not selected
                            const st = shiftTypes.find(item => item.TypeId === type.Id)
                            if (st !== undefined) {
                                // type was selected before => delete shift type
                                const shiftTypeId = shiftTypes.find(item => item.TypeId === type.Id).Id,
                                result = deleteShiftType(id, shiftTypeId, false);
                            }
                        }
                        return type;
                    })

                    props.history.goBack();
                }
            }
        }
    }

    return (
        <div className='shiftform'>
            <h1>{t('heading.shift')}</h1>
            <Form>
                <div className="field">
                    <h2>{t('shift.timing')}</h2>
                    <div>{shift.Date && new Date(shift.Date).toLocaleDateString()} {time ? t('general.at') + ' ' + time : ''}</div>
                </div>
                
                <div className='field'><label>{t('shift.type')}<sup><i className='icon asterisk'></i></sup></label></div>
                {typeOptions.map(item =>
                    <Form.Group key={item.key}>
                        <Form.Field
                            label={t(item.text)}
                            control={Checkbox}
                            width={8}
                            onChange={(e,{value}) => setTypes(value)}
                            value={item.value}
                            checked={shiftTypesSelection.find(t => t.type === item.value)!==undefined}
                        />
                        {shiftTypesSelection.find(t => t.type === item.value) ?
                            <>
                                <div>Max.</div>
                                <Form.Input 
                                    value={shiftTypesSelection.find(t => t.type === item.value).amount}
                                    onChange={(e) => setPersonsOnCall(item.value,e.target.value)}
                                    className='small-text'
                                />
                                <div>{t('shift.numberOfPersonsOnCall')}<sup><i className='icon asterisk'></i></sup></div>
                            </>
                        :
                            <><div className="hidden"><Form.Input /></div><div className="hidden">{t('shift.numberOfPersonsOnCall')}</div></>
                        }
                    </Form.Group>
                )}
                
                <div className="field">
                    <h2>{t('shift.location')}</h2>
                    <div>{locations.filter(item => item.Id === location).map(item => item.Name)}</div>
                </div>

                <div className="field"><label>{t('shift.supervisor')}<sup><i className='icon asterisk'></i></sup></label>
                    <Select 
                        value={supervisors.filter(item => item.value === supervisor)}
                        onChange={(e,{value}) => setSupervisor(e.value)}
                        options={supervisors}
                        placeholder={t('user.searchWithLastnameOrFirstname')}
                        menuPlacement='top'
                    />
                </div>
                <h2>{t('shift.description')}</h2>
                <Form.TextArea
                    value={description || ''}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </Form>
            <Divider/>
            <Button onClick={() => handleSave()}>{t('general.save')}</Button>
            <Button onClick={() => props.history.goBack()}>{t('general.cancel')}</Button>
        </div>
    )

}

export default UpdateShift;
import React from 'react';
import { useTranslation } from 'react-i18next';

const pagenotfound = { textAlign: "center" }
const pagenotfoundP = { fontSize: "72px", fontWeight: "bold" }

const PageNotFound = (props) => {

    const { t } = useTranslation();

    return (
        <div style={pagenotfound}>
            <h1>{t('errors.pagenotfound')}</h1>
            <p style={pagenotfoundP}>404</p>
        </div>
    );

}

export default PageNotFound;

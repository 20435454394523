
const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const dateTimeToDate = (date) => {
    const d = new Date(date);
    return d.toLocaleDateString();
}

const sortByProperty = (array, param, asc = true) => {
    if (!Array.isArray(array)) return;
    return array?.slice().sort((a, b) => {
                                if(!a[param]) a[param] = ''
                                if(!b[param]) b[param] = '' 
                                return asc ? (a[param] > b[param]) ? 1 : ((b[param] > a[param]) ? -1 : 0) :
                                             (a[param] > b[param]) ? -1 : ((b[param] > a[param]) ? 1 : 0)
                                });
}

const sortByPropertyAsInt = (array, param, asc = true) => {
    if (!Array.isArray(array)) return;
    const numbers = array
                        .slice()
                        .filter(item => !isNaN(parseInt(item[param])))
                        .sort((a, b) => {
                            if(!a[param]) a[param] = ''
                            if(!b[param]) b[param] = '' 
                            return asc ? (parseInt(a[param]) > parseInt(b[param])) ? 1 : ((parseInt(b[param]) > parseInt(a[param])) ? -1 : 0) :
                                         (parseInt(a[param]) > parseInt(b[param])) ? -1 : (parseInt((b[param]) > parseInt(a[param])) ? 1 : 0)
                            });
                        
 
    const strings = sortByProperty(array.filter(item => isNaN(parseInt(item[param]))), param, asc)
    return asc ? numbers.concat(strings) : strings.concat(numbers);
}

const sortByDate = (array, param, asc = true) => {
    if (!Array.isArray(array)) return;
    return array?.slice().sort((a, b) => asc ? new Date(a[param]) - new Date(b[param]) : new Date(b[param]) - new Date(a[param]))
}

const groupArrayBy = (array, property) => {
    if (!Array.isArray(array)) return;
    const param = String(property);
    const grouped = array.reduce((memo, item) => {
      if (!memo[item[param]]) {
           memo[item[param]] = []; 
      }
      memo[item[param]].push(item);
      return memo;
    }, []);

    return grouped.filter(item => Array.isArray(item));
}

const convertImageToBase64 = (img) => {
    try {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        return canvas.toDataURL("image/png");
    } catch (error) {
        return error;
    }
}

export default { 
    capitalizeFirstLetter,
    dateTimeToDate,
    sortByProperty,
    sortByPropertyAsInt,
    sortByDate,
    groupArrayBy,
    convertImageToBase64
};

import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PagedTable from '../../Reusable/Lists/PagedTable';
import { Divider, Button, Grid, Message } from 'semantic-ui-react';
import useListUsers from '../../Hooks/Users/useListUsers';
import useListBadgeOfMerits from '../../Hooks/Settings/useListBadgeOfMerits';
import { StateContext } from '../../Context/StateContext';
import { MessageContext } from '../../Context/MessageContext';

const UserList = (props) => {

    const { t } = useTranslation();
    const { globalState, locations, services, setUserDetailBackRoute } = useContext(StateContext);
    const { addMessage } = useContext(MessageContext);
    const { users, listUsers } = useListUsers();
    const { badgeOfMerits, listBadgeOfMerits } = useListBadgeOfMerits();

    setUserDetailBackRoute('/user/badgeofmerit/list');

    let locationOptions = [{label: t('user.location-options.all'), value: ''}]
    locations.map(l => {
        if(l.Reference!=='remote'){
            const tmp = {
                label: l.Name,
                value: l.Id
            }
            locationOptions.push(tmp)
        }
        return l;
    })

    // Fetch users
    useEffect(() => {
        (async () => {
            await listUsers();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // Fetch badges of merit
    useEffect(() => {
        (async () => {
            await listBadgeOfMerits();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // add view url and city name to users
    useEffect(() => {
        users.map(user => {
            user.viewUrl = '/user/detail/';
            user.link=user.LastName;
            user.serviceNames = '';
            user.shiftCount = user.UserShiftCountInPreviousSystem + user.UserShiftCount;
            if(user.UserServices) {
                user.UserServices.map(item => {
                    if(user.serviceNames.length>0) user.serviceNames += ', '
                    user.serviceNames += services.find(s => s.Id === item.ServiceId).Name;
                    return item;
                })
            }
            if(user.BadgeOfMeritEarned && badgeOfMerits && badgeOfMerits.find(item => item.Id === user.BadgeOfMeritEarned)){
                user.BadgeName=badgeOfMerits.find(item => item.Id === user.BadgeOfMeritEarned).Name || '';
            }
            return user;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[users,badgeOfMerits]);
    
    // filter users that earn a badge of merit
    const filteredUsers = users.filter(user => user.BadgeOfMeritEarned)

    return (
        <div className="userlist min600">
            <Grid stackable columns={2} className="add-margin">
                <Grid.Column><h1>{t('heading.BOMUsers')}</h1></Grid.Column>
            </Grid>
            <Message>{t('info.badgeUserList')}</Message>
                
            <PagedTable unstackable striped celled compact='very'
                header={[
                    t('user.lastname'),
                    t('user.firstname'),
                    t('user.city'),
                    t('user.services'),
                    t('heading.shifts'),
                    t('user.lastBadgeDate'),
                    t('badgeOfMerit.name')
                ]}
                content={filteredUsers}
                contentKeys={[
                    {key: 'link', type: 'url', urlKey:'viewUrl', cellAlign: 'left', targetKey: 'Id'},
                    {key: 'FirstName'},
                    {key: 'LocationName'},
                    {key: 'serviceNames'},
                    {key: 'shiftCount'},
                    {key: 'LastBadgeDate', type: 'date'},
                    {key: 'BadgeName'}
                ]}
                pageSize='20'
            />

            <Divider />
            <Button onClick={() => props.history.push('/user/list')}>{t('general.back')}</Button>
            
                
        </div>
    );

}

export default UserList;

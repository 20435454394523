import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageContext } from '../../../Context/MessageContext';
import Axios from 'axios';
import Config from '../../../Config/Config';
import { useMsal } from "@azure/msal-react";

const useReadBadgeOfMerit = () => {

    const rootPath = Config.api.rootPath;
    const { instance } = useMsal();
    const { t } = useTranslation();
    const { addMessage, removeMessage } = useContext(MessageContext);
    const [ badgeOfMerit, setBadgeOfMerit ] = useState([]);
    const [ fetchingBadgeOfMerit, setFetchingBadgeOfMerit ] = useState(true);

    /** read one to one
     * @param {integer} userId - user id
     * * @param {integer} badgeOfMeritId - one to one id id
     * @returns {object} - one to one */ 
    const readBadgeOfMerit = async (userId, badgeOfMeritId) => {
        try {

            const msgId = addMessage({ type: "loading", header: t('general.moment'), body: t('info.fetchingBadgeOfMerit')});
            
            const activeAccount = instance.getActiveAccount();
            const accounts = instance.getAllAccounts();

            if (!activeAccount && accounts.length === 0) return;

            const request = {
                scopes: Config.api.scopes,
                account: activeAccount || accounts[0]
            }

            const authResult = await instance.acquireTokenSilent(request);

            const options = {
                "headers": {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + authResult.accessToken
                }
            }

            let result = null;

            result = await Axios.get(rootPath + "/api/v1/users/" + userId + "/badgeofmerits/" + badgeOfMeritId, options);

            removeMessage(msgId);

            if (result && result.status === 200) {
                setBadgeOfMerit(result);
                setFetchingBadgeOfMerit(false);
                return result;
            }

            if (result && result.status === 404) {
                addMessage({ type: "warning", header: t('general.error'), body: t('warnings.badgeOfMeritNotFound') });
                setFetchingBadgeOfMerit(false);
                return result;
            }

            addMessage({ type: "error", header: t('general.error'), body: t('errors.fetchingBadgeOfMeritFailed') });
            setFetchingBadgeOfMerit(false);
            return result;

        } catch (error) {
            if (Config.environment === 'development' || Config.environment === 'test') console.log(error);
            addMessage({ type: "error", header: t('general.error'), body: t('errors.fetchingBadgeOfMeritFailed') });
            setFetchingBadgeOfMerit(false);
            return error;
        }
    }

    return { badgeOfMerit, fetchingBadgeOfMerit, readBadgeOfMerit };

}

export default useReadBadgeOfMerit;

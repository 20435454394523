import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import config from './Config/Config';
import App from './App';
import Loading from './Layout/Loading';
//import reportWebVitals from './reportWebVitals';
import './index.css';
import './Utils/i18n'; // import i18n (needs to be bundled ;))
import 'moment/locale/fi';

const pca = new PublicClientApplication(config.authentication);

ReactDOM.render(
  <Suspense fallback={<Loading/>}>
    {/*<React.StrictMode>*/}
      <MsalProvider instance={pca}>
        <App />
      </MsalProvider>
    {/*</React.StrictMode>*/}
  </Suspense>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

import React from 'react';
import { createMedia } from "@artsy/fresnel";
import Navigation from './Navigation';

const Header = ({ mobileMenuOpen, setMobileMenuOpen }) => {

    window.addEventListener('beforeunload', function (e) {
        // Cancel the event
        e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = '';
      });
    
    const { MediaContextProvider, Media } = createMedia({
        breakpoints: {
            sm: 0,
            md: 768,
            lg: 1024,
            xl: 1192,
        },
    });

    return (
        <div className="app-header">
            <MediaContextProvider>
                <Media greaterThan='md'>
                    <Navigation mobile={false} />
                </Media>
                <Media lessThan='lg'>
                    <Navigation mobile={true} mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen}/>
                </Media>
            </MediaContextProvider>
        </div>
    );
}

export default Header;


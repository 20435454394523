
import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageContext } from '../../Context/MessageContext';
import Axios from 'axios';
import Config from '../../Config/Config';
import { useMsal } from "@azure/msal-react";

const useListShiftTypes = () => {
    const rootPath = Config.api.rootPath;
    const { instance } = useMsal();
    const { t } = useTranslation();
    const { addMessage } = useContext(MessageContext);
    const [ shiftTypes, setShiftTypes ] = useState([]);
    const [ fetchingShiftTypes, setFetchingShiftTypes ] = useState(true);

    /** search shift types
    * @returns {array} - array of shift types 
    */ 
    const listShiftTypes = async (id) => {
        try {

            
            const activeAccount = instance.getActiveAccount();
            const accounts = instance.getAllAccounts();

            if (!activeAccount && accounts.length === 0) return;

            const request = {
                scopes: Config.api.scopes,
                account: activeAccount || accounts[0]
            }

            const authResult = await instance.acquireTokenSilent(request);
            
            const options = {
                "headers": {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + authResult.accessToken
                }
            }

            let result = null;

            result = await Axios.get(rootPath + "/api/v1/shifts/" + id + "/types", options);

            
            if (result && result.status === 200 && result.data) {
                setShiftTypes(result.data.data);
                setFetchingShiftTypes(false);
                return result;
            }

            if (result && result.status === 404) {
                addMessage({ type: "warning", header: t('general.error'), body: t('warnings.shiftTypesNotFound') });
                setFetchingShiftTypes(false);
                return result;
            }

            addMessage({ type: "error", header: t('general.error'), body: t('errors.fetchingShiftTypesFailed') });
            setFetchingShiftTypes(false);
            return result;

        } catch (error) {
            if (Config.environment === 'development' || Config.environment === 'test') console.log(error);
            addMessage({ type: "error", header: t('general.error'), body: t('errors.fetchingShiftTypesFailed') });
            setFetchingShiftTypes(false);
            return error;
        }
    }

    return { shiftTypes, fetchingShiftTypes, listShiftTypes };
}

export default useListShiftTypes;
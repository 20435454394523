import React, { useState, useContext } from 'react';
import SubNavigation from '../../Layout/SubNavigation';
import { Form, Grid, Segment, Container, Divider, Button, List, Popup } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { StateContext } from '../../Context/StateContext';
import Question from '../../Reusable/Question';
import config from '../../Config/Config';
import { YesNoModal, ApproveModal } from '../../Reusable/Modals/GeneralModal';

const Themes = (props) => {

  const { t } = useTranslation();
  const [ theme, setTheme ] = useState({});
  const [ subject, setSubject ] = useState({});
  const { globalState, contactDataOK, saveContactData, highlightUnfilledRequiredFields } = useContext(StateContext);
  const [ currentSubject, setCurrentSubject ] = useState(globalState.subjects.length === 0 ? 0 : -1);
  const [ deleteModalOpen, setDeleteModalOpen ] = useState(false);
  const [ approveModalOpen, setApproveModalOpen ] = useState(false);
  const [ subjectToDelete, setSubjectToDelete ] = useState('');

  const subjectLimitReached = globalState.subjects.length === config.configurations.subjectLimit
  const disableAddButton = globalState.subjects.length >= config.configurations.subjectLimit;

  let questions = []
  if(globalState.service.type === 'vpn') questions = globalState.settings.find(item => item.type === 'vpn') || [];
  if(globalState.service.type === 'lnpn') questions = globalState.settings.find(item => item.type === 'lnpn') || [];

  const themes = questions.themes;

  const handleSelectTheme = (theme) => {
    const newObj = {
      "ref": theme.ref,
      "name": { "fi": theme.name.fi }
    }
    setTheme(newObj);
  }

  const handleSelectSubject = (s) => {
    let newObj = JSON.parse(JSON.stringify(s));
    // test if subject is already selected (if editing, same subject can be selected)
    if(globalState.subjects.find(item => item.ref === s.ref) && globalState.subjects.findIndex(item => item.ref === s.ref)!==currentSubject) {
      setApproveModalOpen(true);
    } else {
      newObj.theme = theme;
      // initialize fields that are filled in later
      if(globalState.service.type==='vpn'){
        newObj.subjectAppliesTo = [];
        newObj.childAge = [];
        newObj.childGender = [];
        newObj.childrenGroup = [];
        newObj.adult = [];
      }

      let newSubjects = [];
      if(currentSubject<globalState.subjects.length){
        //editing a subject
        newSubjects = [...globalState.subjects]
        newSubjects[currentSubject] = newObj;
      } else {
        //adding new subject
        newSubjects = globalState.subjects.concat(newObj);
      }
      globalState.setSubjects(newSubjects);
      if(globalState.service.type==='vpn'){
        setSubject(s);
      } else {
        setTheme('');
        setSubject('');
        setCurrentSubject('');
      }
    }
  }

  const handleChangeSubject = () => {
    setSubject({});
  }

  const handleAddSubject = () => {
      setCurrentSubject(globalState.subjects.length);
      setTheme('');
      setSubject('');
  }

  const handleEditSubject = (sub) => {
    setTheme(globalState.subjects.find(item => item.ref === sub.ref).theme)
    setSubject(globalState.subjects.find(item => item.ref === sub.ref))
    setCurrentSubject(globalState.subjects.findIndex(item => item.ref === sub.ref))
  }

  const handleClickDelete = (sub) => {
    setSubjectToDelete(sub);
    setDeleteModalOpen(true);
  }
  const handleDeleteSubject = (sub) => {
    let newObj = globalState.subjects.filter(item => item.ref !== sub.ref);
    globalState.setSubjects(newObj);
    setCurrentSubject(globalState.subjects.length===0 ? 0 : -1);
    /*console.log("subjects")
    console.log(globalState.subjects)
    console.log("subjects length")
    console.log(globalState.subjects.length);
    console.log("current subject")
    console.log(currentSubject || 'empty');*/
  }

  const handleClose = (index) => {
    setCurrentSubject(-1);
    setTheme('');
    setSubject('');
  }

  const setSubjectAppliesTo = (obj,index) => {
    let newSubjects = [...globalState.subjects]
    let newObj = {};
    if(newSubjects[index].subjectAppliesTo.find(item => item.ref === obj.ref)){
      // uncheck -> remove item from array
      newObj = newSubjects[index].subjectAppliesTo.filter(item => item.ref !== obj.ref);
      newSubjects[index].subjectAppliesTo=newObj;
    } else {
      //check -> add item to array
      newObj = JSON.parse(JSON.stringify(obj));
      newSubjects[index].subjectAppliesTo.push(newObj);
    }
    globalState.setSubjects(newSubjects);
  }

  //console.log(globalState.subjects)

  const setSubjectChildAge = (obj,index) => {
    let newSubjects = [...globalState.subjects]
    let newObj = {};
    if(newSubjects[index].childAge.find(item => item.ref === obj.ref)){
      // uncheck -> remove item from array
      newObj = newSubjects[index].childAge.filter(item => item.ref !== obj.ref);
      newSubjects[index].childAge=newObj;
    } else {
      //check -> add item to 
      newObj = JSON.parse(JSON.stringify(obj));
      newSubjects[index].childAge.push(newObj);
    }
    globalState.setSubjects(newSubjects);
  }

  const setSubjectChildGender = (obj,index) => {
    let newSubjects = [...globalState.subjects]
    let newObj = {};
    if(newSubjects[index].childGender.find(item => item.ref === obj.ref)){
      // uncheck -> remove item from array
      newObj = newSubjects[index].childGender.filter(item => item.ref !== obj.ref);
      newSubjects[index].childGender=newObj;
    } else {
      //check -> add item to array
      newObj = JSON.parse(JSON.stringify(obj));
      newSubjects[index].childGender.push(newObj);
    }
    globalState.setSubjects(newSubjects);
  }

  const setSubjectChildrenGroup = (obj,index) => {
    let newSubjects = [...globalState.subjects]
    let newObj = {};
    if(newSubjects[index].childrenGroup.find(item => item.ref === obj.ref)){
      // uncheck -> remove item from array
      newObj = newSubjects[index].childrenGroup.filter(item => item.ref !== obj.ref);
      newSubjects[index].childrenGroup=newObj;
    } else {
      //check -> add item to array
      newObj = JSON.parse(JSON.stringify(obj));
      newSubjects[index].childrenGroup.push(newObj);
    }
    globalState.setSubjects(newSubjects);
  }

  //console.log(globalState.subjects);

  const setSubjectAdult = (obj,index) => {
    let newSubjects = [...globalState.subjects]
    let newObj = {};
    if(newSubjects[index].adult.find(item => item.ref === obj.ref)){
      // uncheck -> remove item from array
      newObj = newSubjects[index].adult.filter(item => item.ref !== obj.ref);
      newSubjects[index].adult=newObj;
    } else {
      //check -> add item to array
      newObj = JSON.parse(JSON.stringify(obj));
      newSubjects[index].adult.push(newObj);
    }
    globalState.setSubjects(newSubjects);
  }

  const setSubjectAdultFreetext = (e,ref) => {
    let newSubjects = [...globalState.subjects];
    console.log("new before freetext");
    console.log(newSubjects);
    let newObj = newSubjects[currentSubject].adult.find(item => item.ref === ref);
    newObj.description=e.target.value;
    console.log("subjects after freetext");
    console.log(newSubjects);
    globalState.setSubjects(newSubjects);
  }

  const subjectAdultFreeTextValue = (index) => {
    let newSubjects = [...globalState.subjects];
    let freeTextOption = questions.subjectAdult.options.find(item => item.freetext===true);
    let freeTextValue = '';
    if(newSubjects[index].adult.find(item => item.ref === freeTextOption.ref)){
      freeTextValue = newSubjects[index].adult.find(item => item.ref === freeTextOption.ref).description;
    }
    return (freeTextValue);
  }

  /* this is for developing */
  /*
  const clearSubjects = () => {
    setTheme('');
    setSubject('');
    setCurrentSubject(0);
    globalState.setSubjects([]);
  }
  */

  return (

    <div>
      {subjectToDelete &&
        <YesNoModal 
          type='delete'
          header={t('modal.confirmDeleteSubject')}
          text={t('modal.deleteSubject') + ' ' + subjectToDelete.name.fi + '?'}
          method={handleDeleteSubject}
          item={subjectToDelete}
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
        />
      }
      <ApproveModal 
        type='info'
        header={t('modal.subjectIsAlreadySelected')}
        text={t('modal.selectOtherSubject')}
        open={approveModalOpen}
        setOpen={setApproveModalOpen}
      />
    
    <SubNavigation activePage='themes' />
    <Form>
    <Segment className='title'><h1 className={highlightUnfilledRequiredFields && globalState.subjects.length===0 ? 'highlight' : undefined}>{t('heading.select1ToMaxThemes').replace("max",config.configurations.subjectLimit)}<sup><i className='icon asterisk'></i></sup></h1></Segment>
    {globalState.subjects.map((item,index)=>
        <>
        <Grid key={index} stackable columns={3}>
          <Grid.Column>
            <Segment>
            <List className='themelist'>
              {currentSubject === index ?
                <>
                <h1>{t('contact.selectTheme')}<sup><i className='icon asterisk'></i></sup></h1>
                {subject.ref ?
                  <List.Item key={theme.ref}>{theme.name.fi}</List.Item>
                :
                  themes.map((t,index) =>
                    <List.Item key={t.ref} as={t.ref === theme.ref ? 'header' : 'a'} onClick={()=>handleSelectTheme(t)}>{t.name.fi}</List.Item>
                  )
                }
                </>
              :
                <>
                {index === 0 && <h1>{t('heading.theme')}</h1>}
                <List.Item key='theme' as='header'>{item.theme.name.fi}</List.Item>
                </>
              }
              </List>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <List className='subjectlist'>
              {currentSubject === index && theme.ref ?
                <>
                <h1>{t('contact.selectSubject')}<sup><i className='icon asterisk'></i></sup></h1>
                {subject.ref &&
                  <>
                  <List.Item key={subject.ref}>{subject.name.fi}</List.Item>
                  <List.Item key={index + 'changeSubject'} as='a' onClick={handleChangeSubject}>{t('contact.changeSubject')}</List.Item>
                  </>
                }
                {!subject.ref &&
                  themes.filter(t => t.ref === theme.ref)[0].subjects.map((s) =>
                    <List.Item key={s.ref} as={s.type === 'subtitle' ? 'header' : 'a'} onClick={s.type === 'subtitle' ? '' : ()=>handleSelectSubject(s)}>
                      {s.name.fi}
                      {s.type !== 'subtitle' && s.info.fi &&
                        <Popup content={s.info.fi} trigger={<i className='info icon'></i>} />
                      }
                    </List.Item>
                  )
                }
                </>
              :
                <>
                {index === 0 && <h1>{t('heading.subject')}</h1>}
                <List.Item key='subject' as='header'>{item.name.fi}</List.Item>
                </>
              }
              </List>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              {globalState.service.type === 'vpn' &&
                <>
                {currentSubject === index ?
                  <>
                  {/* subject is in edit mode */}
                  {theme.ref && subject.ref ?
                    <>
                      {/* theme and subject is selected -> show other options */}
                      <h1>{questions.subjectAppliesTo.name.fi}{questions.subjectAppliesTo.required && <sup><i className='icon asterisk'></i></sup>}</h1>

                        {/* if subject can apply to a child, show questions about child */}
                        {item.childTheme.ref &&
                          <Form.Checkbox
                            label={questions.subjectAppliesTo.options[0].name.fi}
                            value={questions.subjectAppliesTo.options[0].ref}
                            checked={item.subjectAppliesTo.find(item => item.ref === questions.subjectAppliesTo.options[0].ref)}
                            onClick={(e,{value}) => setSubjectAppliesTo(questions.subjectAppliesTo.options[0],index)}
                          />
                        }
                        {/* show more questions if checkbox is selected */}
                        {item.subjectAppliesTo.find(item => item.ref === questions.subjectAppliesTo.options[0].ref) &&
                          <Segment className='indented'>
                            <Question
                              data={questions.subjectChildAge || []} 
                              selectedValue={item.childAge} 
                              setSelectedValue={val => {
                                const x = questions.subjectChildAge.options.find(s => s.ref === val)
                                setSubjectChildAge(x,index)
                              }}
                            />
                            <Question
                              data={questions.subjectChildGender || []} 
                              selectedValue={item.childGender} 
                              setSelectedValue={val => {
                                const x = questions.subjectChildGender.options.find(s => s.ref === val)
                                setSubjectChildGender(x,index)
                              }}
                            />
                          </Segment>
                        }
                      
                        {/* if subject can apply to a child, show questions about child group */}
                        {item.childTheme.ref &&
                          <Form.Checkbox
                            label={questions.subjectAppliesTo.options[1].name.fi}
                            value={questions.subjectAppliesTo.options[1].ref}
                            checked={item.subjectAppliesTo.find(item => item.ref === questions.subjectAppliesTo.options[1].ref)}
                            onClick={(e,{value}) => setSubjectAppliesTo(questions.subjectAppliesTo.options[1],index)}
                          />
                        }
                        {/* show more questions if checkbox is selected */}
                        {item.subjectAppliesTo.find(item => item.ref === questions.subjectAppliesTo.options[1].ref) &&
                          <Segment className='indented'>
                            <Question
                              data={questions.subjectChildrenGroup || []} 
                              selectedValue={item.childrenGroup} 
                              setSelectedValue={val => {
                                const x = questions.subjectChildrenGroup.options.find(s => s.ref === val)
                                setSubjectChildrenGroup(x,index)
                              }}
                            />
                          </Segment>
                        }
                        
                        {/* if subject can apply to an adult, show questions about adult */}
                        {item.adultTheme.ref &&
                          <Form.Checkbox
                            label={questions.subjectAppliesTo.options[2].name.fi}
                            value={questions.subjectAppliesTo.options[2].ref}
                            checked={item.subjectAppliesTo.find(item => item.ref === questions.subjectAppliesTo.options[2].ref)}
                            onClick={(e,{value}) => setSubjectAppliesTo(questions.subjectAppliesTo.options[2],index)}
                          />
                        }
                        {/* show more questions if checkbox is selected */}
                        {item.subjectAppliesTo.find(item => item.ref === questions.subjectAppliesTo.options[2].ref) &&
                          <Segment className='indented'>
                            <Question
                              data={questions.subjectAdult || []} 
                              selectedValue={item.adult} 
                              setSelectedValue={val => {
                                const x = questions.subjectAdult.options.find(s => s.ref === val)
                                setSubjectAdult(x,index)
                              }}
                              freeTextValue={subjectAdultFreeTextValue(index)}
                              setFreeText={setSubjectAdultFreetext}
                            />
                          </Segment>
                        }

                        {/* subject is discussed in general */}
                        <Form.Checkbox
                          label={questions.subjectAppliesTo.options[3].name.fi}
                          value={questions.subjectAppliesTo.options[3].ref}
                          checked={item.subjectAppliesTo.find(item => item.ref === questions.subjectAppliesTo.options[3].ref)}
                          onClick={(e,{value}) => setSubjectAppliesTo(questions.subjectAppliesTo.options[3],index)}
                        />

                      <Button onClick={()=>handleClose(index)}>{t('general.ready')}</Button>
                    </>
                  :
                    <>
                    {/* theme and subject is not selected -> don't show checkboxes */}
                    {index === 0 && <h1>Kenen asioista aiheessa oli kyse?</h1>}
                    </>
                  }
                  </>
                :
                  <>
                    {/* subject is not in edit mode -> show edit and delete links */}
                    {index === 0 && <h1>Kenen asioista aiheessa oli kyse?</h1>}
                    {item.subjectAppliesTo.length===0 &&
                      <p className={highlightUnfilledRequiredFields ? 'highlight' : undefined}>Tarkentavat tiedot puuttuu</p>
                    }
                    <List>
                      {item.subjectAppliesTo.map((i,index) =>
                        <List.Item key={index} className={
                          ((i.ref==='adult' && item.adult.length===0) || 
                          (i.ref==='children' && item.childrenGroup.length===0) || 
                          (i.ref==='child' && (item.childAge.length===0 || item.childGender.length===0))) && 
                          highlightUnfilledRequiredFields ? 'highlight' : undefined
                        }>
                          {i.name.fi}
                        </List.Item>
                      )}
                    </List>
                    <List bulleted horizontal>
                      <List.Item key={index + 'edit'} as='a' onClick={() => handleEditSubject(item)}>{t('general.edit')}</List.Item>
                      <List.Item key={index + 'delete'} as='a' onClick={() => handleClickDelete(item)}>{t('general.delete')}</List.Item>
                    </List>
                  </>
                }
                </>
              }

              {globalState.service.type === 'lnpn' && currentSubject !== index &&
                <>
                {/* subject is not in edit mode -> show edit and delete links */}
                {index === 0 && <h1> </h1>}
                <List bulleted horizontal>
                  <List.Item key={index + 'edit'} as='a' onClick={() => handleEditSubject(item)}>{t('general.edit')}</List.Item>
                  <List.Item key={index + 'delete'} as='a' onClick={() => handleClickDelete(item)}>{t('general.delete')}</List.Item>
                </List>
                </>
              }
            </Segment>
          </Grid.Column>
        </Grid>
        </>
      )}

      {/* if edited subject is not saved yet and subject limit has not been reached */}
      {globalState.subjects.length === currentSubject && !subjectLimitReached &&
        <Grid stackable columns={3}>
          <Grid.Column>
            <Segment>
              <h1>{t('contact.selectTheme')}<sup><i className='icon asterisk'></i></sup></h1>
              <List className='themelist'>
                {subject.ref ?
                  <List.Item key={theme.ref}>{theme.name.fi}</List.Item>
                :
                  themes.map((t,index) =>
                    <List.Item key={t.ref} as={t.ref === theme.ref ? 'header' : 'a'} onClick={()=>handleSelectTheme(t)}>{t.name.fi}</List.Item>
                  )
                }
              </List>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              {theme.ref &&
                <>
                <h1>{t('contact.selectSubject')}<sup><i className='icon asterisk'></i></sup></h1>
                <List className='subjectlist'>
                  {subject.ref &&
                    <>
                    <List.Item key={subject.ref}>{subject.name.fi}</List.Item>
                    <List.Item key={subject.ref + 'changesubject'} as='a' onClick={() => handleChangeSubject()}>{t('contact.changeSubject')}</List.Item>
                    </>
                  }
                  {!subject.ref &&
                    themes.filter(t => t.ref === theme.ref)[0].subjects.map((s) =>
                      <List.Item key={s.ref} as={s.type === 'subtitle' ? 'header' : 'a'} onClick={s.type === 'subtitle' ? '' : ()=>handleSelectSubject(s)}>
                        {s.name.fi}
                        {s.type !== 'subtitle' && s.info.fi &&
                          <Popup content={s.info.fi} trigger={<i className='info icon'></i>} />
                        }
                      </List.Item>
                    )
                  }
                </List>
                </>
              }
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              
            </Segment>
          </Grid.Column>
        </Grid>
      }


      
      <Container>
          <Divider/>
          {/*<Button floated='left' onClick={() => clearSubjects()}>Testinappi: Tyhjennä aiheet</Button>*/}
          {contactDataOK().result ?
            <Button floated='right' onClick={() => saveContactData(props.history)}>{t('general.savecontact')}</Button>
          :
            <Button floated='right' onClick={() => props.history.push("/contact/specificinformation")}>{t('general.nextstep')}</Button>
          }
          {disableAddButton || subject.ref || theme.ref ?
            <Button disabled floated='right' onClick={()=>handleAddSubject()}>{t('contact.addSubject')}</Button>
          :
            <Button floated='right' onClick={()=>handleAddSubject()}>{t('contact.addSubject')}</Button>
          }
      </Container>
    </Form>
  </div>
  );
}

export default Themes;

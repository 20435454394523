import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Divider, Button, Message, Grid } from 'semantic-ui-react';
import useReadUser from '../../Hooks/Users/useReadUser';
import useReadReminder from '../../Hooks/Users/Reminder/useReadReminder';
import useDeleteReminder from '../../Hooks/Users/Reminder/useDeleteReminder';
import { useParams } from 'react-router-dom';
import { YesNoModal } from '../../Reusable/Modals/GeneralModal';

const ReminderDetail = (props) => {

    const { t } = useTranslation();
    const { reminder, readReminder } = useReadReminder();
    const { user, readUser } = useReadUser();
    const { deleteReminder } = useDeleteReminder();
    const { userId, reminderId } = useParams();
    const [ modalOpen, setModalOpen ] = useState(false);

    //console.log(reminder)

    useEffect(() => {
        (async() => {
            await readReminder(userId,reminderId);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async() => {
            if(reminder && reminder.data && reminder.data.UserId){
                await readUser(reminder.data.UserId);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reminder]);

    const delReminder = () => {
        (async () => {
            let result = null;
            result = await deleteReminder(userId,reminderId);
            if(result.status === 204){
                props.history.goBack();
            }
        })();
    }

    return (
        <div>
            <YesNoModal 
                type='delete'
                header={t('modal.confirmDelete')}
                text={t('modal.confirmDeleteReminder')}
                method={delReminder}
                open={modalOpen}
                setOpen={setModalOpen}
            />
           <Container>
                <h1>{t('heading.reminder')}</h1>
                { reminder && reminder.data &&
                    <>
                    <h2>{t('heading.user')}</h2>
                    <div>{user.data && user.data.FirstName} {user.data && user.data.LastName}</div>

                    <h2>{t('reminder.recipient')}</h2>
                    <div>{reminder.data.Recipient}</div>

                    <h2>{t('reminder.date')}</h2>
                    <div>{new Date(reminder.data.Date).toLocaleDateString()}</div>

                    <h2>{t('reminder.subject')}</h2>
                    <div>{reminder.data.Subject}</div>

                    <h2>{t('reminder.description')}</h2>
                    <div>{reminder.data.Body}</div>
                    </>
                }

            </Container>
            <Divider/>
            <Button onClick={() => props.history.push('/user/' + userId + '/reminder/edit/' + reminderId)}>{t('general.edit')}</Button>
            <Button onClick={() => setModalOpen(true)}>{t('general.delete')}</Button>
            <Button onClick={() => props.history.goBack()}>{t('general.back')}</Button>
        </div>
    )

}

export default ReminderDetail;
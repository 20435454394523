import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Form,  Divider, Button, Grid } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import useCreateUser from '../../Hooks/Users/useCreateUser';
import { MessageContext } from '../../Context/MessageContext';
import useCreateUserService from '../../Hooks/Users/useCreateUserService';
import useCreateUserType from '../../Hooks/Users/useCreateUserType';
import useListActivityTypes from '../../Hooks/Settings/useListActivityTypes';
import { StateContext } from '../../Context/StateContext';

const CreateUser = (props) => {

    const { t } = useTranslation();
    const { createUser } = useCreateUser();
    const { addMessage, removeMessage, messages } = useContext(MessageContext);
    const { createUserService } = useCreateUserService();
    const { createUserType } = useCreateUserType();
    const { activityTypes, listActivityTypes } = useListActivityTypes();
    const { locations, services, types } = useContext(StateContext);

    const [ firstname, setFirstname ] = useState('');
    const [ lastname, setLastname ] = useState('');
    const [ city, setCity ] = useState('');
    const [ username, setUsername ] = useState('');
    const [ emailAddress, setEmailAddress ] = useState('');
    const [ phoneNumber, setPhoneNumber ] = useState('');
    const [ startDate, setStartDate ] = useState('');
    const [ userActivityType, setUserActivityType ] = useState('');
    const [ mllPhoneNumber, setMllPhoneNumber ] = useState('');
    const [ mllProperty, setMllProperty ] = useState('');
    const [ description, setDescription ] = useState('');
    const [ specialDiet, setSpecialDiet ] = useState('');
    const [ nextOneToOne, setNextOneToOne ] = useState('');
    const [ keepOnMailingList, setKeepOnMailingList ] = useState(false);
    const [ setBreak, setSetBreak ] = useState(false);
    const [ breakStartDate, setBreakStartDate ] = useState('');
    const [ isEmployee, setIsEmployee ] = useState(false);
    const [ isActive, setIsActive ] = useState(true);
    const [ onCallRemote, setOnCallRemote ] = useState(undefined);
    const [ selectedServices, setSelectedServices ] = useState([]);
    const [ selectedTypes, setSelectedTypes ] = useState([]);
    const [ userShiftCountInPreviousSystem, setUserShiftCountInPreviousSystem ] = useState(0);


    let locationOptions = []
    locations.map(l => {
        if(l.Reference!=='remote'){
            const tmp = {
                key: l.Id,
                text: l.Name,
                value: l.Id
            }
            locationOptions.push(tmp);
        }
        return l;
    })

     // Fetch activity types
     useEffect(() => {
        (async () => {
            await listActivityTypes();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    let activityTypeOptions = []
    activityTypes.map(at => {
        const tmp = {
            key: at.Id,
            text: at.Name,
            value: at.Id
        }
        activityTypeOptions.push(tmp)
        return at;
    })

    const setServices = (value) => {
        const array = [...selectedServices]
        if(array.find(item => item === value)){
            // uncheck, remove item from array
            setSelectedServices(array.filter(item => item !== value))
        } else {
            // check, add item to array
            array.push(value)
            setSelectedServices(array)
        }
    }

    const setTypes = (value,type) => {
        const array = [...selectedTypes];
        const selectedItem = array.find(item => item.id === value);
        if(selectedItem){
            // type found, check if present or remote and change value
            if(type==='present'){
                selectedItem.present = !selectedItem.present;
            } else if(type==='remote') {
                selectedItem.remote = !selectedItem.remote;
            }
        } else {
            // type not found, add item to array
            if(type==='present'){
                array.push({id: value, present: true, remote: false});
            } else if(type==='remote') {
                array.push({id: value, present: false, remote: true});
            }
        }
        setSelectedTypes(array);
    }

    const handleRemote = (isRemote) => {
        setOnCallRemote(isRemote)
        // delete remote types from selected user types
        let array = [...selectedTypes]
        array = array.map(item => {
            item.remote = false;
            return item;
        })
        setSelectedTypes(array)
    }

    const handleActivityChange = (value) => {
        setUserActivityType(value)

        //set isActive
        if(activityTypes.find(item => item.Id === value).RefName === 'onBreak' || activityTypes.find(item => item.Id === value).RefName === 'quit') {
            setIsActive(false)
        } else {
            setIsActive(true)
        }
    }

    const handleEmployeeChoice = () => {
        if(!isEmployee){
            addMessage({type: "warning", header: t('message.dataMayBeLost'), body: t('message.onCallDataMayBeLost')})
        }
        setIsEmployee(!isEmployee)
    }

    const handleSave = async () => {
        messages.map(msg => removeMessage(msg.id));

        //validate
        if(!username || !firstname || !lastname || !city || !emailAddress || (!isEmployee && !userActivityType)) {
            addMessage({type: "error", header: t('message.fillInRequiredInformation'), body: t('message.requiredMarkedWithAsterisk')})
            window.scrollTo(0, 0)
        } else {

            // Format dates
            let dateParts = []
            let startDateISO = null;
            if(startDate) {
                dateParts=startDate.split('.');
                startDateISO=new Date(dateParts[2] + "-" + dateParts[1].padStart(2,"0") + "-" + dateParts[0].padStart(2,"0")).toISOString();
            }

            let nextOneToOneISO = null;
            if(nextOneToOne) {
                dateParts=nextOneToOne.split('.');
                nextOneToOneISO=new Date(dateParts[2] + "-" + dateParts[1].padStart(2,"0") + "-" + dateParts[0].padStart(2,"0")).toISOString();
            }

            let breakStartDateISO = null;
            if(setBreak && breakStartDate) {
                dateParts=breakStartDate.split('.');
                breakStartDateISO=new Date(dateParts[2] + "-" + dateParts[1].padStart(2,"0") + "-" + dateParts[0].padStart(2,"0")).toISOString();
            }

            //create data
            let data = {}
            // some data is not saved for employees
            if(isEmployee){
                data = {
                    "username": username.trim(),
                    "emailAddress": emailAddress.trim(),
                    "firstName": firstname,
                    "lastName": lastname,
                    "locationId": city,
                    "isActive": true,
                    "sendInvitation": true,
                    "isEmployee": isEmployee,
                    "isRemoteWorker": false,
                    "phoneNumber": phoneNumber,
                    "startDate": null,
                    "userActivityType": 0,
                    "mllPhoneNumber": '',
                    "mllProperty": '',
                    "description": '',
                    "specialDiet": '',
                    "nextOneToOne": null,
                    "keepOnMailingList": false,
                    "breakStartDate": null,
                    "userShiftCountInPreviousSystem": null
                }
            } else {
                data = {
                    "username": username.trim(),
                    "emailAddress": emailAddress.trim(),
                    "firstName": firstname,
                    "lastName": lastname,
                    "locationId": city,
                    "isActive": isActive,
                    "sendInvitation": true,
                    "isEmployee": isEmployee,
                    "isRemoteWorker": onCallRemote,
                    "phoneNumber": phoneNumber,
                    "startDate": startDateISO,
                    "userActivityType": userActivityType,
                    "mllPhoneNumber": mllPhoneNumber,
                    "mllProperty": mllProperty,
                    "description": description,
                    "specialDiet": specialDiet,
                    "nextOneToOne": nextOneToOneISO,
                    "keepOnMailingList": keepOnMailingList,
                    "breakStartDate": breakStartDateISO,
                    "userShiftCountInPreviousSystem": parseInt(userShiftCountInPreviousSystem)
                }
            }

            //save
            let result = null;
            result = await createUser(data);

            if(result.status === 400 && result.data.code === -2){
                addMessage({ type: "error", header: t('general.error'), body: t('errors.userExists') });
            }

            const userId = result.data.id

            if(result.status === 201 && userId) {
                // save user services
                selectedServices.map(item => {
                    data = { serviceId: item }
                    result = createUserService(userId,data,false);
                    return item;
                })

                // save user types
                selectedTypes.map(item => {
                    data = { typeId: item.id, remote: item.remote, present: item.present}
                    result = createUserType(userId,data,false);
                    return item;
                })
                props.history.goBack();
            }
        }
    }

    //console.log(selectedTypes)

    return (
        <div className='userform'>
            <Form>
                <Container className="userlist">
                    <h1>{t('heading.user')}</h1>

                    <Grid stackable columns={2}>
                        <Grid.Column>
                            <h2>{t('user.lastname')}<sup><i className='icon asterisk'></i></sup></h2>
                            <Form.Input value={lastname} onChange={(e) => setLastname(e.target.value)} />
                        
                            <h2>{t('user.firstname')}<sup><i className='icon asterisk'></i></sup></h2>
                            <Form.Input value={firstname} onChange={(e) => setFirstname(e.target.value)} />

                            <h2>{t('user.city')}<sup><i className='icon asterisk'></i></sup></h2>
                            <Form.Select value={city} options={locationOptions} onChange={(e, {value}) => setCity(value)} />

                            <h2>{t('user.username')}<sup><i className='icon asterisk'></i></sup></h2>
                            <Form.Input onChange={(e) => setUsername(e.target.value)} />

                            <Form.Checkbox
                                label={t('user.employee')} 
                                checked={isEmployee} 
                                onChange={(e, {checked}) => handleEmployeeChoice()}
                            />

                            <h2>{t('user.email')}<sup><i className='icon asterisk'></i></sup></h2>
                            <Form.Input value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />

                            <h2>{t('user.phone')}</h2>
                            <Form.Input value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />

                            {!isEmployee &&
                                <>
                                <h2>{t('user.description')}</h2>
                                <Form.TextArea
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />

                                <h2>{t('user.specialDiet')}</h2>
                                <Form.TextArea
                                    value={specialDiet}
                                    onChange={(e) => setSpecialDiet(e.target.value)}
                                />

                                <h2>{t('user.startDate')} {t('user.basicCourseStartDate')}</h2>
                                <DateInput
                                    localization='fi'
                                    dateFormat={'DD.MM.YYYY'}
                                    value={startDate || ""}
                                    iconPosition="right"
                                    onChange={(ev, {value}) => setStartDate(value) }
                                    closable={true}
                                    popupPosition='bottom left'
                                />
                                </>
                            }
                        </Grid.Column>
                        <Grid.Column>
                            {!isEmployee &&
                                <>
                                <Form.Group inline>
                                    <h2 className='add-margin-right'>{t('user.remote')}</h2>
                                    <Form.Radio
                                        label={t('general.yes')}
                                        checked={onCallRemote}
                                        onChange={e => handleRemote(true)}
                                    />
                                    <Form.Radio
                                        label={t('general.no')}
                                        checked={onCallRemote === false}
                                        onChange={e => handleRemote(false)}
                                    />
                                </Form.Group>
                                { onCallRemote &&
                                    <>
                                    <h2>{t('user.mllPhoneNumber')}</h2>
                                    <Form.Input value={mllPhoneNumber} onChange={(e) => setMllPhoneNumber(e.target.value)} />
                                    <h2>{t('user.mllProperty')}</h2>
                                    <Form.Input value={mllProperty} onChange={(e) => setMllProperty(e.target.value)} />
                                    </>
                                }

                                <h2>{t('user.services')}</h2>
                                {services.map(item => (
                                    <Form.Checkbox
                                        key={item.Id}
                                        label={item.Name}
                                        value={item.Id}
                                        checked={selectedServices.find(s => s === item.Id)!== undefined}
                                        onChange={(e,{value}) => setServices(value)}
                                    />
                                ))}

                                <Grid columns={3} className='add-margin small-padding'>
                                    <Grid.Row>
                                        <Grid.Column width={4}>
                                            <h2>{t('user.channels')}</h2>
                                        </Grid.Column>
                                        <Grid.Column width={2}>
                                            {t('user.isPresent')}
                                        </Grid.Column>
                                        <Grid.Column>
                                            {t('user.isRemote')}
                                        </Grid.Column>
                                    </Grid.Row>
                                    {types.filter(item => item.ServiceId !== null).map(item => (
                                        <Grid.Row>
                                            <Grid.Column width={4}>
                                                {item.Abbreviation}
                                            </Grid.Column>
                                            <Grid.Column width={2}>
                                                <Form.Checkbox
                                                    key={item.Id + 'present'}
                                                    value={item.Id}
                                                    checked={selectedTypes.find(s => s.id === item.Id && s.present)!== undefined}
                                                    onChange={(e,{value}) => setTypes(value,'present')}
                                                />
                                            </Grid.Column>
                                            <Grid.Column>
                                                {onCallRemote &&
                                                    <Form.Checkbox
                                                        key={item.Id + 'remote'}
                                                        value={item.Id}
                                                        checked={selectedTypes.find(s => s.id === item.Id && s.remote)!== undefined}
                                                        onChange={(e,{value}) => setTypes(value,'remote')}
                                                    />
                                                }
                                            </Grid.Column>
                                        </Grid.Row>
                                    ))}
                                </Grid>

                                <h2>{t('user.activity')}<sup><i className='icon asterisk'></i></sup></h2>
                                <Form.Select
                                    value={userActivityType}
                                    options={activityTypeOptions}
                                    onChange={(e, {value}) => handleActivityChange(value)}
                                />

                                {activityTypes && userActivityType && activityTypes.find(item => item.Id === userActivityType).RefName === 'onBreak' ?
                                    <Form.Checkbox
                                        label={t('user.active')} 
                                        checked={isActive} 
                                        onChange={(e, {checked}) => setIsActive(!isActive)}
                                    />
                                :
                                    <Form.Checkbox
                                        label={t('user.active')} 
                                        checked={isActive}
                                        disabled
                                    />
                                }

                                <Form.Checkbox
                                    label={t('user.keepOnMailingList')}
                                    checked={keepOnMailingList}
                                    onChange={e => setKeepOnMailingList(!keepOnMailingList)}
                                />
                                <Form.Checkbox
                                    label={t('user.setBreak')}
                                    checked={setBreak}
                                    onChange={e => setSetBreak(!setBreak)}
                                />
                                {setBreak &&
                                    <>
                                    <h2>{t('user.breakStartDate')}</h2>
                                    <DateInput
                                        localization='fi'
                                        dateFormat={'DD.MM.YYYY'}
                                        value={breakStartDate || ""}
                                        iconPosition="right"
                                        onChange={(ev, {value}) => setBreakStartDate(value) }
                                        closable={true}
                                        popupPosition='bottom left'
                                    />
                                    </>
                                }

                                <h2>{t('user.nextOneToOne')}</h2>
                                <DateInput
                                    localization='fi'
                                    dateFormat={'DD.MM.YYYY'}
                                    value={nextOneToOne || ""}
                                    iconPosition="right"
                                    onChange={(ev, {value}) => setNextOneToOne(value) }
                                    closable={true}
                                    popupPosition='bottom left'
                                />

                                <h2>{t('user.shiftsInPreviousSystem')}</h2>
                                <Form.Input value={userShiftCountInPreviousSystem} onChange={(e) => setUserShiftCountInPreviousSystem(e.target.value)} />
                                </>
                            }
                        </Grid.Column>
                    </Grid>
                
                </Container>
                <Divider/>
                <Button onClick={() => handleSave()}>{t('general.save')}</Button>
                <Button onClick={() => props.history.goBack()}>{t('general.cancel')}</Button>
            </Form>
        </div>
    )

}

export default CreateUser;
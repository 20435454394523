import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Form,  Divider, Button, Table, Grid } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import useReadUser from '../../Hooks/Users/useReadUser';
import useListParticipationTypes from '../../Hooks/Settings/useListParticipationTypes';
import useReadOtherParticipation from '../../Hooks/Users/OtherParticipation/useReadOtherParticipation';
import useUpdateOtherParticipation from '../../Hooks/Users/OtherParticipation/useUpdateOtherParticipation';
import { MessageContext } from '../../Context/MessageContext';

const EditOtherParticipation = (props) => {

    const { t } = useTranslation();
    const { addMessage, removeMessage, messages } = useContext(MessageContext);
    const { userId, participationId } = useParams();
    const { user, readUser } = useReadUser();
    const { participationTypes, listParticipationTypes } = useListParticipationTypes();
    const { otherParticipation, readOtherParticipation } = useReadOtherParticipation();
    const { updateOtherParticipation } = useUpdateOtherParticipation();

    const [ date, setDate ] = useState(null);
    const [ selectedParticipationType, setSelectedParticipationType ] = useState(null);
    const [ description, setDescription ] = useState('');

    useEffect(() => {
        (async() => {
            await listParticipationTypes();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let participationTypeOptions = []
    if (participationTypes) {
        participationTypes.map(badge => {
            const tmp = {
                key: badge.Id,
                text: badge.Name,
                value: badge.Id
            }
            participationTypeOptions.push(tmp)
            return badge;
        })
    }

    useEffect(() => {
        (async() => {
            await readOtherParticipation(userId,participationId);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async() => {
            await readUser(userId);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Set user data
    useEffect(() => {
        (async () => {
            if (otherParticipation.data && otherParticipation.data.Id) {
                setDate(otherParticipation.data.Date ? new Date(otherParticipation.data.Date).toLocaleDateString() : '');
                setDescription(otherParticipation.data.Description || '');
                setSelectedParticipationType(otherParticipation.data.ParticipationTypeId || null);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otherParticipation]);

    const handleSave = async () => {
        messages.map(msg => removeMessage(msg.id));

        //validate
        if(!date || !selectedParticipationType) {
            addMessage({type: "error", header: t('message.fillInRequiredInformation'), body: t('message.requiredMarkedWithAsterisk')})
            window.scrollTo(0, 0)
        } else {
            // Format date
            let dateParts = []
            let dateISO = null;
            if(date) {
                dateParts=date.split('.');
                dateISO=new Date(dateParts[2] + "-" + dateParts[1].padStart(2,"0") + "-" + dateParts[0].padStart(2,"0")).toISOString();
            }

            //create data
            let data = {
                "date": dateISO,
                "typeId": selectedParticipationType,
                "description": description
            }

            //save
            let result = null;
            result = await updateOtherParticipation(userId,participationId,data);

            if(result.status === 200) {
                props.history.goBack();
            }
        }
    }

    return (
        <div className='userform'>
            <Form>
                <Container className="userlist">
                    <h1>{t('heading.user')}</h1>
                    
                    <h2>{t('heading.user')}</h2>
                    <div>{user.data && user.data.FirstName} {user.data && user.data.LastName}</div>
                
                    <h2>{t('otherParticipation.date')}<sup><i className='icon asterisk'></i></sup></h2>
                    <DateInput
                        localization='fi'
                        dateFormat={'DD.MM.YYYY'}
                        value={date || ""}
                        iconPosition="right"
                        onChange={(ev, {value}) => setDate(value) }
                        closable={true}
                        popupPosition='bottom left'
                    />

                    <h2>{t('otherParticipation.type')}<sup><i className='icon asterisk'></i></sup></h2>
                    <Form.Select
                        value={selectedParticipationType}
                        options={participationTypeOptions}
                        onChange={(e, {value}) => setSelectedParticipationType(value)}
                    />
                
                    <h2>{t('otherParticipation.description')}</h2>
                    <Form.TextArea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </Container>
            </Form>
            <Divider/>
            <Button onClick={() => handleSave()}>{t('general.save')}</Button>
            <Button onClick={() => props.history.goBack()}>{t('general.cancel')}</Button>
        </div>
    )

}

export default EditOtherParticipation;
import React, { useContext } from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { Menu, Container } from 'semantic-ui-react';
import { createMedia } from "@artsy/fresnel";
import { useTranslation } from 'react-i18next';
import { StateContext } from '../Context/StateContext';

const SubNavigation = ({activePage}) => {

  const { t } = useTranslation();

  const { globalState } = useContext(StateContext);

  const { MediaContextProvider, Media } = createMedia({
      breakpoints: {
          sm: 0,
          md: 768,
          lg: 1024,
          xl: 1192,
      }
  });

  return (
    
    <div className="subnavigation">

      {!globalState.service.type &&
        <Redirect to='/'/>
      }

      <Container>
      
        <MediaContextProvider>
          <Media greaterThan='md'>
            <Menu pointing secondary>
              <Menu.Item active={activePage === 'contacttype'}>
                <NavLink to='/contact/contacttype'>{t('subnavigation.contacttype')}</NavLink>
              </Menu.Item>
              <Menu.Item active={activePage === 'backgroundinformation'}>
              {globalState.contactType.ref==='conversationContact' ?
                <NavLink to='/contact/backgroundinformation'>{t('subnavigation.backgroundinformation')}</NavLink>
              :
                <NavLink to='#' className='disabled-link'>{t('subnavigation.backgroundinformation')}</NavLink>
              }
              </Menu.Item>
              {globalState.contactType.ref==='conversationContact' && globalState.dialogue ?
                <>
                <Menu.Item active={activePage === 'previoussupport'}>
                  <NavLink to='/contact/previoussupport'>{t('subnavigation.previoussupport')}</NavLink>
                </Menu.Item>
                <Menu.Item active={activePage === 'themes'}>
                  <NavLink to='/contact/themes'>{t('subnavigation.themes')}</NavLink>
                </Menu.Item>
                <Menu.Item active={activePage === 'specificinformation'}>
                  <NavLink to='/contact/specificinformation'>{t('subnavigation.specificinformation')}</NavLink>
                </Menu.Item>
                <Menu.Item active={activePage === 'referral'}>
                  <NavLink to='/contact/referral'>{t('subnavigation.referral')}</NavLink>
                </Menu.Item>
                <Menu.Item active={activePage === 'retrospective'}>
                  <NavLink to='/contact/retrospective'>{t('subnavigation.retrospective')}</NavLink>
                </Menu.Item>
                </>
              :
                <>
                <Menu.Item active={activePage === 'previoussupport'}>
                  <NavLink to='#' className='disabled-link'>{t('subnavigation.previoussupport')}</NavLink>
                </Menu.Item>
                <Menu.Item active={activePage === 'themes'}>
                  <NavLink to='#' className='disabled-link'>{t('subnavigation.themes')}</NavLink>
                </Menu.Item>
                <Menu.Item active={activePage === 'specificinformation'}>
                  <NavLink to='#' className='disabled-link'>{t('subnavigation.specificinformation')}</NavLink>
                </Menu.Item>
                <Menu.Item active={activePage === 'referral'}>
                  <NavLink to='#' className='disabled-link'>{t('subnavigation.referral')}</NavLink>
                </Menu.Item>
                <Menu.Item active={activePage === 'retrospective'}>
                  <NavLink to='#' className='disabled-link'>{t('subnavigation.retrospective')}</NavLink>
                </Menu.Item>
                </>
              }
            </Menu>
          </Media>
          <Media lessThan='lg'>
            <Menu pointing secondary>
              {activePage==='contacttype' &&
                <>
                <Menu.Menu position='right'>
                  <Menu.Item disabled={!globalState.contactType.ref==='conversationContact'}><NavLink to='/contact/backgroundinformation'>{t('subnavigation.next')}</NavLink></Menu.Item>
                </Menu.Menu>
                </>
              }
              {activePage==='backgroundinformation' &&
                <>
                <Menu.Item><NavLink to='/contact/contacttype'>{t('subnavigation.previous')}</NavLink></Menu.Item>
                <Menu.Menu position='right'>
                  <Menu.Item><NavLink to='/contact/previoussupport'>{t('subnavigation.next')}</NavLink></Menu.Item>
                </Menu.Menu>
                </>
              }
              {activePage==='previoussupport' &&
                <>
                <Menu.Item><NavLink to='/contact/backgroundinformation'>{t('subnavigation.previous')}</NavLink></Menu.Item>
                <Menu.Menu position='right'>
                  <Menu.Item><NavLink to='/contact/themes'>{t('subnavigation.next')}</NavLink></Menu.Item>
                </Menu.Menu>
                </>
              }
              {activePage==='themes' &&
                <>
                <Menu.Item><NavLink to='/contact/previoussupport'>{t('subnavigation.previous')}</NavLink></Menu.Item>
                <Menu.Menu position='right'>
                  <Menu.Item><NavLink to='/contact/specificinformation'>{t('subnavigation.next')}</NavLink></Menu.Item>
                </Menu.Menu>
                </>
              }
              {activePage==='specificinformation' &&
                <>
                <Menu.Item><NavLink to='/contact/themes'>{t('subnavigation.previous')}</NavLink></Menu.Item>
                <Menu.Menu position='right'>
                  <Menu.Item><NavLink to='/contact/referral'>{t('subnavigation.next')}</NavLink></Menu.Item>
                </Menu.Menu>
                </>
              }
              {activePage==='referral' &&
                <>
                <Menu.Item><NavLink to='/contact/specificinformation'>{t('subnavigation.previous')}</NavLink></Menu.Item>
                <Menu.Menu position='right'>
                  <Menu.Item><NavLink to='/contact/retrospective'>{t('subnavigation.next')}</NavLink></Menu.Item>
                </Menu.Menu>
                </>
              }
              {activePage==='retrospective' &&
                <>
                <Menu.Item><NavLink to='/contact/referral'>{t('subnavigation.previous')}</NavLink></Menu.Item>
                </>
              }
            </Menu>
          </Media>
        
        </MediaContextProvider>
      
      </Container>
    </div>
  );
}


export default SubNavigation;

import React, { useState, useContext, useEffect } from 'react';
import { Form, Checkbox, Divider, Grid, Button, Modal, Header, Icon } from 'semantic-ui-react';
import PagedTable from '../../Reusable/Lists/PagedTable';
import { useTranslation } from 'react-i18next';
import { StateContext } from '../../Context/StateContext';
import { MessageContext } from '../../Context/MessageContext';
import useSearchShiftTypes from '../../Hooks/Shifts/useSearchShiftTypes';
import useCreateShiftUser from '../../Hooks/ShiftUsers/useCreateShiftUser';
import useDeleteShiftUser from '../../Hooks/ShiftUsers/useDeleteShiftUser';
import useListUsers from '../../Hooks/Users/useListUsers';
import { YesButton, NoButton, CloseButton } from '../../Reusable/Buttons/Buttons';
import useCreateSendQueueEmail from '../../Hooks/SendQueue/useCreateSendQueueEmail';
import { createMedia } from "@artsy/fresnel";
import { Link } from 'react-router-dom';

const ShiftList = (props) => {

    const { MediaContextProvider, Media } = createMedia({
        breakpoints: {
            sm: 0,
            md: 768,
            lg: 1024,
            xl: 1192,
        }
    });

    const { t } = useTranslation();
    const { globalState, locations, services, channels, types, profile } = useContext(StateContext);
    const { addMessage, removeMessage, messages } = useContext(MessageContext);
    const { shiftTypes, searchShiftTypes } = useSearchShiftTypes();
    const { users, listUsers } = useListUsers();
    const { createShiftUser } = useCreateShiftUser();
    const { deleteShiftUser } = useDeleteShiftUser();
    const [ detailOpen, setDetailOpen ] = useState(false);
    const [ registrationConfirmOpen, setRegistrationConfirmOpen ] = useState(false);
    const [ unregistrationConfirmOpen, setUnregistrationConfirmOpen ] = useState(false);
    const [ joinRequestConfirmOpen, setJoinRequestConfirmOpen ] = useState(false);
    const [ joinRequestConfirm, setJoinRequestConfirm ] = useState('');
    const [ shiftInPastModalOpen, setShiftInPastModalOpen ] = useState(false);
    const [ selectedShift, setSelectedShift ] = useState(null);
    const [ refresh, setRefresh ] = useState(false);
    const [ weekdays, setWeekdays ] = useState([]);
    const [ showAllUpcomingEvents, setShowAllUpcomingEvents ] = useState(false);
    const [ sendReminder, setSendReminder ] = useState(true)
    const { createSendQueueEmail } = useCreateSendQueueEmail();

    let serviceOptions = [{key: 'all', text: t('shift.service-options.all'), value: ''},]
    services.map(s => {
        const option = {
            key: s.Id,
            text: s.Name,
            value: s.Id
        }
        serviceOptions.push(option);
        return s;
    })

    let channelOptions = [{key: 'all', text: t('shift.channel-options.all'), value: ''},]
    channels.map(c => {
        const option = {
            key: c.Id,
            text: t('shift.channel-options.' + c.Reference),
            value: c.Id
        }
        channelOptions.push(option);
        return c;
    })

    let typeOptions = [{key: 'all', text: t('shift.type-options.all'), value: ''},]
    types.map(i => {
        const option = {
            key: i.Id,
            text: i.Name,
            value: i.Id
        }
        typeOptions.push(option);
        return i;
    })

    const months = [
        { key: '01', text: t('general.january'), value: 1 },
        { key: '02', text: t('general.february'), value: 2 },
        { key: '03', text: t('general.march'), value: 3 },
        { key: '04', text: t('general.april'), value: 4 },
        { key: '05', text: t('general.may'), value: 5 },
        { key: '06', text: t('general.june'), value: 6 },
        { key: '07', text: t('general.july'), value: 7 },
        { key: '08', text: t('general.august'), value: 8 },
        { key: '09', text: t('general.september'), value: 9 },
        { key: '10', text: t('general.october'), value: 10 },
        { key: '11', text: t('general.november'), value: 11 },
        { key: '12', text: t('general.december'), value: 12 }
    ]

    const createDate = (startdate,offset) => {
        const d = new Date(startdate)
        const day = d.getDate()
        d.setDate(day + offset)
        return d.toLocaleDateString();
    }

    /* show years from beginning to year after current year */
    let years = []
    for (let i = 2021; i < new Date().getFullYear()+2; i++) {
        years.push({key: i, text: i, value: i });
    }

    // Fetch users
    useEffect(() => {
        (async () => {
            await listUsers();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // add detail links to shift types
    useEffect(() => {
        shiftTypes.map(shift => {
            shift.typeName=types.find(item => item.Id === shift.TypeId) !== undefined && types.find(item => item.Id === shift.TypeId).Name;
            if(shift.typeName.startsWith('LNPN')){
                shift.serviceColor="mll-pink";
            } else if(shift.typeName.startsWith('VPN')){
                shift.serviceColor="mll-blue";
            } else if(shift.typeName.startsWith('BUT')){
                shift.serviceColor="mll-black";
            } else {
                shift.serviceColor="mll-blue";
            }
            if(shift.typeName.endsWith('Puhelin') || shift.typeName.endsWith('Telefon')){
                shift.channelColor="mll-turqoise";
            } else if(shift.typeName.endsWith('Chat')){
                shift.channelColor="mll-green";
            } else if(shift.typeName.endsWith('Nettikirje') || shift.typeName.endsWith('Brevtjänst')){
                shift.channelColor="mll-beige";
            } else {
                //shift.channelColor="mll-blue";
            }
            if(shift.typeName.startsWith('LNPN') || shift.typeName.startsWith('VPN') || shift.typeName.startsWith('BUT')){
                shift.iconName="sticky note";
            } else {
                shift.iconName="sticky note outline";
            }
            shift.Date=shift.Shift[0].Date;
            shift.Time=new Date(shift.Shift[0].StartTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) + '-' + new Date(shift.Shift[0].EndTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            shift.StartTime=shift.Shift[0].StartTime;
            shift.EndTime=shift.Shift[0].EndTime;
            shift.InstructorName=shift.Shift[0].InstructorName;
            shift.InstructorPhone=shift.Shift[0].InstructorPhone;
            shift.LocationName=shift.Shift[0].LocationName;
            shift.Description=shift.Shift[0].Description;
            shift.ShiftUsersRegistered = (shift.ShiftUsers ? shift.ShiftUsers.length : '0') + '/' + shift.ShiftUserCount
            shift.ShiftUserCountLeft = shift.ShiftUsers ? shift.ShiftUserCount - shift.ShiftUsers.length : shift.ShiftUserCount;
            if(new Date(shift.EndTime).getTime() < new Date().getTime()) {
                shift.InPast = true
            }
            if(shift.ShiftUsers && shift.ShiftUsers.find(item => item.UserId === profile.Id) !== undefined) {
                // user has registered
                shift.actionLink=t('shift.unregister')
            } else if(shift.ShiftUserCountLeft===0){
                // shift is full
                shift.actionLink=t('shift.register')
                shift.actionText=t('shift.full')
            } else {
                shift.actionLink=t('shift.register')
            }
            return shift;
            
        })

        // set weekdays to show in search result
        let wds = []
        if(showAllUpcomingEvents){
            shiftTypes.map(item => {
                const obj = {num: new Date(item.Shift[0].Date).getDay(), date: createDate(new Date(item.Shift[0].Date),0)}
                // if new date, add
                if(wds.findIndex(wd => wd.date === obj.date)===-1){
                    wds.push(obj)
                }
                return item;
            })
        } else {
            wds = [
                {num: 1, date: createDate(globalState.shiftStartDate,0)},
                {num: 2, date: createDate(globalState.shiftStartDate,1)},
                {num: 3, date: createDate(globalState.shiftStartDate,2)},
                {num: 4, date: createDate(globalState.shiftStartDate,3)},
                {num: 5, date: createDate(globalState.shiftStartDate,4)},
                {num: 6, date: createDate(globalState.shiftStartDate,5)},
                {num: 0, date: createDate(globalState.shiftStartDate,6)}
            ]
        }
        setWeekdays(wds)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[shiftTypes,types,profile]);

    const [isSending, setIsSending] = useState(false)
    const [ defaultSearchDone, setDefaultSearchDone ] = useState(false);

    // make default search
    useEffect(() => {
        if(globalState.shiftLocations.length && !defaultSearchDone){
            setIsSending(true)
            sendRequest()
            setDefaultSearchDone(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[globalState.shiftLocations])

    const setLocations = (value) => {
        let newLocations = [];
        if(globalState.shiftLocations.includes(value)){
            // uncheck -> remove item from array
            newLocations = globalState.shiftLocations.filter(item => item!==value)
        } else {
            //check -> add item to array
            newLocations = globalState.shiftLocations.concat(value)
        }
        globalState.setShiftLocations(newLocations)
    }

    const sendRequest = async (offset = 0) => {

        // don't send again while we are sending
        if (isSending) return

        // update state
        setIsSending(true)

        // check if own shifts is selected or if selected type is training or recreation
        let showAll = false;
        if(globalState.ownShiftsOnly)  {
            showAll = true;
        } else if(types.filter(item => item.ServiceId===null).some(item => item.Id === globalState.shiftType)){
            showAll = true;
        }

        setShowAllUpcomingEvents(showAll);

        let startdate = new Date();
        let enddate = new Date();
        if(!showAll){
            startdate = new Date(globalState.shiftStartDate);
            if(offset!==0){
                // offset from previous startdate
                startdate.setDate(startdate.getDate() + offset)
            } else {
                // month is selected, set startdate to monday
                startdate.setDate(startdate.getDate() - (startdate.getDay() + 6) % 7);
            }
            
            // set enddate to monday of next week
            enddate = new Date(startdate);
            enddate.setDate(startdate.getDate() + 7);
        }
        
        globalState.setShiftStartDate(new Date(startdate))

        // set time to 00:00:00.000
        let month = parseInt(startdate.getMonth()) + 1
        startdate = new Date(startdate.getFullYear() + '-' + month.toString().padStart(2,'0') + '-' + startdate.getDate().toString().padStart(2,'0'));
        month = parseInt(enddate.getMonth()) + 1
        enddate = new Date(enddate.getFullYear() + '-' + month.toString().padStart(2,'0') + '-' + enddate.getDate().toString().padStart(2,'0'));
        
        let params = null;
        if(showAll){
            params = {
                startdate: startdate.toISOString(),
                //enddate: enddate.toISOString(),
                service: globalState.shiftService,
                //channel: globalState.shiftChannel,
                type: globalState.shiftType,
                locations: globalState.shiftLocations,
                own: globalState.ownShiftsOnly ? 1 : 0,
                sort: 'starttime',
                order: 'asc'
            }
        } else {
            params = {
                startdate: startdate.toISOString(),
                enddate: enddate.toISOString(),
                service: globalState.shiftService,
                //channel: globalState.shiftChannel,
                type: globalState.shiftType,
                locations: globalState.shiftLocations,
                own: globalState.ownShiftsOnly ? 1 : 0,
                sort: 'starttime',
                order: 'asc'
            }
        }

        // send the actual request
        await searchShiftTypes(params);

        // once the request is sent, update state again
        setIsSending(false)
        setRefresh(!refresh)
    }
    
    const setMonth = (value) => {
        globalState.setShiftMonth(value);
        const d = new Date(globalState.shiftYear + '-' + value + '-01');
        globalState.setShiftStartDate(new Date(d))
    }

    const setYear = (value) => {
        globalState.setShiftYear(value);
        const d = new Date(value + '-' + globalState.shiftMonth + '-01');
        globalState.setShiftStartDate(new Date(d))
    }

    const openDetail = (ev,row,item) => {
        //console.log(row)
        setSelectedShift(row)
        setDetailOpen(true)
        setJoinRequestConfirm('')
    }

    const openConfirm = (ev,row,item) => {
        console.log(row)
        // find out which confirm modal to open
        setSelectedShift(row)
        // date is in the past
        if(new Date(row.EndTime).getTime() < new Date().getTime()) {
            setShiftInPastModalOpen(true)
        } else if(row.ShiftUsers && row.ShiftUsers.find(item => item.UserId === profile.Id) !== undefined) {
            // user has registered
            setUnregistrationConfirmOpen(true)
        } else if(row.ShiftUserCountLeft===0){
            // shift is full
            setJoinRequestConfirmOpen(true)
        } else {
            setRegistrationConfirmOpen(true)
        }
    }

    const register = async (shift) => {
        if(shift){
            messages.map(msg => removeMessage(msg.id));

            //create shift user data
            let data = {
                userId: profile.Id,
                enrollmentDate: new Date().toISOString(),
                shiftTypeId: parseInt(shift.ShiftTypeId),
                sendShiftReminder: sendReminder
            }

            //save shift
            let result = null;
            result = await createShiftUser(shift.ShiftId,data);

            //console.log(result)

            if(result.data.status===400 && result.data.code===-2){
                addMessage({type: "error", header: t('message.alreadyRegistered'), body: t('message.removeRegistrationFirst')})
                window.scrollTo(0, 0)
            } else if(result.status===201){
                //search shifts again to re-render
                setIsSending(true)
                sendRequest()
            }
        }
    }

    const unregister = async (shift) => {
        if(shift){
            console.log(shift)
            messages.map(msg => removeMessage(msg.id));

            const shiftUserId = shift.ShiftUsers.find(item => item.UserId === profile.Id).Id;

            //delete shift user
            let result = null;
            result = await deleteShiftUser(shift.ShiftId,shiftUserId);
            
            //search shifts again to re-render
            if(result.status===204){
                setIsSending(true)
                sendRequest()

                // send cancellation info to instructor
                const instructor = users.find(item => item.Id === shift.Shift[0].InstructorId);
                const typeName = types.find(item => item.Id === shift.TypeId).Name;
                let body = t('email.registrationCancelledBody')
                body = body.replace('$firstname$',profile.FirstName)
                body = body.replace('$lastname$',profile.LastName)
                body = body.replace('$type$',typeName)
                body = body.replace('$date$',new Date(shift.Date).toLocaleDateString())
                body = body.replace('$time$',shift.Time)
                let data = [{
                    type: "email",
                    to: instructor.EmailAddress || null,
                    cc: null,
                    bcc: null,
                    subject: t('email.registrationCancelledSubject'),
                    body: body,
                    replyTo: profile.EmailAddress
                }]

                console.log(data)
                
                result = await createSendQueueEmail(data,false);
            }
        }
    }

    const handleJoinRequestConfirm = async () => {
        // send request to join shift
        const instructor = users.find(item => item.Id === selectedShift.Shift[0].InstructorId);
        const typeName = types.find(item => item.Id === selectedShift.TypeId).Name;
        let body = t('email.requestToJoinBody')
        body = body.replace('$firstname$',profile.FirstName)
        body = body.replace('$lastname$',profile.LastName)
        body = body.replace('$type$',typeName)
        body = body.replace('$date$',new Date(selectedShift.Date).toLocaleDateString())
        body = body.replace('$time$',selectedShift.Time)
        let data = [{
            type: "email",
            to: instructor.EmailAddress || null,
            cc: null,
            bcc: null,
            subject: t('email.requestToJoinSubject'),
            body: body,
            replyTo: profile.EmailAddress
        }]
        
       let result = null;
        result = await createSendQueueEmail(data);

        if(result.status === 201){
            setJoinRequestConfirm('sent')
            if(!detailOpen){
                window.scrollTo(0, 0)
            }
        }
    }

    const shiftDetail = (shift) => {
        //console.log(shift)
        let date = null;
        let time = null;
        if(shift.StartTime){
            date = new Date(shift.StartTime)
            time = date.getHours() + '.' + date.getMinutes().toString().padStart(2,'0')
            date = new Date(shift.EndTime)
            time += '-' + date.getHours() + '.' + date.getMinutes().toString().padStart(2,'0')
        }

        if(shift.ShiftUsers){
            shift.ShiftUsers.map(item => {
                const user = users.find(usr => usr.Id === item.UserId);
                item.Name = user.FirstName + ' ' + user.LastName.substring(0,1) + '.';
                return item;
            })
        }

        return (
            <>
            <h4>{t('shift.timing')}</h4>
            <div>{shift.Date && new Date(shift.Date).toLocaleDateString()} {time ? t('general.at') + ' ' + time : ''}</div>

            <h4>{t('shift.type')}</h4>
            <div>{shift.typeName}</div>

            <h4>{t('shift.location')}</h4>
            <div>{shift.LocationName}</div>

            <h4>{t('shift.supervisor')}</h4>
            <div>{shift.InstructorName} ({t('user.phone-abbreviation')} {shift.InstructorPhone})</div>

            {shift.Description &&
                <>
                <h4>{t('shift.description')}</h4>
                <div>{shift.Description}</div>
                </>
            }

            <h4>{t('shift.onCallPositions')}</h4>
            <div>{shift.ShiftUserCountLeft}/{shift.ShiftUserCount}</div>

            
            {joinRequestConfirm==='confirm' ?
                <>
                <h3>{t('shift.shiftIsFull')}</h3>
                <h4>{t('shift.sendRequestToJoinConfirm')}</h4>
                <Button onClick={() => setJoinRequestConfirm('')}>{t('general.cancel')}</Button>
                <Button onClick={() => handleJoinRequestConfirm()}>{t('general.send')}</Button>
                </>
            :
                <>
                {joinRequestConfirm==='sent' ?
                    <>
                    <h3>{t('shift.shiftIsFull')}</h3>
                    <p>{t('info.joinRequestSent')}</p>
                    </>
                :
                    <>
                    {shift.ShiftUserCountLeft===0 &&
                        <>
                        <h3>{t('shift.shiftIsFull')}</h3>
                        <Button onClick={() => setJoinRequestConfirm('confirm')}>{t('shift.sendRequestToJoin')}</Button>
                        </>
                    }
                    </>
                }
                </>
            }

            <h4>{t('heading.personsOnCall')}</h4>
            {shift.ShiftUsers ?
                shift.ShiftUsers.map(item => (
                    <div key={item.Id}>{item.Name}</div>
                )) 
            : 
                <div>{t('warnings.personsOnCallNotFound')}</div>
            }
            </>
        )
    
    }

    return (

        <Form>

            <Modal
                closeIcon
                open={detailOpen}
                onClose={() => setDetailOpen(false)}
                onOpen={() => setDetailOpen(true)}
            >
                <Header icon='info' content={t('heading.shiftDetail')} />
                <Modal.Content>
                    {/*<ShiftDetail shift={selectedShift}/>*/}
                    {selectedShift && shiftDetail(selectedShift)}
                </Modal.Content>
                
                <Modal.Actions>
                    <CloseButton onClick={() => {
                        setDetailOpen(false);
                    }} />
                </Modal.Actions>

            </Modal>

            <Modal
                closeIcon
                open={registrationConfirmOpen}
                onClose={() => setRegistrationConfirmOpen(false)}
                onOpen={() => setRegistrationConfirmOpen(true)}
            >
                <Header icon='info' content={t('heading.confirmRegistration')} />
                <Modal.Content>
                    <p>{t('shift.confirmRegistration')}</p>
                    <Form.Checkbox
                        label={t('shift.sendReminderToMe')}
                        checked={sendReminder}
                        onClick={() => setSendReminder(!sendReminder)}
                    />
                </Modal.Content>
                
                <Modal.Actions>
                    <NoButton title={t('general.cancel')} onClick={() => {
                        setRegistrationConfirmOpen(false);
                    }} />
                    <YesButton title={t('shift.register')} onClick={() => {
                        register(selectedShift)
                        setRegistrationConfirmOpen(false);
                    }} />
                </Modal.Actions>
            </Modal>

            <Modal
                closeIcon
                open={unregistrationConfirmOpen}
                onClose={() => setUnregistrationConfirmOpen(false)}
                onOpen={() => setUnregistrationConfirmOpen(true)}
            >
                <Header icon='info' content={t('heading.confirmUnregistration')} />
                <Modal.Content>
                    <p>{t('shift.confirmUnregistration')}</p>
                </Modal.Content>
                
                <Modal.Actions>
                    <NoButton onClick={() => {
                        setUnregistrationConfirmOpen(false);
                    }} />
                    <YesButton onClick={() => {
                        unregister(selectedShift)
                        setUnregistrationConfirmOpen(false);
                    }} />
                </Modal.Actions>
            </Modal>

            <Modal
                closeIcon
                open={joinRequestConfirmOpen}
                onClose={() => setJoinRequestConfirmOpen(false)}
                onOpen={() => setJoinRequestConfirmOpen(true)}
            >
                <Header icon='info' content={t('shift.sendRequestToJoin')} />
                <Modal.Content>
                    <p>{t('shift.sendRequestToJoinConfirmModal')}</p>
                </Modal.Content>
                
                <Modal.Actions>
                    <NoButton title={t('general.cancel')} onClick={() => {
                        setJoinRequestConfirmOpen(false);
                    }} />
                    <YesButton title={t('general.send')} onClick={() => {
                        handleJoinRequestConfirm(selectedShift)
                        setJoinRequestConfirmOpen(false);
                    }} />
                </Modal.Actions>
            </Modal>

            <Modal
                closeIcon
                open={shiftInPastModalOpen}
                onClose={() => setShiftInPastModalOpen(false)}
                onOpen={() => setShiftInPastModalOpen(true)}
            >
                <Header icon='info' content={t('modal.shiftInPast')} />
                <Modal.Content>
                    {t('modal.noRegistrationToShiftsInPast')}
                </Modal.Content>
                
                <Modal.Actions>
                    <CloseButton onClick={() => {
                        setShiftInPastModalOpen(false);
                    }} />
                </Modal.Actions>

            </Modal>

            <div className='shift-search'>
            <Grid stackable columns={2}>
                    <Grid.Column width={10}>
                        <Form.Group widths='equal'>
                            <Form.Select
                                value={globalState.shiftMonth}
                                onChange={(e,{value}) => setMonth(value) }
                                label={t('shift.month')}
                                options={months}
                            />
                            <Form.Select
                                value={globalState.shiftYear} 
                                onChange={(e,{value}) => setYear(value)}
                                label={t('shift.year')}
                                options={years}
                            />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Select
                                value={globalState.shiftService}
                                onChange={(e,{value}) => globalState.setShiftService(value)}
                                label={t('shift.service')}
                                options={serviceOptions}
                                placeholder={t('shift.service-options.all')}
                            />
                            <Form.Select
                                value={globalState.shiftType}
                                onChange={(e,{value}) => globalState.setShiftType(value)}
                                label={t('shift.type')}
                                options={typeOptions}
                                placeholder={t('shift.type-options.all')}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Field
                                control={Checkbox}
                                checked={globalState.ownShiftsOnly}
                                onChange={(e) => globalState.setOwnShiftsOnly(!globalState.ownShiftsOnly)}
                                label={t('shift.showOwnShiftsOnly')}
                            />
                        </Form.Group>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Form.Group grouped>
                            <label>{t('shift.location')}</label>
                            {locations.map(item =>
                                <Form.Field
                                    key={item.Id}
                                    control={Checkbox}
                                    label={item.Name}
                                    value={item.Id}
                                    checked={globalState.shiftLocations.includes(item.Id)}
                                    onChange={(e,{value}) => setLocations(value)}
                                />
                            )}
                        </Form.Group>
                        <Button disabled={isSending} onClick={() => sendRequest()}>{t('general.search')}</Button>
                    </Grid.Column>
                </Grid>
            </div>
                
            <Divider />

            {showAllUpcomingEvents ?
                <h1>{t('shift.upcomingShifts')}</h1>
            :
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={5}><h1>{t('shift.shiftsForWeek')}</h1></Grid.Column>
                        <Grid.Column width={11}>
                            {isSending ?
                                <>
                                <Button floated='right' disabled>{t('shift.nextWeek')}</Button>
                                <Button floated='right' disabled>{t('shift.previousWeek')}</Button>
                                </>
                            :
                                <>
                                <Button floated='right' onClick={() => sendRequest(7)}>{t('shift.nextWeek')}</Button>
                                <Button floated='right' onClick={() => sendRequest(-7)}>{t('shift.previousWeek')}</Button>
                                </>
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
            
            {weekdays.length === 0 ?
                <div>{t('warnings.shiftsNotFound')}</div>
            :
                <>
                {weekdays.map((wd,index) =>
                    <>
                    {shiftTypes.filter(item => new Date(item.Date).getDay() === wd.num).length === 0 ?
                        <div key={index} className='shiftlist'>
                            <h2>{t('general.weekdays.' + wd.num) + ' ' + wd.date}</h2>
                            <div>{t('warnings.shiftsNotFound')}</div>
                        </div>
                    :
                        <div key={index} className='shiftlist'>
                            <h2>{t('general.weekdays.' + wd.num) + ' ' + wd.date}</h2>
                            <div>
                                <MediaContextProvider>
                                    <Media greaterThan='sm'>
                                        <PagedTable unstackable striped celled compact='very'
                                            header={[
                                                t('shift.time'),
                                                t('shift.type'),
                                                '',
                                                t('shift.location'),
                                                t('shift.supervisor'),
                                                t('heading.personsOnCall'),
                                                ''
                                            ]}
                                            content={shiftTypes.filter(item => new Date(item.Date).toLocaleDateString() === wd.date)}
                                            contentKeys={[
                                                {key: 'Time', cellWidth: '2'},
                                                {key: 'typeName', type: 'action', action: openDetail, targetKey: 'Id', cellWidth: '6'},
                                                {key: 'iconName', key2: 'serviceColor', key3: 'channelColor', type: 'icons'},
                                                {key: 'LocationName', cellWidth: '3'},
                                                {key: 'InstructorName', cellWidth: '3'},
                                                {key: 'ShiftUsersRegistered'},
                                                {key: 'actionLink', key2: 'actionText', type: 'keyWithActionButton', action: openConfirm, targetKey: 'Id', cellWidth: '2', disabled: 'InPast'}
                                            ]}
                                            pageSize='20'
                                            
                                        />
                                    </Media>
                                    <Media lessThan='md'>
                                        {shiftTypes.filter(item => new Date(item.Date).toLocaleDateString() === wd.date).map(shift =>
                                            <>
                                            <div className="shift-item">
                                                {shift.InPast ?
                                                    <Button className='fixed-width' floated='right' disabled>{shift.actionLink}</Button>
                                                :
                                                    <Button className='fixed-width' floated='right' onClick={ (ev) => { ev.preventDefault(); openConfirm(ev, shift, null); } } >{shift.actionLink}</Button>
                                                }
                                                <div classname="shift-time"><Icon name='clock outline' color='grey' /> {shift.Time} {shift.LocationName}</div>
                                                <div className="shift-channel">
                                                    <Icon name={shift.iconName} color={shift.serviceColor} />
                                                    {shift.channelColor && <Icon name={shift.iconName} color={shift.channelColor} /> }
                                                    <Link onClick={(ev) => { ev.preventDefault(); openDetail(ev,shift,null)}}>{shift.typeName}</Link>
                                                </div>
                                                <div classname="shift-supervisor"><Icon name='user outline' color='grey' /> {shift.InstructorName}</div>
                                                <div classname="shift-persons"><Icon name='group' color='grey' /> {t('heading.personsOnCall')} {shift.ShiftUsersRegistered} {shift.actionText}</div>

                                            </div>
                                            {/*<div className="shift-item">
                                                <div classname="shift-time"><Icon name='clock outline' /> {shift.Time} {shift.LocationName}</div>
                                                <div className="shift-channel">
                                                    {shift.typeName} <Icon name={shift.iconName} color={shift.serviceColor} />
                                                    {shift.channelColor && <Icon name={shift.iconName} color={shift.channelColor} />}
                                                </div>
                                                <div classname="shift-supervisor">{t('shift.supervisor')} {shift.InstructorName}</div>
                                                <div classname="shift-persons"><Icon name='group' /> {shift.ShiftUsersRegistered} {shift.actionText}</div>
                                                {shift.InPast ?
                                                    <Button disabled>{shift.actionLink}</Button>
                                                :
                                                    <Button onClick={ (ev) => { ev.preventDefault(); openConfirm(ev, shift, null); } } >{shift.actionLink}</Button>
                                                }
                                            </div>
                                            <div className="shift-item">
                                                <div><Icon name={shift.iconName} color={shift.serviceColor} /><Icon name={shift.iconName} color={shift.channelColor} /></div>
                                                <div classname="shift-time"><Icon name='clock outline' /> {shift.Time} {shift.typeName}</div>
                                                <div className="shift-location"><Icon name='map marker alternate' /> {shift.LocationName}</div>
                                                <div classname="shift-supervisor">{t('shift.supervisor')} {shift.InstructorName}</div>
                                                <div classname="shift-persons"><Icon name='group' /> {shift.ShiftUsersRegistered}</div>
                                            </div>*/}
                                            </>
                                        )}
                                    </Media>
                                </MediaContextProvider>
                            </div>
                        </div> 
                    }
                    </>
                )}
                </>
            }
            {!showAllUpcomingEvents &&
                <div>
                    {isSending ?
                        <>
                        <Button floated='right' disabled>{t('shift.nextWeek')}</Button>
                        <Button floated='right' disabled>{t('shift.previousWeek')}</Button>
                        </>
                    :
                        <>
                        <Button floated='right' onClick={() => sendRequest(7)}>{t('shift.nextWeek')}</Button>
                        <Button floated='right' onClick={() => sendRequest(-7)}>{t('shift.previousWeek')}</Button>
                        </>
                    }
                </div>
            }
            
        </Form>
    )
}

export default ShiftList;
import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PagedTable from '../../Reusable/Lists/PagedTable';
import { Divider, Button, Grid } from 'semantic-ui-react';
import useListReminders from '../../Hooks/Users/Reminder/useListReminders';
import { useParams } from 'react-router-dom';
import SubNavigation from '../../Pages/User/SubNavigation';
import { StateContext } from '../../Context/StateContext';

const ReminderList = (props) => {

    const { id } = useParams();
    const { t } = useTranslation();
    const { reminders, listReminders } = useListReminders();
    const { userDetailBackRoute } = useContext(StateContext);

    // Fetch one to ones
    useEffect(() => {
        (async () => {
            await listReminders(id);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // add detail link to one to one array
    useEffect(() => {
        reminders.map(item => {
            item.viewUrl = 'detail/';
            item.link=t('general.view');
            return item;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[reminders]);
    
    return (
        <div>
            <SubNavigation id={id} activePage='reminders' />

            <Grid stackable columns={2} className="add-margin">
                <Grid.Column><h1>{t('heading.reminders')}</h1></Grid.Column>
                <Grid.Column>
                    <Button floated='right' onClick={() => props.history.push('/user/' + id + '/reminder/new')}>{t('reminder.add')}</Button>
                </Grid.Column>
            </Grid>
                
            <PagedTable unstackable striped celled compact='very'
                header={[
                    t('reminder.date'),
                    t('reminder.subject'),
                    t('reminder.description'),
                    ''
                ]}
                content={reminders}
                contentKeys={[
                    {key: 'Date', type: 'date'},
                    {key: 'Subject'},
                    {key: 'Body', type: 'firstChars', limit: 100},
                    {key: 'link', type: 'url', urlKey:'viewUrl', cellAlign: 'left', targetKey: 'Id'},
                ]}
                pageSize='20'
            />

            <Divider />
            <Button onClick={() => props.history.push(userDetailBackRoute || '/user/list')}>{t('general.backToUserList')}</Button>
                
        </div>
    );

}

export default ReminderList;

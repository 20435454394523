import React, { useContext, useState } from 'react';
import SubNavigation from '../../Layout/SubNavigation';
import { Grid, Segment, Container, Divider, Form, Button, Checkbox, TextArea } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { StateContext } from '../../Context/StateContext';
import { MessageContext } from '../../Context/MessageContext';
import Question from '../../Reusable/Question';
import { YesNoModal } from '../../Reusable/Modals/GeneralModal';

function BackgroundInformation(props) {

  const { t } = useTranslation();
  const [ saveModalOpen, setSaveModalOpen ] = useState(false);
  const { removeMessage, messages } = useContext(MessageContext);
  const { globalState, contactDataOK, saveContactData } = useContext(StateContext);
  
  let questions = []
  if(globalState.service.type === 'vpn') questions = globalState.settings.find(item => item.type === 'vpn') || [];
  if(globalState.service.type === 'lnpn') questions = globalState.settings.find(item => item.type === 'lnpn') || [];

  const setFamilyChildAgeState = (obj) => {
    let newObj = {};
    if(globalState.familyChildAgeState.find(item => item.ref === obj.ref)){
      // uncheck -> remove item from array
      newObj = globalState.familyChildAgeState.filter(item => item.ref !== obj.ref)
    } else {
      //check -> add item to array
      newObj = globalState.familyChildAgeState.concat(obj)
    }
    globalState.setFamilyChildAgeState(newObj)
  }

  const setContactorRelationToFamilyFreetext = (e) => {
    const newObj = JSON.parse(JSON.stringify(globalState.contactorRelationToFamily));
    newObj.description=e.target.value;
    globalState.setContactorRelationToFamily(newObj)
  }

  const setFamilyFormFreetext = (e) => {
    const newObj = JSON.parse(JSON.stringify(globalState.familyForm));
    newObj.description=e.target.value;
    globalState.setFamilyForm(newObj);
  }

  const saveContact = async () => {
    //remove old messages
    messages.map(item => removeMessage(item.id));
    saveContactData(props.history);
  }

  //console.log(globalState);

  return (
    <div>
      <YesNoModal 
          type='save'
          header={t('modal.confirmSaveContact')}
          text={t('modal.saveNoDialogueContact')}
          method={saveContact}
          open={saveModalOpen}
          setOpen={setSaveModalOpen}
      />
      <SubNavigation activePage='backgroundinformation' />
      <Form>
        <Grid stackable columns={2}>

          {/* VPN */}
          { globalState.service.type === 'vpn' &&
            <>
            <Grid.Column>
              <Segment>
                <h1>{t('contact.contactorInformation')}</h1>
                <Divider/>
                <Question
                  data={questions.contactorAge || []} 
                  selectedValue={globalState.contactorAge.ref} 
                  setSelectedValue={val => {
                    const x = questions.contactorAge.options.find(s => s.ref === val)
                    globalState.setContactorAge(x)
                  }}
                />
                <Question
                  data={questions.contactorGender || []} 
                  selectedValue={globalState.contactorGender.ref} 
                  setSelectedValue={val => {
                    const x = questions.contactorGender.options.find(s => s.ref === val)
                    globalState.setContactorGender(x)
                  }}
                />
                <Question
                  data={questions.contactorRelationToFamily || []} 
                  selectedValue={globalState.contactorRelationToFamily.ref} 
                  setSelectedValue={val => {
                    const x = questions.contactorRelationToFamily.options.find(s => s.ref === val)
                    globalState.setContactorRelationToFamily(x)
                  }}
                  freeTextValue={globalState.contactorRelationToFamily.description}
                  setFreeText={setContactorRelationToFamilyFreetext}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment>
                <h1>{t('contact.familyInformation')}</h1>
                <Divider/>
                <Question
                  data={questions.familyChildAgeState || []} 
                  selectedValue={globalState.familyChildAgeState} 
                  setSelectedValue={val => {
                    const x = questions.familyChildAgeState.options.find(s => s.ref === val)
                    setFamilyChildAgeState(x)
                  }}
                />
                <Question
                  data={questions.familyForm || []} 
                  selectedValue={globalState.familyForm.ref} 
                  setSelectedValue={val => {
                    const x = questions.familyForm.options.find(s => s.ref === val)
                    globalState.setFamilyForm(x)
                  }}
                  freeTextValue={globalState.familyForm.description}
                  setFreeText={setFamilyFormFreetext}
                />
                <Question
                  data={questions.familySocialNetwork || []} 
                  selectedValue={globalState.familySocialNetwork.ref} 
                  setSelectedValue={val => {
                    const x = questions.familySocialNetwork.options.find(s => s.ref === val)
                    globalState.setFamilySocialNetwork(x)
                  }}
                />
              </Segment>
            </Grid.Column>
            </>
          }

          {/* LNPN */}
          { globalState.service.type === 'lnpn' &&
            <>
            <Grid.Column>
              <Segment>
                <Question
                  data={questions.contactorAge || []} 
                  selectedValue={globalState.contactorAge.ref} 
                  setSelectedValue={val => {
                    const x = questions.contactorAge.options.find(s => s.ref === val)
                    globalState.setContactorAge(x)
                  }}
                />
                <Question
                  data={questions.contactorCount || []} 
                  selectedValue={globalState.contactorCount.ref} 
                  setSelectedValue={val => {
                    const x = questions.contactorCount.options.find(s => s.ref === val)
                    globalState.setContactorCount(x)
                  }}
                />
                <Question
                  data={questions.contactorGender || []} 
                  selectedValue={globalState.contactorGender.ref} 
                  setSelectedValue={val => {
                    const x = questions.contactorGender.options.find(s => s.ref === val)
                    globalState.setContactorGender(x)
                  }}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment>
                {(globalState.channel.ref === 'phone' || globalState.channel.ref === 'chat') &&
                <Question
                  data={questions.previousContact || []} 
                  selectedValue={globalState.previousContact.ref} 
                  setSelectedValue={val => {
                    const x = questions.previousContact.options.find(s => s.ref === val)
                    globalState.setPreviousContact(x)
                  }}
                />
                }
                {globalState.service.language === 'sv' &&
                  <Question
                    data={questions.contactorLanguage || []} 
                    selectedValue={globalState.contactorLanguage.ref} 
                    setSelectedValue={val => {
                      let x = {ref: '', name: { fi: '' }};
                      if(!globalState.contactorLanguage || globalState.contactorLanguage.ref===''){
                        x = {ref: val, name: { fi: questions.contactorLanguage.name.fi }}
                      } 
                      globalState.setContactorLanguage(x)
                    }}
                  />
                }
                <div className='noDialogueContainer'>
                  <Divider />
                  <Form.Field>
                    <Checkbox
                      label={t('contact.noDialogueInContact')}
                      checked={!globalState.dialogue}
                      onClick={() => globalState.setDialogue(!globalState.dialogue)}
                    />
                  </Form.Field>
                  {!globalState.dialogue &&
                    <>
                    <h1>{t('contact.describeNoDialogueInContact')}</h1>
                    <Form.Field
                      control={TextArea}
                      value={globalState.dialogueDescription}
                      onChange={e => globalState.setDialogueDescription(e.target.value)}
                    />
                    </>
                  }
                </div>
              </Segment>
            </Grid.Column>
            </>
          }

        </Grid>

        <Container>
            <Divider/>
            {!globalState.dialogue ?
              <>
              {contactDataOK().result ?
                <Button floated='right' onClick={() => setSaveModalOpen(true)}>{t('general.savecontact')}</Button>
              :
                <Button floated='right' onClick={() => props.history.push(contactDataOK().route)}>{t('general.returnToMissingInformation')}</Button>
              }
              </>
            :
              <>
              {contactDataOK().result ?
                <Button floated='right' onClick={() => saveContactData(props.history)}>{t('general.savecontact')}</Button>
              :
                <Button floated='right' onClick={() => props.history.push('/contact/previoussupport')}>{t('general.nextstep')}</Button>
              }
              </>
            }
        </Container>
      </Form>
    </div>
  );
}

export default BackgroundInformation;

import React, { useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, Container, Dropdown } from 'semantic-ui-react';
import { createMedia } from "@artsy/fresnel";
import { useTranslation } from 'react-i18next';
import useReadUser from '../../Hooks/Users/useReadUser';

const SubNavigation = ({id, activePage}) => {

  const { t } = useTranslation();
  const { user, readUser } = useReadUser();

  const { MediaContextProvider, Media } = createMedia({
      breakpoints: {
          sm: 0,
          md: 768,
          lg: 1024,
          xl: 1192,
      }
  });

  useEffect(() => {
    (async() => {
        await readUser(id);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let selectedPageTitle = ''
  if(activePage === 'detail'){
    selectedPageTitle=t('user.detail')
  } else if (activePage === 'activity'){
    selectedPageTitle=t('user.activity')
  } else if (activePage === 'onetoones'){
    selectedPageTitle=t('user.oneToOnes')
  } else if (activePage === 'badges'){
    selectedPageTitle=t('user.badgesOfMerit')
  } else if (activePage === 'participations'){
    selectedPageTitle=t('user.otherParticipations')
  } else if (activePage === 'reminders'){
    selectedPageTitle=t('user.reminders')
  }

  return (
    
    <div className="subnavigation">

      <Container>
        <h1>{user && user.data && user.data.IsEmployee ? t('heading.employeeinfo') : t('heading.userinfo')}{user && user.data && ': ' + user.data.FirstName + ' ' + user.data.LastName}</h1>
        <MediaContextProvider>
          <Media greaterThan='md'>
            <Menu pointing secondary>
                <Menu.Item active={activePage === 'detail'}>
                  <NavLink to={'/user/detail/' + id}>{t('user.detail')}</NavLink>
                </Menu.Item>
                <Menu.Item active={activePage === 'activity'}>
                  <NavLink to={'/user/' + id + '/activity'}>{t('user.activity')}</NavLink>
                </Menu.Item>
                <Menu.Item active={activePage === 'onetoones'}>
                  <NavLink to={'/user/' + id + '/onetoone/list'}>{t('user.oneToOnes')}</NavLink>
                </Menu.Item>
                <Menu.Item active={activePage === 'badges'}>
                  <NavLink to={'/user/' + id + '/badgeofmerit/list'}>{t('user.badgesOfMerit')}</NavLink>
                </Menu.Item>
                <Menu.Item active={activePage === 'participations'}>
                  <NavLink to={'/user/' + id + '/participation/list'}>{t('user.otherParticipations')}</NavLink>
                </Menu.Item>
                <Menu.Item active={activePage === 'reminders'}>
                  <NavLink to={'/user/' + id + '/reminder/list'}>{t('user.reminders')}</NavLink>
                </Menu.Item>
            </Menu>
          </Media>
          <Media lessThan='lg'>
            <Menu pointing secondary>
              <Dropdown text={selectedPageTitle}>
                <Dropdown.Menu>
                  <Dropdown.Item active={activePage === 'detail'}>
                    <NavLink to={'/user/detail/' + id}>{t('user.detail')}</NavLink>
                  </Dropdown.Item>
                  <Dropdown.Item active={activePage === 'activity'}>
                    <NavLink to={'/user/' + id + '/activity'}>{t('user.activity')}</NavLink>
                  </Dropdown.Item>
                  <Dropdown.Item active={activePage === 'onetoones'}>
                    <NavLink to={'/user/' + id + '/onetoone/list'}>{t('user.oneToOnes')}</NavLink>
                  </Dropdown.Item>
                  <Dropdown.Item active={activePage === 'badges'}>
                    <NavLink to={'/user/' + id + '/badgeofmerit/list'}>{t('user.badgesOfMerit')}</NavLink>
                  </Dropdown.Item>
                  <Dropdown.Item active={activePage === 'participations'}>
                    <NavLink to={'/user/' + id + '/participation/list'}>{t('user.otherParticipations')}</NavLink>
                  </Dropdown.Item>
                  <Dropdown.Item active={activePage === 'reminders'}>
                    <NavLink to={'/user/' + id + '/reminder/list'}>{t('user.reminders')}</NavLink>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu>
          </Media>
        
        </MediaContextProvider>
      
      </Container>
    </div>
  );
}


export default SubNavigation;

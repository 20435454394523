import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageContext } from '../../../Context/MessageContext';
import Axios from 'axios';
import Config from '../../../Config/Config';
import { useMsal } from "@azure/msal-react";

const useListOtherParticipations = () => {

    const rootPath = Config.api.rootPath;
    const { instance } = useMsal();
    const { t } = useTranslation();
    const { addMessage, removeMessage } = useContext(MessageContext);
    const [ otherParticipations, setOtherParticipations ] = useState([]);
    const [ fetchingOtherParticipations, setFetchingOtherParticipations ] = useState(true);

    /** list contracts
    * @returns {array} - array of one to Ones */ 
    const listOtherParticipations = async (userID) => {
        try {

            const msgId = addMessage({ type: "loading", header: t('general.moment'), body: t('info.fetchingOtherParticipations')});
            
            const activeAccount = instance.getActiveAccount();
            const accounts = instance.getAllAccounts();

            if (!activeAccount && accounts.length === 0) return;

            const request = {
                scopes: Config.api.scopes,
                account: activeAccount || accounts[0]
            }

            const authResult = await instance.acquireTokenSilent(request);

            const options = {
                "headers": {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + authResult.accessToken
                }
            }

            let result = null;

            result = await Axios.get(rootPath + "/api/v1/users/" + userID + "/participations", options);

            removeMessage(msgId);

            if (result && result.status === 200) {
                setOtherParticipations(result.data);
                setFetchingOtherParticipations(false);
                return result;
            }

            if (result && result.status === 404) {
                addMessage({ type: "warning", header: t('general.error'), body: t('warnings.otherParticipationsNotFound') });
                setFetchingOtherParticipations(false);
                return result;
            }

            addMessage({ type: "error", header: t('general.error'), body: t('errors.fetchingOtherParticipationsFailed') });
            setFetchingOtherParticipations(false);
            return result;

        } catch (error) {
            if (Config.environment === 'development' || Config.environment === 'test') console.log(error);
            addMessage({ type: "error", header: t('general.error'), body: t('errors.fetchingOtherParticipationsFailed') });
            setFetchingOtherParticipations(false);
            return error;
        }
    }

    return { otherParticipations, fetchingOtherParticipations, listOtherParticipations };

}

export default useListOtherParticipations;

import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Form,  Divider, Button } from 'semantic-ui-react';
import { MessageContext } from '../../Context/MessageContext';
import useListBadgeOfMerits from '../../Hooks/Settings/useListBadgeOfMerits';
import useCreateBadgeOfMerit from '../../Hooks/Users/BadgeOfMerit/useCreateBadgeOfMerit';
import { DateInput } from 'semantic-ui-calendar-react';
import { useParams } from 'react-router-dom';
import useReadUser from '../../Hooks/Users/useReadUser';

const CreateBadgeOfMerit = (props) => {

    const { t } = useTranslation();
    const { badgeOfMerits, listBadgeOfMerits } = useListBadgeOfMerits();
    const { createBadgeOfMerit } = useCreateBadgeOfMerit();
    const { addMessage, removeMessage, messages } = useContext(MessageContext);
    const { id } = useParams();
    const { user, readUser } = useReadUser();
    
    const [ date, setDate ] = useState(null);
    const [ selectedBadgeOfMerit, setSelectedBadgeOfMerit ] = useState(null);
    const [ description, setDescription ] = useState('');

    useEffect(() => {
        (async() => {
            await readUser(id);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async() => {
            await listBadgeOfMerits();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let badgeOfMeritOptions = []
    if (badgeOfMerits) {
        badgeOfMerits.map(badge => {
            const tmp = {
                key: badge.Id,
                text: badge.Name,
                value: badge.Id
            }
            badgeOfMeritOptions.push(tmp)
            return badge;
        })
    }

    const handleSave = async () => {
        messages.map(msg => removeMessage(msg.id));

        //validate
        if(!date || !selectedBadgeOfMerit) {
            addMessage({type: "error", header: t('message.fillInRequiredInformation'), body: t('message.requiredMarkedWithAsterisk')})
            window.scrollTo(0, 0)
        } else {
            // Format date
            let dateParts = []
            let dateISO = null;
            if(date) {
                dateParts=date.split('.');
                dateISO=new Date(dateParts[2] + "-" + dateParts[1].padStart(2,"0") + "-" + dateParts[0].padStart(2,"0")).toISOString();
            }

            //create data
            let data = {
                "date": dateISO,
                "badgeOfMerit": selectedBadgeOfMerit,
                "description": description
            }

            //save
            let result = null;
            result = await createBadgeOfMerit(id,data);

            if(result.status === 201) {
                props.history.goBack();
            }
        }
    }

    return (
        <div>
            <Form>
                <Container>
                    <h1>{t('heading.badgeOfMerit')}</h1>

                    <h2>{t('heading.user')}</h2>
                    <div>{user.data && user.data.FirstName} {user.data && user.data.LastName}</div>
                
                    <h2>{t('badgeOfMerit.date')}<sup><i className='icon asterisk'></i></sup></h2>
                    <DateInput
                        localization='fi'
                        dateFormat={'DD.MM.YYYY'}
                        value={date || ""}
                        iconPosition="right"
                        onChange={(ev, {value}) => setDate(value) }
                        closable={true}
                        popupPosition='bottom left'
                    />

                    <h2>{t('heading.badgeOfMerit')}<sup><i className='icon asterisk'></i></sup></h2>
                    <Form.Select
                        value={selectedBadgeOfMerit}
                        options={badgeOfMeritOptions}
                        onChange={(e, {value}) => setSelectedBadgeOfMerit(value)}
                    />
                
                    <h2>{t('badgeOfMerit.description')}</h2>
                    <Form.TextArea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </Container>
                <Divider/>
                <Button onClick={() => handleSave()}>{t('general.save')}</Button>
                <Button onClick={() => props.history.goBack()}>{t('general.cancel')}</Button>
            </Form>
        </div>
    )

}

export default CreateBadgeOfMerit;
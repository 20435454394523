import { useState, useContext } from 'react';
import Axios from 'axios';
import { useMsal } from "@azure/msal-react";
import Config from './../../Config/Config';
import { MessageContext } from '../../Context/MessageContext';
import { useTranslation } from 'react-i18next';

const useListAllSettings = () => {

    const [ settings, setSettings ] = useState([]);
    const rootPath = Config.api.rootPath;
    const { instance } = useMsal();
    const { addMessage, removeMessage, messages } = useContext(MessageContext);
    const { t } = useTranslation();

    /** List all settings */
    const listAllSettings = async () => {
        try {

            messages.map(msg => removeMessage(msg.id));
            const activeAccount = instance.getActiveAccount();
            const accounts = instance.getAllAccounts();

            if (!activeAccount && accounts.length === 0) return;

            const request = {
                scopes: Config.api.scopes,
                account: activeAccount || accounts[0]
            }

            const authResult = await instance.acquireTokenSilent(request);

            const options = {
                "headers": {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + authResult.accessToken
                }
            }

            const result = await Axios.get(rootPath + "/api/v1/settings", options);

            if (result && result.status && result.status === 200 && result.data) setSettings(result.data);

            return result;

        } catch (error) {

            const err = error && error.response ? error.response : error;
            const name = err.name || "";
            const message = err.message || "";
            const status = err.status || 500;
    

            if (name === "InteractionRequiredAuthError") {
                addMessage({ type: "error", header: 'InteractionRequiredAuthError', body: message.toString() });
                return error;
            }

            switch(status){
                case 401:
                    addMessage({ type: "error", header: t('errors.fetchingSettingsFailed'), body: t('errors.noAuthentication') });
                    break;
                case 403:
                    addMessage({ type: "error", header: t('errors.fetchingSettingsFailed'), body: t('errors.noPermission') });
                    break;
                case 500:
                    addMessage({ type: "error", header: t('errors.fetchingSettingsFailed'), body: t('errors.systemError') });
                    break;
                default:
                    addMessage({ type: "error", header: t('general.error'), body: t('errors.fetchingSettingsFailed') });
                    break;
            }

            if (Config.environment === 'development' || Config.environment === 'test') console.log(error);

            return error;

        }
    }

    return { settings, listAllSettings };

}

export default useListAllSettings;

import React, { useState, useContext, useEffect } from 'react';
import { Form, Checkbox, Divider, Grid, Button } from 'semantic-ui-react';
import PagedTable from '../../Reusable/Lists/PagedTable';
import { useTranslation } from 'react-i18next';
import { StateContext } from '../../Context/StateContext';
import useSearchShifts from '../../Hooks/Shifts/useSearchShifts';

const AddShifts = (props) => {

    const { t } = useTranslation();
    const { globalState, locations, services, channels, types } = useContext(StateContext);
    const { shifts, searchShifts } = useSearchShifts();
    const [ refresh, setRefresh ] = useState(false);

    let serviceOptions = [{key: 'all', text: t('shift.service-options.all'), value: ''},]
    services.map(s => {
        const option = {
            key: s.Id,
            text: s.Name,
            value: s.Id
        }
        serviceOptions.push(option);
        return s;
    })

    let channelOptions = [{key: 'all', text: t('shift.channel-options.all'), value: ''},]
    channels.map(c => {
        const option = {
            key: c.Id,
            text: t('shift.channel-options.' + c.Reference),
            value: c.Id
        }
        channelOptions.push(option);
        return c;
    })

    let typeOptions = [{key: 'all', text: t('shift.type-options.all'), value: ''},]
    types.map(i => {
        const option = {
            key: i.Id,
            text: i.Name,
            value: i.Id
        }
        typeOptions.push(option);
        return t;
    })

    const months = [
        { key: '01', text: t('general.january'), value: 1 },
        { key: '02', text: t('general.february'), value: 2 },
        { key: '03', text: t('general.march'), value: 3 },
        { key: '04', text: t('general.april'), value: 4 },
        { key: '05', text: t('general.may'), value: 5 },
        { key: '06', text: t('general.june'), value: 6 },
        { key: '07', text: t('general.july'), value: 7 },
        { key: '08', text: t('general.august'), value: 8 },
        { key: '09', text: t('general.september'), value: 9 },
        { key: '10', text: t('general.october'), value: 10 },
        { key: '11', text: t('general.november'), value: 11 },
        { key: '12', text: t('general.december'), value: 12 }
    ]

    /* show years from beginning to year after current year */
    let years = []
    for (let i = 2021; i < new Date().getFullYear()+2; i++) {
        years.push({key: i, text: i, value: i });
    }

    useEffect(() => {
        setIsSending(true)
        sendRequest()
    },[])

    // exclude shifts already selected, add links to remaining shifts
    useEffect(() => {
        shifts.map(shift => {
            //shift.viewUrl="/shift/detail/"
            //shift.link=t('general.view')
            shift.selectLink=t('general.select');
            shift.selected=false;
            shift.typeArray = shift.ShiftTypes ? shift.ShiftTypes.map(item => types.filter(type => type.Id === item.TypeId).map(type => type.Name)) : []
            shift.countArray = shift.ShiftTypes ? shift.ShiftTypes.map(item => (item.ShiftTypeUsers ? item.ShiftTypeUsers.length : '0') + '/' + item.ShiftUserCount) : []
            return shift;
       })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[shifts]);

    const setLocations = (value) => {
        let newLocations = [];
        if(globalState.shiftLocations.includes(value)){
            // uncheck -> remove item from array
            newLocations = globalState.shiftLocations.filter(item => item!==value)
        } else {
            //check -> add item to array
            newLocations = globalState.shiftLocations.concat(value)
        }
        globalState.setShiftLocations(newLocations)
    }

    const [isSending, setIsSending] = useState(false)
    const sendRequest = async () => {
        // don't send again while we are sending
        if (isSending) return
        // update state
        setIsSending(true)

        let startdate = new Date(globalState.shiftYear + '-' + globalState.shiftMonth + '-01')
        let enddate = new Date(globalState.shiftYear,globalState.shiftMonth,1).toLocaleDateString()
        const dateArray = enddate.split('.')
        let enddateISO = new Date(dateArray[2] + '-' + dateArray[1].padStart(2,'0') + '-' + dateArray[0].padStart(2,'0')).toISOString()

        let params = {
            startdate: startdate.toISOString(),
            enddate: enddateISO,
            service: globalState.shiftService,
            channel: globalState.shiftChannel,
            type: globalState.shiftType,
            location: globalState.shiftLocations
        }
        // send the actual request
        await searchShifts(params);
        // once the request is sent, update state again
        setIsSending(false)
    }

    const selectShift = (ev,row,item) => {
        shifts.find(item => item.Id === row.Id).selected=!shifts.find(item => item.Id === row.Id).selected
        shifts.find(item => item.Id === row.Id).selectLink=shifts.find(item => item.Id === row.Id).selected ? t('general.unselect') : t('general.select')
        setRefresh(!refresh);
    }

    const handleAddShifts = () => {
        const newArray = globalState.selectedShifts.concat(shifts.filter(item => item.selected))
        globalState.setSelectedShifts(newArray)
        props.history.goBack();
    }

    return (
        <div className="shiftlist">
            <Form>
                <h1>{t('heading.addShiftsToAlert')}</h1>
                
                <div className='shift-search'>
                    <Grid stackable columns={2}>
                        <Grid.Column width={10}>
                            <Form.Group widths='equal'>
                                <Form.Select
                                    value={globalState.shiftMonth}
                                    onChange={(e,{value}) => globalState.setShiftMonth(value)}
                                    label={t('shift.month')}
                                    options={months}
                                />
                                <Form.Select
                                    value={globalState.shiftYear} 
                                    onChange={(e,{value}) => globalState.setShiftYear(value)}
                                    label={t('shift.year')}
                                    options={years}
                                />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Select
                                    value={globalState.shiftService}
                                    onChange={(e,{value}) => globalState.setShiftService(value)}
                                    label={t('shift.service')}
                                    options={serviceOptions}
                                    placeholder={t('shift.service-options.all')}
                                />
                                <Form.Select
                                    value={globalState.shiftChannel}
                                    onChange={(e,{value}) => globalState.setShiftChannel(value)}
                                    label={t('shift.channel')}
                                    options={channelOptions}
                                    placeholder={t('shift.channel-options.all')}
                                />
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Select
                                    value={globalState.shiftType}
                                    onChange={(e,{value}) => globalState.setShiftType(value)}
                                    label={t('shift.type')}
                                    options={typeOptions}
                                    placeholder={t('shift.type-options.all')}
                                />
                            </Form.Group>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Form.Group grouped>
                                <label>{t('shift.location')}</label>
                                {locations.map(item =>
                                    <Form.Field
                                        key={item.Id}
                                        control={Checkbox}
                                        label={item.Name}
                                        value={item.Id}
                                        checked={globalState.shiftLocations.includes(item.Id)}
                                        onChange={(e,{value}) => setLocations(value)}
                                    />
                                )}
                            </Form.Group>
                            <Button disabled={isSending} onClick={sendRequest}>{t('general.search')}</Button>
                        </Grid.Column>
                    </Grid>
                </div>
                    
                <div className='shiftlist'>
                    <PagedTable unstackable striped celled compact='very'
                        header={[
                            '',
                            t('shift.date'),
                            t('shift.startTime'),
                            t('shift.endTime'),
                            t('shift.type'),
                            t('heading.personsOnCall'),
                            t('shift.location'),
                            t('shift.supervisor')
                            //,''
                        ]}
                        content={shifts.filter(shift => globalState.selectedShifts.find(item => item.Id === shift.Id) === undefined)}
                        contentKeys={[
                            {key: 'selectLink', type: 'action', action: selectShift},
                            {key: 'Date', type: 'date'},
                            {key: 'StartTime', type: 'time'},
                            {key: 'EndTime', type: 'time'},
                            {key: 'typeArray', type: 'array'},
                            {key: 'countArray', type: 'array'},
                            {key: 'LocationName'},
                            {key: 'InstructorName'}
                            //,{key: 'link', type: 'url', urlKey:'viewUrl', cellAlign: 'left', targetKey: 'Id'}
                        ]}
                        pageSize='20'
                        
                    />
                </div>

                {/*resetPage={name || activeOnly}*/}

                <Divider />
                <Button onClick={() => handleAddShifts()}>{t('alert.addSelectedShifts')}</Button>
                <Button onClick={() => props.history.goBack()}>{t('general.cancel')}</Button>
            </Form>
        </div>
    )
}

export default AddShifts;
import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './Layout/Header';
import Content from './Layout/Content';
import Footer from './Layout/Footer';
import Routes from './Layout/Routes';
import Messages from './Layout/Messages';
import MobileNavigation from './Layout/MobileNavigation';
import StateContextProvider from './Context/StateContext';
import MessageContextProvider from './Context/MessageContext';
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import LoadingComponent from './Layout/Loading';
import ErrorComponent from './Pages/Error';
import 'semantic-ui-css/semantic.min.css';
import './App.css';
import './Layout/styles.css';

function App(props) {

  const [ mobileMenuOpen, setMobileMenuOpen ] = useState(false);

  const loginRequestTokenScope = {
      scopes: ["user.read"]
  };

  return (
    <>
      <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={loginRequestTokenScope}
          errorComponent={ErrorComponent}
          loadingComponent={LoadingComponent}
      >
        <MessageContextProvider>
          <StateContextProvider>
            <Router>
              <div className="PageContainer">
                  <Header mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen}/>
                  <MobileNavigation mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen}>
                    <Content>
                      <Messages />
                      <Routes/>
                    </Content>
                  </MobileNavigation>
                  <Footer/>
              </div>
            </Router>
          </StateContextProvider>
        </MessageContextProvider>
      </MsalAuthenticationTemplate>
      </>
  );

}

export default App;

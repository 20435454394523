import { useContext } from 'react';
import Axios from 'axios';
import Config from './../../Config/Config';
import { useTranslation } from 'react-i18next';
import { MessageContext } from '../../Context/MessageContext';
import { useMsal } from "@azure/msal-react";

const useCreateStatistics = () => {

    const rootPath = Config.api.rootPath;
    const { instance } = useMsal();

    const { t } = useTranslation();
    const { addMessage, removeMessage, messages } = useContext(MessageContext);

    /** Create statistic
     * @param {string} -type - type of the statistics [ 'vpn' || 'lnpn' ] */
    const createStatistics = async (type, body) => {
        try {
            
            messages.map(msg => removeMessage(msg.id));

            const msgId = addMessage({ type: "saving", header: t('general.moment'), body: t('info.savingContact')});

            const activeAccount = instance.getActiveAccount();
            const accounts = instance.getAllAccounts();
            
            if (!activeAccount && accounts.length === 0) return;

            const request = {
                scopes: Config.api.scopes,
                account: activeAccount || accounts[0]
            }

            const authResult = await instance.acquireTokenSilent(request);

            const options = {
                "headers": {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + authResult.accessToken
                }
            }

            let result = null;

            if (type === 'vpn') result = await Axios.post(rootPath + "/api/v1/statistics/vpn", body, options);
            if (type === 'lnpn') result = await Axios.post(rootPath + "/api/v1/statistics/lnpn", body, options);           

            removeMessage(msgId);

            if (result && result.status === 201 && result.data) {
                addMessage({type: "success", header: t('message.contactSaved'), body: t('message.continueToNextContact')});
                return result;
            }

            addMessage({ type: "error", header: t('general.error'), body: t('errors.savingContactFailed') });
            return result;

        } catch (error) {
            if (Config.environment === 'development' || Config.environment === 'test') console.log(error);
            addMessage({ type: "error", header: t('general.error'), body: t('errors.savingContactFailed') });
            window.scrollTo(0,0);
            if(error && error.response){
                return error.response;
            }
            return error;
        }
    }

    return { createStatistics };

}

export default useCreateStatistics;
import React, {Fragment, useContext} from 'react';
import { Form, Popup, Radio, Checkbox, TextArea, Input } from 'semantic-ui-react';
import { StateContext } from '../Context/StateContext';

const Question = (props) => {

	const {data, selectedValue, setSelectedValue, ...rest} = props;

	const { highlightUnfilledRequiredFields } = useContext(StateContext);

	const options = data.options;
	const name = data && data.name ? data.name.fi : "";
	let subtitle = data && data.subtitle ? data.subtitle.fi : "";
	if(subtitle==="") subtitle=rest.subtitle || "";
	
	//console.log(data && data.name ? data.name.fi : "");
	//console.log(data);

	const highlight = highlightUnfilledRequiredFields && (data.required || rest.required) && (!selectedValue || selectedValue.length===0);

	return (
		<>
			{data.type !== 'checkbox' &&
				<div className={subtitle ? 'with-subtitle' : 'without-subtitle'}>
					<h1 className={highlight ? 'highlight' : undefined}>
						{name}
						{(data.required || rest.required) &&
							<sup><i className='icon asterisk'></i></sup>
						}
						{data.info && data.info.fi && <Popup position='top center' content={data.info.fi} trigger={<i className='info icon'></i>} />}
					</h1>
					{subtitle &&
						<div className='subtitle'>{subtitle}</div>
					}
				</div>
			}

			{/* type = radiogroup */}
			{data.type === 'radiogroup' &&
			options.map((option,index)=>
				<Fragment key={index}>
					{option.info && option.info.fi ?
						<div key={index}>
							<Form.Field
								key={index + 'radio'}
								control={Radio}
								label={option.name.fi}
								value={option.ref}
								checked={selectedValue===option.ref}
								onChange={(e,{value}) => setSelectedValue(value)}
								className='float-left'
							/>
							<Popup key={index + 'popup'} position='top center' content={option.info.fi} trigger={<i className='info icon'></i>} />
							{option.freetext && selectedValue===option.ref &&
								<Form.Field
									key={index + 'freetext'}
									control={Input}
									value={props.freeTextValue}
									onChange={e => props.setFreeText(e)}
									className='indented'
								/>
							}
						</div>
					:
						<>
						<Form.Field
							key={index}
							control={Radio}
							label={option.name.fi}
							value={option.ref}
							checked={selectedValue===option.ref}
							onChange={(e,{value}) => setSelectedValue(value)}
						/>
						{option.freetext && selectedValue===option.ref &&
							<Form.Field
								key={index + 'freetext'}
								control={Input}
								value={props.freeTextValue}
								onChange={e => props.setFreeText(e)}
								className='indented'
							/>
						}
						</>
					}
				</Fragment>
			)}

			{/* type = checkboxgroup */}
			{data.type === 'checkboxgroup' &&
			options.map((option,index)=>
				<>
					{option.info && option.info.fi ?
						<div key={index}>
							<Form.Field
								key={index + 'checkbox'}
								control={Checkbox}
								label={option.name.fi}
								value={option.ref}
								checked={selectedValue.find(item => item.ref === option.ref)}
								onChange={(e,{value}) => setSelectedValue(value)}
								className='float-left'
								disabled={!selectedValue.find(item => item.ref === option.ref) && selectedValue.length>data.limit-1 ? true : false}
							/>
							<Popup key={index + 'popup'} position='top center' content={option.info.fi} trigger={<i className='info icon'></i>} />
							{option.freetext && selectedValue.find(item => item.ref === option.ref) &&
								<Form.Field
									key={index + 'freetext'}
									control={Input}
									value={props.freeTextValue}
									onChange={e => props.setFreeText(e)}
									className='indented'
								/>
							}
						</div>
					:
						<>
						<Form.Field
							key={index}
							control={Checkbox}
							label={option.name.fi}
							value={option.ref}
							checked={selectedValue.find(item => item.ref === option.ref)}
							onChange={(e,{value}) => setSelectedValue(value)}
							disabled={!selectedValue.find(item => item.ref === option.ref) && selectedValue.length>data.limit-1 ? true : false}
						/>
						{option.freetext && selectedValue.find(item => item.ref === option.ref) &&
							<Form.Field
								key={index + 'freetext'}
								control={Input}
								value={props.freeTextValue}
								onChange={e => props.setFreeText(e,option.ref)}
								className='indented'
							/>
						}
						</>
					}
				</>
			)}

			{/* type = checkbox */}
			{data.type === 'checkbox' &&
				<Form.Field
				control={Checkbox}
				label={data.name.fi}
				value={data.ref}
				checked={selectedValue === data.ref}
				onChange={(e,{value}) => setSelectedValue(value)}
				className='single-checkbox'
			/>
			}

			{/* type = textbox */}
			{data.type === 'textbox' &&
				<Form.Field
					control={Input}
					value={selectedValue}
					onChange={e => setSelectedValue(e.target.value)}
					id={rest.id}
					icon={rest.icon}
				/>
			}

			{/* type = textarea */}
			{data.type === 'textarea' &&
				<Form.Field
					control={TextArea}
					value={selectedValue}
					onChange={e => setSelectedValue(e.target.value)}
				/>
			}
		</>
	)

}

export default Question;
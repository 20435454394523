import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divider, Button, Grid } from 'semantic-ui-react';
import PagedTable from '../../Reusable/Lists/PagedTable';
import useReadShift from '../../Hooks/Shifts/useReadShift';
import useDeleteShift from '../../Hooks/Shifts/useDeleteShift';
import useListShiftTypes from '../../Hooks/Shifts/useListShiftTypes';
import useListShiftUsers from '../../Hooks/ShiftUsers/useListShiftUsers';
import useDeleteShiftUser from '../../Hooks/ShiftUsers/useDeleteShiftUser';
import useReadUser from '../../Hooks/Users/useReadUser';
import { YesNoModal } from '../../Reusable/Modals/GeneralModal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MessageContext } from '../../Context/MessageContext';

const ShiftDetail = (props) => {

    const { t } = useTranslation();
    const { id } = useParams();
    const { addMessage } = useContext(MessageContext);

    const { shift, readShift } = useReadShift();
    const { deleteShift } = useDeleteShift();
    const { shiftTypes, listShiftTypes } = useListShiftTypes();
    const { shiftUsers, listShiftUsers } = useListShiftUsers();
    const { deleteShiftUser } = useDeleteShiftUser();
    const { user, readUser } = useReadUser();

    const [ deleteShiftModalOpen, setDeleteShiftModalOpen ] = useState(false);
    const [ deleteUserModalOpen, setDeleteUserModalOpen ] = useState(false);

    const [ userToDelete, setUserToDelete ] = useState(null);

    // Fetch shift
    useEffect(() => {
        (async () => {
            await readShift(id);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    //console.log(shift)

   // Fetch shift types
   useEffect(() => {
        (async () => {
            await listShiftTypes(id);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[shift]);

    // Fetch shift users
    useEffect(() => {
        (async () => {
            await listShiftUsers(id);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[shift]);

    // make delete links to users
    useEffect(() => {
        (async () => {
            shiftUsers.map(item => {
                item.link=t('general.delete')
                return item;
            })
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[shiftUsers]);

    // Fetch instructor information
    useEffect(() => {
        (async () => {
            if (shift.InstructorId) {
                await readUser(shift.InstructorId);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[shift]);

    const instructorName = user.data && user.data.Id ? user.data.FirstName + ' ' + user.data.LastName : ''

    let date = null;
    let time = null;
    if(shift.StartTime){
        date = new Date(shift.StartTime)
        time = date.getHours() + '.' + date.getMinutes().toString().padStart(2,'0')
        date = new Date(shift.EndTime)
        time += '-' + date.getHours() + '.' + date.getMinutes().toString().padStart(2,'0')
    }

    const delShift = () => {
        (async () => {
            let result = null;
            result = await deleteShift(id);
            if(result.status === 204){
                props.history.goBack();
            }
        })();
    }

    const delUser = () => {
        (async () => {
            let result = null;
            result = await deleteShiftUser(id,userToDelete);
            shiftUsers.splice(shiftUsers.indexOf(shiftUsers.find(item => item.Id === userToDelete)),1)
            setUserToDelete(null)
        })();
    }

    const removeUser = (ev, row, item) => {
        setUserToDelete(row.Id)
        setDeleteUserModalOpen(true)
    }

    // email addresses for copying to clipboard
    let emailList = ""
    shiftUsers.filter(item => item.EmailAddress).map(item => {
        emailList+=item.EmailAddress + ';'
        return item;
    });

    return (
        <div className='shift-detail'>
            <YesNoModal 
                type='delete'
                header={t('modal.confirmDelete')}
                text={t('modal.confirmDeleteShift')}
                method={delShift}
                open={deleteShiftModalOpen}
                setOpen={setDeleteShiftModalOpen}
            />
            <YesNoModal 
                type='delete'
                header={t('modal.confirmDelete')}
                text={t('modal.confirmDeleteUser')}
                method={delUser}
                open={deleteUserModalOpen}
                setOpen={setDeleteUserModalOpen}
            />

            <h1>{t('heading.shift')}</h1>

            <h2>{t('shift.timing')}</h2>
            <div>{shift.Date && new Date(shift.Date).toLocaleDateString()} {time ? t('general.at') + ' ' + time : ''}</div>

            <h2>{t('shift.types')}</h2>
            {shiftTypes.map(item =>
                <div key={item.Id}>{item.TypeName}</div>
            )}

            <h2>{t('shift.location')}</h2>
            <div>{shift.LocationName}</div>

            <h2>{t('shift.supervisor')}</h2>
            <div>{instructorName}</div>

            <h2>{t('shift.description')}</h2>
            <div>{shift.Description}</div>

            <h1>{t('heading.personsOnCall')}</h1>

            {shiftTypes.map(item =>
                <div className="persons-on-call" key={item.Id}>
                    <Grid stackable columns={2}>
                        <Grid.Column width={8}><h2>{item.TypeName}</h2></Grid.Column>
                        <Grid.Column width={8}>
                            {shiftUsers.filter(user => user.ShiftTypeId === item.Id).length}/{item.ShiftUserCount}
                            {shiftUsers.filter(user => user.ShiftTypeId === item.Id).length === item.ShiftUserCount ?
                                <>
                                    <em className="highlight"> {t('shift.full')}</em>
                                    <Button floated='right' disabled>{t('shift.addPersonOnCall')}</Button>
                                </>
                            :
                                <Button floated='right' onClick={() => props.history.push("/shift/" + id + "/adduser/" + item.Id)}>{t('shift.addPersonOnCall')}</Button>
                            }
                            
                        </Grid.Column>
                    </Grid>
                    <PagedTable unstackable striped celled compact='very'
                        header={[
                            t('user.name'),
                            t('user.email'),
                            t('user.city'),
                            t('user.specialDiet'),
                            t('shift.joindate'),
                            ''
                        ]}
                        content={shiftUsers.filter(user => user.ShiftTypeId === item.Id)}
                        contentKeys={[
                            {key: 'LastName', key2: 'FirstName', type: 'twokeys'},
                            {key: 'EmailAddress'},
                            {key: 'LocationName'},
                            {key: 'SpecialDiet'},
                            {key: 'EnrollmentDate', type: 'datetime'},
                            {key: 'link', type: 'action', action: removeUser}
                        ]}
                        pageSize='20'
                        
                    />
                </div>
            )}


            
            <Divider/>
            <Button onClick={() => props.history.push('/shift/edit/' + id)}>{t('general.edit')}</Button>
            <Button onClick={() => props.history.push('/shift/new/' + id)}>{t('shift.copyToNewShift')}</Button>
            <Button onClick={() => setDeleteShiftModalOpen(true)}>{t('general.delete')}</Button>
            <Button floated='right' onClick={() => props.history.goBack()}>{t('general.back')}</Button>
            <CopyToClipboard 
                text={emailList}
                onCopy={() => {addMessage({type: "success", header: t('general.copied'), body: t('info.emailsCopiedToClipboard')});window.scrollTo(0,0)}}
            >
                <Button floated='right'>{t('user.copyToClipboard')}</Button>
            </CopyToClipboard>

        </div>
    )
}

export default ShiftDetail;
import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageContext } from '../../Context/MessageContext';
import Axios from 'axios';
import Config from '../../Config/Config';
import { useMsal } from "@azure/msal-react";

const useReadUser = () => {

    const rootPath = Config.api.rootPath;
    const { instance } = useMsal();
    const { t } = useTranslation();
    const { addMessage, removeMessage } = useContext(MessageContext);
    const [ user, setUser ] = useState([]);
    const [ fetchingUser, setFetchingUser ] = useState(true);

    /** list contracts
     * @param {integer} id - user id
     * @returns {object} - user profile */ 
    const readUser = async (id) => {
        try {

            const msgId = addMessage({ type: "loading", header: t('general.moment'), body: t('info.fetchingProfile')});
            
            const activeAccount = instance.getActiveAccount();
            const accounts = instance.getAllAccounts();

            if (!activeAccount && accounts.length === 0) return;

            const request = {
                scopes: Config.api.scopes,
                account: activeAccount || accounts[0]
            }

            const authResult = await instance.acquireTokenSilent(request);

            const options = {
                "headers": {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + authResult.accessToken
                }
            }

            let result = null;

            result = await Axios.get(rootPath + "/api/v1/users/" + id, options);

            removeMessage(msgId);

            if (result && result.status === 200) {
                setUser(result);
                setFetchingUser(false);
                return result;
            }

            if (result && result.status === 404) {
                addMessage({ type: "warning", header: t('general.error'), body: t('warnings.profileNotFound') });
                setFetchingUser(false);
                return result;
            }

            addMessage({ type: "error", header: t('general.error'), body: t('errors.fetchingProfileFailed') });
            setFetchingUser(false);
            return result;

        } catch (error) {
            if (Config.environment === 'development' || Config.environment === 'test') console.log(error);
            addMessage({ type: "error", header: t('general.error'), body: t('errors.fetchingProfileFailed') });
            setFetchingUser(false);
            return error;
        }
    }

    return { user, fetchingUser, readUser };

}

export default useReadUser;

import React, { useContext } from 'react';
import {MessageContext} from './../Context/MessageContext';
import {InfoMessage,PositiveMessage,WarningMessage,ErrorMessage,SavingMessage,FetchingMessage} from '../Reusable/Messages/Messages';

const Messages = (props) => {

    const { messages, removeMessage } = useContext(MessageContext);

    const msg = messages.map(msg => {

        let selectedType = null;

        switch (msg.type) {
            case "success":
                selectedType = <PositiveMessage key={msg.id} header={msg.header} content={msg.body} option={msg.option} onDismiss={() => removeMessage(msg.id)} />
                break;
            case "warning":
                selectedType = <WarningMessage key={msg.id} header={msg.header} content={msg.body} option={msg.option} onDismiss={() => removeMessage(msg.id)} />
                break;
            case "error":
                selectedType = <ErrorMessage key={msg.id} header={msg.header} content={msg.body} option={msg.option} onDismiss={() => removeMessage(msg.id)} />
                break;
            case "saving":
                selectedType = <SavingMessage key={msg.id} header={msg.header} content={msg.body} option={msg.option} onDismiss={() => removeMessage(msg.id)} />
                break;
            case "loading":
                selectedType = <FetchingMessage key={msg.id} header={msg.header} content={msg.body} option={msg.option} onDismiss={() => removeMessage(msg.id)} />
                break;
            default:
                selectedType = <InfoMessage key={msg.id} header={msg.header} content={msg.body} option={msg.option} onDismiss={() => removeMessage(msg.id)} />
                break;
        }
        
        return selectedType;

    });

    return (
        <div className="messages" style={{ marginBottom: '20px' }}>
            {msg}
        </div>
    );

}

export default Messages;

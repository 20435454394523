
import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageContext } from '../../Context/MessageContext';
import Axios from 'axios';
import Config from '../../Config/Config';
import { useMsal } from "@azure/msal-react";

const useSearchShifts = () => {
    const rootPath = Config.api.rootPath;
    const { instance } = useMsal();
    const { t } = useTranslation();
    const { addMessage, removeMessage } = useContext(MessageContext);
    const [ shifts, setShifts ] = useState([]);
    const [ fetchingShifts, setFetchingShifts ] = useState(true);

    /** search shifts
    * @param {searchParams} object including all search parameters
    *   starttime - shifts after (and including) this date
    *   endtime - shifts before (and including) this date
    *   service - shifts having types with this service
    *   channel - shifts having types with this channel
    *   type - shifts having this type
    *   location - shifts in this location
    * @returns {array} - array of shifts 
    */ 
    const searchShifts = async (searchParams) => {
        try {

            const msgId = addMessage({ type: "loading", header: t('general.moment'), body: t('info.fetchingShifts')});

            const activeAccount = instance.getActiveAccount();
            const accounts = instance.getAllAccounts();

            if (!activeAccount && accounts.length === 0) return;

            const request = {
                scopes: Config.api.scopes,
                account: activeAccount || accounts[0]
            }

            const authResult = await instance.acquireTokenSilent(request);
            
            const options = {
                "headers": {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + authResult.accessToken
                }
            }

            let result = null;

            let queryString = ""
            if (searchParams && searchParams.startdate) { queryString += "starttime=" + searchParams.startdate + '&'}
            if (searchParams && searchParams.enddate) { queryString += "endtime=" + searchParams.enddate + '&'}
            if (searchParams && searchParams.service) { queryString += "service=" + searchParams.service + '&'}
            if (searchParams && searchParams.channel) { queryString += "channel=" + searchParams.channel + '&'}
            if (searchParams && searchParams.type) { queryString += "type=" + searchParams.type + '&'}
            if (searchParams && searchParams.locations) { queryString += "locations=" + searchParams.locations}

            //console.log(queryString)

            result = await Axios.get(rootPath + "/api/v1/search/shifts?" + queryString, options);

            removeMessage(msgId);

            
            if (result && result.status === 200 && result.data) {
                setShifts(result.data.data);
                setFetchingShifts(false);
                return result;
            }

            if (result && result.status === 404) {
                addMessage({ type: "warning", header: t('general.error'), body: t('warnings.shiftsNotFound') });
                setFetchingShifts(false);
                return result;
            }

            addMessage({ type: "error", header: t('general.error'), body: t('errors.fetchingShiftsFailed') });
            setFetchingShifts(false);
            return result;

        } catch (error) {
            if (Config.environment === 'development' || Config.environment === 'test') console.log(error);
            addMessage({ type: "error", header: t('general.error'), body: t('errors.fetchingShiftsFailed') });
            setFetchingShifts(false);
            return error;
        }
    }

    return { shifts, fetchingShifts, searchShifts };
}

export default useSearchShifts;
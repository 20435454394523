import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Message, Table, Divider, Button } from 'semantic-ui-react';
import useReadUser from '../../Hooks/Users/useReadUser';
import useListUserActivities from '../../Hooks/Users/useListUserActivities';
import { StateContext } from '../../Context/StateContext';
import { useParams } from 'react-router-dom';
import SubNavigation from '../../Pages/User/SubNavigation';

const ActivityStatus = (props) => {

    const { t } = useTranslation();
    const { id } = useParams();
    const { user, readUser } = useReadUser();
    const { userActivities, listUserActivities } = useListUserActivities();
    const { userDetailBackRoute } = useContext(StateContext);

    useEffect(() => {
        (async () => {
            await readUser(id);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        (async () => {
            await listUserActivities(id);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const years = []
    userActivities.map(item => {
        if(!years.includes(item.Year)){
            years.push(item.Year)
        }
        return item;
    })
    
    return (
        <div>
            <SubNavigation id={id} activePage='activity' />

            <h1>{t('user.activityStatus')}</h1>
            {user && user.data && user.data.ActivityAchieved ?
                <Message className='align-center' positive>{t('user.activityAchieved')}</Message>
            :
                <Message className='align-center' negative>{t('user.activityNotAchieved')}</Message>
            }
            <Divider />

            {years.map(y =>
                <Table basic='very'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{y}</Table.HeaderCell>
                            <Table.HeaderCell>{t('user.achieved')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('user.shiftCount')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {userActivities && userActivities.filter(item => item.Year === y).map(item =>
                        <Table.Row>
                            <Table.Cell>{t("general.months." + item.Month)}</Table.Cell>
                            <Table.Cell>{item.Achieved ? t('general.yes'): t('general.no')}</Table.Cell>
                            <Table.Cell>{item.ShiftCount}</Table.Cell>
                        </Table.Row>
                    )}
                    </Table.Body>
                </Table>
            )}
            <Divider />
            <Button onClick={() => props.history.push(userDetailBackRoute || '/user/list')}>{t('general.backToUserList')}</Button>
        </div>


    );

}

export default ActivityStatus;
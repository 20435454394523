import React, { useContext, useState } from 'react';
import SubNavigation from '../../Layout/SubNavigation';
import { Grid, Segment, Container, Divider, Form, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Question from '../../Reusable/Question';
import { StateContext } from '../../Context/StateContext';
import { YesNoModal } from '../../Reusable/Modals/GeneralModal';

const ContactType = (props) => {

  const { t } = useTranslation();
  const { globalState, contactDataOK, saveContactData } = useContext(StateContext);
  const [ saveModalOpen, setSaveModalOpen ] = useState(false);

  let questions = []
  if(globalState.service.type === 'vpn') questions = globalState.settings.find(item => item.type === 'vpn') || [];
  if(globalState.service.type === 'lnpn') questions = globalState.settings.find(item => item.type === 'lnpn') || [];
  
  const saveContact = async () => {
    saveContactData(props.history);
  }

  return (
    <div>
      <YesNoModal 
          type='save'
          header={t('modal.confirmSaveContact')}
          text={t('modal.noOtherInformationNeeded')}
          method={saveContact}
          open={saveModalOpen}
          setOpen={setSaveModalOpen}
      />
      <SubNavigation activePage='contacttype' />
      <Form>
        <Grid stackable columns={2}>
          <Grid.Column>
            <Segment>
              <Question
                data={questions.contactType || []} 
                selectedValue={globalState.contactType.ref} 
                setSelectedValue={val => {
                  const x = questions.contactType.options.find(s => s.ref === val)
                  globalState.setContactType(x)
                }} 
              />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
            
            </Segment>
          </Grid.Column>
        </Grid>
        
        <Container>
            <Divider/>
            <a target='_blank' href='https://mllry.sharepoint.com/teams/msteams_088f12/SitePages/Home.aspx'>{t('general.homepage')}</a>
        
            {globalState.contactType.ref === 'silentContact' || globalState.contactType.ref === 'excludedContact' ?
              <Button floated='right' onClick={() => setSaveModalOpen(true)}>{t('general.savecontact')}</Button>
            :
              <>
              {contactDataOK().result ?
                <Button floated='right' onClick={() => saveContactData(props.history)}>{t('general.savecontact')}</Button>
              :
                <Button floated='right' onClick={() => props.history.push('/contact/backgroundinformation')}>{t('general.nextstep')}</Button>
              }
              </>
            }
        </Container>
      </Form>
  </div>
  );
}

export default ContactType;

import React, { useContext } from 'react';
import SubNavigation from '../../Layout/SubNavigation';
import { Form, Grid, Segment, Container, Divider, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { StateContext } from '../../Context/StateContext';
import Question from '../../Reusable/Question';

function Referral(props) {

  const { t } = useTranslation();
  
  const { globalState, contactDataOK, saveContactData } = useContext(StateContext);

  let questions = []
  if(globalState.service.type === 'vpn') questions = globalState.settings.find(item => item.type === 'vpn') || [];
  if(globalState.service.type === 'lnpn') questions = globalState.settings.find(item => item.type === 'lnpn') || [];

  const setReferralTargets = (obj) => {
    let newObj = {};
    if(globalState.referralTargets.find(item => item.ref === obj.ref)){
      // uncheck -> remove item from array
      newObj = globalState.referralTargets.filter(item => item.ref !== obj.ref)
    } else {
      //check -> add item to array
      newObj = globalState.referralTargets.concat(obj)
    }
    globalState.setReferralTargets(newObj)
  }

  return (
    <div>
      <SubNavigation activePage='referral' />
      <Form>
        <Grid stackable columns={2}>
          <Grid.Column>
            <Segment>
              <Question
                data={questions.referralGiven || []} 
                selectedValue={globalState.referralGiven.ref} 
                setSelectedValue={val => {
                  const x = questions.referralGiven.options.find(s => s.ref === val)
                  globalState.setReferralGiven(x)
                }}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              {globalState.referralGiven.ref === 'yes' &&
                <Question
                  data={questions.referralTargets || []} 
                  selectedValue={globalState.referralTargets} 
                  setSelectedValue={val => {
                    const x = questions.referralTargets.options.find(s => s.ref === val)
                    setReferralTargets(x)
                  }}
                />
              }
            </Segment>
          </Grid.Column>
        </Grid>
        
        <Container>
            <Divider/>
            {contactDataOK().result ?
              <Button floated='right' onClick={() => saveContactData(props.history)}>{t('general.savecontact')}</Button>
            :
              <Button floated='right' onClick={() => props.history.push("/contact/retrospective")}>{t('general.nextstep')}</Button>
            }
        </Container>
      </Form>
    </div>
  );
}

export default Referral;

import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Button, Form, Checkbox, Popup, TextArea } from 'semantic-ui-react';
import PagedTable from '../../Reusable/Lists/PagedTable';
import useSearchShifts from '../../Hooks/Shifts/useSearchShifts';
import { StateContext } from '../../Context/StateContext';
import { MessageContext } from '../../Context/MessageContext';
import useListUsers from '../../Hooks/Users/useListUsers';
import useCreateSendQueueEmail from '../../Hooks/SendQueue/useCreateSendQueueEmail';

const Alerts = (props) => {

    const { t } = useTranslation();
    const [ message, setMessage ] = useState('');
    const [ location, setLocation ] = useState('');
    const [ selectedServices, setSelectedServices ] = useState([]);
    const [ onCallRemote, setOnCallRemote ] = useState(false);
    const { shifts, searchShifts } = useSearchShifts();
    const { users, listUsers } = useListUsers();
    const { locations, types, services, globalState, profile } = useContext(StateContext);
    const { addMessage } = useContext(MessageContext);
    const { createSendQueueEmail } = useCreateSendQueueEmail();

    let locationOptions = [{key: 'all', text: t('shift.location-options.all'), value: ''}]
    locations.map(i => {
        if(i.Reference!=='remote'){
            const option = {
                key: i.Id,
                text: i.Name,
                value: i.Id
            }
            locationOptions.push(option);
        }
        return i;
    })

    // Fetch shifts
    useEffect(() => {
        (async () => {
            // startdate next monday
            let startdate = new Date()
            startdate.setDate(startdate.getDate() + (((1 + 7 - startdate.getDay()) % 7) || 7));
            
            // end date next sunday after start date
            let enddate= new Date(startdate)
            enddate.setDate(startdate.getDate() + 7)

            let dateArray = startdate.toLocaleDateString().split('.')
            let startdateISO = new Date(dateArray[2] + '-' + dateArray[1].padStart(2,'0') + '-' + dateArray[0].padStart(2,'0')).toISOString()
            dateArray = enddate.toLocaleDateString().split('.')
            let enddateISO = new Date(dateArray[2] + '-' + dateArray[1].padStart(2,'0') + '-' + dateArray[0].padStart(2,'0')).toISOString()
            
            let params = {
                startdate: startdateISO,
                enddate: enddateISO
            }
            
            // send the actual request
            await searchShifts(params);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // add delete links to shifts
    useEffect(() => {
        let newArray = []
        if (globalState.selectedShifts.length===0){
            newArray = [...shifts];
        } else {
            newArray = [...globalState.selectedShifts]
        }
        
        newArray.map(shift => {
            shift.link=t('general.delete')
            shift.typeArray = shift.ShiftTypes ? shift.ShiftTypes.map(item => types.filter(type => type.Id === item.TypeId).map(type => type.Name)) : []
            shift.countArray = shift.ShiftTypes ? shift.ShiftTypes.map(item => (item.ShiftTypeUsers ? item.ShiftTypeUsers.length : '0') + '/' + item.ShiftUserCount) : []
            return shift;
       })

       globalState.setSelectedShifts(newArray);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[shifts]);

    // Fetch users
    useEffect(() => {
        (async () => {
            await listUsers();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const setServices = (value) => {
        const array = [...selectedServices]
        if(array.find(item => item === value)){
            // uncheck, remove item from array
            setSelectedServices(array.filter(item => item !== value))
        } else {
            // check, add item to array
            array.push(value)
            setSelectedServices(array)
        }
    }

    let filteredUsers = []
    if (location) {
        filteredUsers = users.filter(item => item.LocationId === location)
    }
    if (onCallRemote) {
        if(filteredUsers.length===0) filteredUsers = [...users];
        filteredUsers = filteredUsers.filter(item => item.IsRemoteWorker)
    }
    if(selectedServices.length>0){
        if(filteredUsers.length===0) filteredUsers = [...users];
        filteredUsers = filteredUsers.filter(item => selectedServices.some(el => item.UserServices && item.UserServices.find(i => i.ServiceId === el)))
    }
    if(filteredUsers.length>=0){
        // show only active users
        filteredUsers = filteredUsers.filter(item => item.IsActive)
    }

    const removeShift = (ev, row, item) => {
        const newArray = globalState.selectedShifts.filter(item => item.Id !== row.Id)
        globalState.setSelectedShifts(newArray)
    }

    const sendAlert = async () => {

        let body = "<style>td { vertical-align: top; padding: 5px 20px 5px 0px; }</style>"
        body += "<p>" + message.replaceAll('\n',"<br>") + "</p>"
        body += "<p><table>";
        globalState.selectedShifts.map(shift => {
            const time = new Date(shift.StartTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) + '-' + new Date(shift.EndTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            body += "<tr><td>" + new Date(shift.Date).toLocaleDateString() + "</td>"
            body += "<td>" + time + "</td>"
            body += "<td>"
            shift.ShiftTypes.map(type => {
                const t = types.find(item => item.Id === type.TypeId)
                body += t.Name + "<br>"
                return type;
            })
            body += "</td>"
            body += "<td>" + shift.InstructorName + "</td></tr>"
            return shift;
        })
        body += "</table></p>"
        let emails = "</p><p>"
        locations.map(l => {
            if(l.Reference !== 'remote'){
                emails += l.Name + ': ' + l.Email + '<br>'
            }
        })
        body += "<p>" + t('email.alertBody').replace('$cityEmails$',emails) + "</p>"


        let bcc = [];
        filteredUsers.map(user => {
            bcc.push(user.EmailAddress)
            return user;
        })
        const locObject = locations.find(item => item.Id === location);
        let data = [{
            type: "email",
            to: locObject !== undefined ? locObject.Email : null,
            cc: null,
            bcc: bcc,
            subject: t('email.alertSubject'),
            body: body,
            replyTo: null
        }]

        //console.log(data)

        const result = await createSendQueueEmail(data,false);

        if(result.status === 201){
            addMessage({type: "success", header: t('general.saved'), body: t('info.sendQueueEmailSaved')});
            globalState.setSelectedShifts([])
            props.history.goBack();
        }
    }

    const cancelAlert = () => {
        globalState.setSelectedShifts([])
        props.history.goBack();
    }

    return (
        <div>
            <h1>{t('heading.newAlert')}</h1>
            <Form>
                <h2>{t('heading.shifts')}<Popup position='top center' content={t('alert.defaultShifts')} trigger={<i className='info icon'></i>} /></h2>
                <div>
                    <PagedTable unstackable striped celled compact='very'
                        header={[
                            t('shift.date'),
                            t('shift.startTime'),
                            t('shift.endTime'),
                            t('shift.type'),
                            t('heading.personsOnCall'),
                            t('shift.location'),
                            t('shift.supervisor'),
                            ''
                        ]}
                        content={globalState.selectedShifts}
                        contentKeys={[
                            {key: 'Date', type: 'date'},
                            {key: 'StartTime', type: 'time'},
                            {key: 'EndTime', type: 'time'},
                            {key: 'typeArray', type: 'array'},
                            {key: 'countArray', type: 'array'},
                            {key: 'LocationName'},
                            {key: 'InstructorName'},
                            {key: 'link', type: 'action', action: removeShift}
                        ]}
                        pageSize='20'
                    />
                    <br/>
                </div>
                <Button onClick={() => props.history.push('/shift/alert/add')}>{t('alert.addShifts')}</Button>

                <h2>{t('heading.personsOnCall')}</h2>
            
                <Form.Group widths='equal'>
                    <Form.Select
                        value={location}
                        onChange={(e,{value}) => setLocation(value)}
                        options={locationOptions}
                        placeholder={t('shift.location-options.all')}
                    />
                    <Form.Field
                        control={Checkbox}
                        label={t('shift.service-options.remote')}
                        checked={onCallRemote}
                        onChange={(e) => setOnCallRemote(!onCallRemote)}
                    />
                    <Form.Field label={t('shift.service')} />
                    {services.map(item => (
                        <Form.Field
                            key={item.Id}
                            control={Checkbox}
                            label={item.Name}
                            value={item.Id}
                            checked={selectedServices.find(s => s === item.Id)!==undefined}
                            onChange={(e,{value}) => setServices(value)}
                        />
                    ))}
                </Form.Group>
            
                <PagedTable unstackable striped celled compact='very'
                    header={[
                        t('user.lastname'),
                        t('user.firstname'),
                        t('user.email'),
                        t('user.city')
                    ]}
                    content={filteredUsers}
                    contentKeys={[
                        {key: 'LastName'},
                        {key: 'FirstName'},
                        {key: 'EmailAddress'},
                        {key: 'LocationName'}
                    ]}
                    pageSize='20'
                />

                <h2>{t('heading.message')}</h2>
                <Form.Field
                    control={TextArea}
                    label={t('alert.addMessage')}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
            
                <Divider/>
                <Button onClick={() => sendAlert()}>{t('alert.sendAlert')}</Button>
                <Button onClick={() => cancelAlert()}>{t('general.cancel')}</Button>
            </Form>
        </div>
    )

}

export default Alerts;
import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PagedTable from '../../Reusable/Lists/PagedTable';
import { Divider, Button, Grid, Segment } from 'semantic-ui-react';
import useListBadgeOfMerits from '../../Hooks/Users/BadgeOfMerit/useListBadgeOfMerits';
import useReadUser from '../../Hooks/Users/useReadUser';
import { useParams } from 'react-router-dom';
import SubNavigation from '../User/SubNavigation';
import { StateContext } from '../../Context/StateContext';

const BadgeOfMeritList = (props) => {

    const { id } = useParams();
    const { t } = useTranslation();
    const { badgeOfMerits, listBadgeOfMerits } = useListBadgeOfMerits();
    const { user, readUser } = useReadUser();
    const { userDetailBackRoute } = useContext(StateContext);

    // Fetch badges of merit
    useEffect(() => {
        (async () => {
            await listBadgeOfMerits(id);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // add detail link to badge array
    useEffect(() => {
        badgeOfMerits.map(item => {
            item.viewUrl = 'detail/';
            item.link=t('general.view');
            return item;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[badgeOfMerits]);

    // Fetch user data
    useEffect(() => {
        (async () => {
            await readUser(id);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const userShiftCount = user && user.data ? (user.data.UserShiftCount || 0) + (user.data.UserShiftCountInPreviousSystem || 0) : 0;

    
    return (
        <div>
            <SubNavigation id={id} activePage='badges' />

            <Grid stackable columns={2} className="add-margin">
                <Grid.Column><h1>{t('heading.badgeOfMerits')}</h1></Grid.Column>
                <Grid.Column>
                    <Button floated='right' onClick={() => props.history.push('/user/' + id + '/badgeofmerit/new')}>{t('badgeOfMerit.add')}</Button>
                </Grid.Column>
            </Grid>

            <Segment basic>
                {t('user.hasStarted')} {user && user.data && user.data.StartDate ? new Date(user.data.StartDate).toLocaleDateString() : '-'}. {t('user.shiftCount')} {userShiftCount} {t('general.pcs')}.
            </Segment>
                
            <PagedTable unstackable striped celled compact='very'
                header={[
                    t('badgeOfMerit.date'),
                    t('badgeOfMerit.name'),
                    t('badgeOfMerit.description'),
                    ''
                ]}
                content={badgeOfMerits}
                contentKeys={[
                    {key: 'Date', type: 'date'},
                    {key: 'BadgeOfMeritName'},
                    {key: 'Description', type: 'firstChars', limit: 100},
                    {key: 'link', type: 'url', urlKey:'viewUrl', cellAlign: 'left', targetKey: 'Id'},
                ]}
                pageSize='20'
            />

            <Divider />
            <Button onClick={() => props.history.push(userDetailBackRoute || '/user/list')}>{t('general.backToUserList')}</Button>
                
        </div>
    );

}

export default BadgeOfMeritList;

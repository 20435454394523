import React, { createContext, useState } from 'react'; 
import { v4 as uuidv4 } from 'uuid';

export const MessageContext = createContext();

/** Message provider for the application
 *  Add all message properties and methods here. */
const MessageContextProvider = ({children}) => {

    const [ messages, setMessages ] = useState([]);

    // Remove message from the messages array (dont remove errors automatically!)
    const removeMessageAfterTimeout = (id, timeout) => {
        return setTimeout(() => {
            setMessages(messages => {
                return messages.filter(msg => {
                    if (msg.type === 'error') return true;
                    if (msg.id === id) return false;
                    return true;
                });
            });
        }, timeout);
    }

    /** Function adds message to the container
     *   @param {object || array} msg - Message object or array of objects 
     *   @example
     *    Array:  [{type:"", header:"", body:""},{type:"", header:"", body:""}]
     *    Object: {type:"", header:"", body:""} */
    const addMessage = (msg) => {

        // Message is array of objects 
        if (Array.isArray(msg)) {

            return setMessages(messages => {
                const clone = [...messages]; //cloneArray(messages);
                msg.forEach(item => {
                    item.id = uuidv4();
                    clone.push(item);
                    let timeout = 5000
                    if(item.type==='warning') timeout = 8000
                    removeMessageAfterTimeout(item.id,timeout);
                });
                return clone;
            });

        // Message is object 
        } else if (msg && msg.header && msg.body && msg.type) {
                
                msg.id = uuidv4();
                setMessages(messages => {
                    const clone = [...messages]; //cloneArray(messages);
                    clone.push(msg);
                    let timeout = 5000
                    if(msg.type==='warning') timeout = 8000
                    removeMessageAfterTimeout(msg.id,timeout);
                    return clone;
                });
                return msg.id;
        
        // Error
        } else {
            throw new Error("Message is not an object or array!");
        }
    }

    /* Function removes message from the container */
    const removeMessage = (id) => {
        setMessages(messages => {
            return messages.filter(item => {
                return item.id !== id; 
             });
        });
    }

    return (
        <MessageContext.Provider value={{ messages, addMessage, removeMessage }}>
            {children}
        </MessageContext.Provider>
    )
}

export default MessageContextProvider;

const config = {
    "development": {
        "environment": "development",
        "api": {
            "rootPath": "https://statistics-api-dev.azurewebsites.net",
            "scopes": ["https://statistics-api-dev.azurewebsites.net/user_impersonation"],
        },
        "authentication": {
            "auth": {
                "clientId": "c60a53b7-e9a7-482d-94f7-21653770ccee",
                "authority": "https://login.microsoftonline.com/c76a02d4-bb26-466d-8b8f-135ed699f407",
                "redirectUri": "https://statistics-front-dev.azurewebsites.net/.auth/login/aad/callback"
            },
            "cache": {
                "cacheLocation": "sessionStorage", // localStorage || sessionStorage
                "storeAuthStateInCookie": false
            }
        },
        "configurations": {
            "subjectLimit": 3
        }
    },
    "production": {
        "environment": "production",
        "api": {
            "rootPath": "https://statistics-api-prod.azurewebsites.net",
            "scopes": ["https://statistics-api-prod.azurewebsites.net/user_impersonation"],
        },
        "authentication": {
            "auth": {
                "clientId": "41777de4-7b03-4077-b39d-9c6f6ea2d615",
                "authority": "https://login.microsoftonline.com/c76a02d4-bb26-466d-8b8f-135ed699f407",
                //"redirectUri": "https://statistics-front-prod.azurewebsites.net/.auth/login/aad/callback"
                "redirectUri": "https://paivystys.mll.fi/.auth/login/aad/callback"
            },
            "cache": {
                "cacheLocation": "sessionStorage", // localStorage || sessionStorage
                "storeAuthStateInCookie": false
            }
        },
        "configurations": {
            "subjectLimit": 3
        }
    },
    "configurations": {
        "subjectLimit": 3
    },
}

// Choose environment [config.development || config.production ]
let selectedEnvironment = config.production;

// Set localhost redirect url
if (window.location.href.indexOf("localhost") !== -1) {
    selectedEnvironment.authentication.auth.redirectUri = "http://localhost:3000/.auth/login/aad/callback";
}

// Export selected environment
export default selectedEnvironment;

import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Form,  Divider, Button, Table, Grid } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import useReadUser from '../../Hooks/Users/useReadUser';
import useReadReminder from '../../Hooks/Users/Reminder/useReadReminder';
import useUpdateReminder from '../../Hooks/Users/Reminder/useUpdateReminder';
import { MessageContext } from '../../Context/MessageContext';
import { StateContext } from '../../Context/StateContext';

const EditUser = (props) => {

    const { t } = useTranslation();
    const { addMessage, removeMessage, messages } = useContext(MessageContext);
    const { locations } = useContext(StateContext);
    const { userId, reminderId } = useParams();
    const { user, readUser } = useReadUser();
    const { reminder, readReminder } = useReadReminder();
    const { updateReminder } = useUpdateReminder();

    const [ date, setDate ] = useState(null);
    const [ subject, setSubject ] = useState('');
    const [ description, setDescription ] = useState('');

    useEffect(() => {
        (async() => {
            await readReminder(userId,reminderId);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async() => {
            await readUser(userId);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // get the email address for users location
    let recipient=""
    if(user && user.data && user.data.LocationId && locations.length>0){
        recipient = locations.find(item => item.Id === user.data.LocationId).Email;
    }

    // Set user data
    useEffect(() => {
        (async () => {
            if (reminder.data && reminder.data.Id) {
                setDate(reminder.data.Date ? new Date(reminder.data.Date).toLocaleDateString() : '');
                setSubject(reminder.data.Subject || '');
                setDescription(reminder.data.Body || '');
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reminder]);

    const handleSave = async () => {
        messages.map(msg => removeMessage(msg.id));

        //validate
        if(!date || !description || !subject) {
            addMessage({type: "error", header: t('message.fillInRequiredInformation'), body: t('message.requiredMarkedWithAsterisk')})
            window.scrollTo(0, 0)
        } else {
            // Format date
            let dateParts = []
            let dateISO = null;
            if(date) {
                dateParts=date.split('.');
                dateISO=new Date(dateParts[2] + "-" + dateParts[1].padStart(2,"0") + "-" + dateParts[0].padStart(2,"0")).toISOString();
            }

            //create data
            let data = {
                "date": dateISO,
                "description": description,
                "subject": subject,
                "recipient": recipient
            }

            //save
            let result = null;
            result = await updateReminder(userId,reminderId,data);

            if(result.status === 200) {
                props.history.goBack();
            }
        }
    }

    return (
        <div className='userform'>
            <Form>
                <Container className="userlist">
                    <h1>{t('heading.user')}</h1>
                    
                    <h2>{t('heading.user')}</h2>
                    <div>{user.data && user.data.FirstName} {user.data && user.data.LastName}</div>

                    <h2>{t('reminder.recipient')}</h2>
                    <div>{recipient}</div>
                
                    <h2>{t('reminder.date')}<sup><i className='icon asterisk'></i></sup></h2>
                    <DateInput
                        localization='fi'
                        dateFormat={'DD.MM.YYYY'}
                        value={date || ""}
                        iconPosition="right"
                        onChange={(ev, {value}) => setDate(value) }
                        closable={true}
                        popupPosition='bottom left'
                    />

                    <h2>{t('reminder.subject')}<sup><i className='icon asterisk'></i></sup></h2>
                    <Form.Input
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                    />
                
                    <h2>{t('reminder.description')}<sup><i className='icon asterisk'></i></sup></h2>
                    <Form.TextArea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </Container>
            </Form>
            <Divider/>
            <Button onClick={() => handleSave()}>{t('general.save')}</Button>
            <Button onClick={() => props.history.goBack()}>{t('general.cancel')}</Button>
        </div>
    )

}

export default EditUser;
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';

const Start = (props) => {

    const { t } = useTranslation();

    return (
        <div>
            <h1>{t('heading.newAlert')}</h1>
            <Button onClick={() => props.history.push('/shift/alert/create')}>{t('alert.sendAlert')}</Button>
        </div>
    )

}

export default Start;
import React from 'react';
import { Button, Icon } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import './Buttons.css';

/* Props:
 * noTitle: Title is not visible if set to true
*/

const NewButton = (props) => {
    const { t } = useTranslation();
    const { noTitle, ...rest } = props;
    return (
        <Button icon color='green' labelPosition={ noTitle ? null : 'left' } size='small' type='submit' {...rest}>
            <Icon name='add' />
            {!noTitle && (props.title || t('general.new'))}
        </Button>
    );
}

const CreateButton = (props) => {
    const { t } = useTranslation();
    const { noTitle, ...rest } = props;
    return (
        <Button icon color='green' labelPosition={ noTitle ? null : 'left' } size='small' type='submit' {...rest}>
            <Icon name='add' />
            {!noTitle && (props.title || t('general.create'))}
        </Button>
    );
}

const ViewButton = (props) => {
    const { t } = useTranslation();
    const { noTitle, ...rest } = props;
    return (
        <Button icon color='green' labelPosition={ noTitle ? null : 'left' } size='small' type='submit' {...rest}>
            <Icon name='arrow right' />
            {!noTitle && (props.title || t('general.view'))}
        </Button>
    );
}

const EditButton = (props) => {
    const { t } = useTranslation();
    const { noTitle, ...rest } = props;
    return (
        <Button icon color='green' labelPosition={ noTitle ? null : 'left' } size='small' type='submit' {...rest}>
            <Icon name='pencil' />
            {!noTitle && (props.title || t('general.edit'))}
        </Button>
    );
}

const DeleteButton = (props) => {
    const { t } = useTranslation();
    const { noTitle, ...rest } = props;
    return (
        <Button icon color='red' labelPosition={ noTitle ? null : 'left' } size='small' type='submit' {...rest}>
            <Icon name='delete' />
            {!noTitle && (props.title || t('general.delete'))}
        </Button>
    );
}

const SaveButton = (props) => {
    const { t } = useTranslation();
    const { noTitle, ...rest } = props;
    return (
        <Button icon color='green' labelPosition={ noTitle ? null : 'left' } size='small' type='submit' {...rest}>
            <Icon name='save' />
            {!noTitle && (props.title || t('general.save'))}
        </Button>
    );
}

const CancelButton = (props) => {
    const { t } = useTranslation();
    const { noTitle, ...rest } = props;
    return (
        <Button icon color='blue' labelPosition={ noTitle ? null : 'left' } size='small' type='reset' {...rest}>
            <Icon name='arrow left' />
            {!noTitle && (props.title || t('general.cancel'))}
        </Button>
    );
}

const YesButton = (props) => {
    const { t } = useTranslation();
    const { noTitle, ...rest } = props;
    return (
        <Button icon color='green' labelPosition={ noTitle ? null : 'left' } size='small' type='submit' {...rest}>
            <Icon name='check' />
            {!noTitle && (props.title || t('general.yes'))}
        </Button>
    );
}

const NoButton = (props) => {
    const { t } = useTranslation();
    const { noTitle, ...rest } = props;
    return (
        <Button icon color='red' labelPosition={ noTitle ? null : 'left' } size='small' type='reset' {...rest}>
            <Icon name='ban' />
            {!noTitle && (props.title || t('general.no'))}
        </Button>
    );
}

const OpenButton = (props) => {
    const { t } = useTranslation();
    const { noTitle, ...rest } = props;
    return (
        <Button icon color='green' labelPosition={ noTitle ? null : 'left' } size='small' type='reset' {...rest}>
            <Icon name='arrow right' />
            {!noTitle && (props.title || t('general.open'))}
        </Button>
    );
}

const CloseButton = (props) => {
    const { t } = useTranslation();
    const { noTitle, ...rest } = props;
    return (
        <Button icon color='red' labelPosition={ noTitle ? null : 'left' } size='small' type='reset' {...rest}>
            <Icon name='cancel' />
            {!noTitle && (props.title || t('general.close'))}
        </Button>
    );
}

const ApproveButton = (props) => {
    const { t } = useTranslation();
    const { noTitle, ...rest } = props;
    return (
        <Button icon color='green' labelPosition={ noTitle ? null : 'left' } size='small' type='submit' {...rest}>
            <Icon name='check' />
            {!noTitle && (props.title || t('general.approve'))}
        </Button>
    );
}

const FullScreenButton = (props) => {
    const { t } = useTranslation();
    const { noTitle, ...rest } = props;
    return (
        <Button icon color='green' labelPosition={ noTitle ? null : 'left' } size='small' type='submit' {...rest} onClick={props.onClick}>
            {props.fullscreen ?
                    <>
                        <Icon name='compress' />
                        {!noTitle && (props.title || t('general.compress'))}
                    </>
                :
                    <>
                        <Icon name='expand' />
                        {!noTitle && (props.title || t('general.expand'))}
                    </>
            }
        </Button>
    );    
}

const ReportButton = (props) => {
    const { t } = useTranslation();
    const { noTitle, ...rest } = props;
    return (
        <Button icon color='green' labelPosition={ noTitle ? null : 'left' } size='small' type='submit' {...rest} >
            <Icon name='chart bar outline' />
            {!noTitle && (props.title || t('general.report'))}
        </Button>
    );
}

const CreatePDFButton = (props) => {
    const { t } = useTranslation();
    const { noTitle, ...rest } = props;
    return (
        <Button icon color='green' labelPosition={ noTitle ? null : 'left' } size='small' type='submit' {...rest} >
            <Icon name='file pdf outline' />
            {!noTitle && (props.title || t('general.createPdf'))}
        </Button>
    );
}

const CreateCSVButton = (props) => {
    const { t } = useTranslation();
    const { noTitle, ...rest } = props;
    return (
        <Button icon color='green' labelPosition={ noTitle ? null : 'left' } size='small' type='submit' {...rest} >
            <Icon name='file alternate outline' />
            {!noTitle && (props.title || t('general.createCsv'))}
        </Button>
    );
}

const DownloadButton = (props) => {
    const { t } = useTranslation();
    const { noTitle, ...rest } = props;
    return (
        <Button icon color='green' labelPosition={ noTitle ? null : 'left' } size='small' type='submit' {...rest}>
            <Icon name='download' />
            {!noTitle && (props.title || t('general.download'))}
        </Button>
    );
}

const UploadButton = (props) => {
    const { t } = useTranslation();
    const { noTitle, ...rest } = props;
    return (
        <Button icon color='green' labelPosition={ noTitle ? null : 'left' } size='small' type='submit' {...rest}>
            <Icon name='upload' />
            {!noTitle && (props.title || t('general.upload'))}
        </Button>
    );
}

const UserButton = (props) => {
    const { t } = useTranslation();
    const { noTitle, ...rest } = props;
    return (
        <Button icon color='green' labelPosition={ noTitle ? null : 'left' } size='small' type='submit' {...rest}>
            <Icon name='user' />
            {!noTitle && (props.title || t('general.user'))}
        </Button>
    );
}

const ContactButton = (props) => {
    const { t } = useTranslation();
    const { noTitle, ...rest } = props;
    return (
        <Button icon color='green' labelPosition={ noTitle ? null : 'left' } size='small' type='submit' {...rest}>
            <Icon name='user' />
            {!noTitle && (props.title || t('general.contact'))}
        </Button>
    );
}

const HelpButton = (props) => {
    const { t } = useTranslation();
    const { noTitle, ...rest } = props;
    return (
        <Button icon color='blue' labelPosition={ noTitle ? null : 'left' } size='small' type='submit' {...rest}>
            <Icon name='help' />
            {!noTitle && (props.title || t('general.help'))}
        </Button>
    );
}

const QuestionButton = (props) => {
    const { t } = useTranslation();
    const { noTitle, ...rest } = props;
    return (
        <Button icon color='green' labelPosition={ noTitle ? null : 'left' } size='small' {...rest}>
            <Icon name='question' />
            {!noTitle && (props.title || t('general.question'))}
        </Button>
    );
}

const EmailButton = (props) => {
    const { t } = useTranslation();
    const { noTitle, ...rest } = props;
    return (
        <Button icon color='green' labelPosition={ noTitle ? null : 'left' } size='small' {...rest}>
            <Icon name='mail' />
            {!noTitle && (props.title || t('general.email'))}
        </Button>
    );
}

const ToggleMenuButton = (props) => {
    const { t } = useTranslation();
    const { isOpen, noTitle, ...rest } = props;
    return (
        <Button icon color='green' labelPosition={ noTitle ? null : 'left' } size='small' type='submit' {...rest} onClick={props.onClick}>
            {isOpen ?
                    <>
                        <Icon name='dropdown' />
                        {!noTitle && (props.title || t('general.open'))}
                    </>
                :
                    <>
                        <Icon name='dropdown' />
                        {!noTitle && (props.title || t('general.close'))}
                    </>
            }
        </Button>
    );    
}

const LinkButton = (props) => {
    const { t } = useTranslation();
    const { noTitle, ...rest } = props;
    return (
        <Button icon color='green' labelPosition={ noTitle ? null : 'left' } size='small' as='a' target='_blank' {...rest} >
            <Icon name='linkify' />
            {!noTitle && (props.title || t('general.www'))}
        </Button>
    );    
}

const InfoButton = (props) => {
    return (
        <Button icon color='blue' {...props}>
            <Icon name='info' />
        </Button>
    );
}

const PrintPageButton = (props) => {
    const { t } = useTranslation();
    const { noTitle, ...rest } = props;
    return (
        <Button icon color='green' labelPosition={ noTitle ? null : 'left' } size='small' as='a' target='_blank' {...rest} >
            <Icon name='print' />
            {!noTitle && (props.title || t('general.www'))}
        </Button>
    );    
}


export {
    NewButton,
    CreateButton,
	ViewButton,
	EditButton,
	DeleteButton,
	SaveButton,
	CancelButton,
	YesButton,
    NoButton,
    OpenButton,
	CloseButton,	
	ApproveButton,
	FullScreenButton,
	ReportButton,
    CreatePDFButton,
    CreateCSVButton,
    DownloadButton,
    UploadButton,
    ContactButton,
    UserButton,
    QuestionButton,
    HelpButton,
    EmailButton,
    ToggleMenuButton,
    LinkButton,
    InfoButton,
    PrintPageButton
};

import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Divider, Button, Message, Grid, Loader } from 'semantic-ui-react';
import useReadUser from '../../Hooks/Users/useReadUser';
import useListUserServices from '../../Hooks/Users/useListUserServices';
import useListUserTypes from '../../Hooks/Users/useListUserTypes';
import useListActivityTypes from '../../Hooks/Settings/useListActivityTypes';
import { StateContext } from '../../Context/StateContext';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import SubNavigation from '../../Pages/User/SubNavigation';

const UserDetail = (props) => {

    const { t } = useTranslation();
    const { user, fetchingUser, readUser } = useReadUser();
    const { userServices, fetchingUserServices, listUserServices } = useListUserServices();
    const { userTypes, fetchingUserTypes, listUserTypes } = useListUserTypes();
    const { activityTypes, fetchingActivityTypes, listActivityTypes } = useListActivityTypes();
    const { types, profile, userDetailBackRoute } = useContext(StateContext);
    const { id } = useParams();
    const history = useHistory();

    useEffect(() => {
        (async() => {
            if(profile && (profile.IsOwner || parseInt(id) === profile.Id)){
                await readUser(id);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile]);

    useEffect(() => {
        (async () => {
            if(profile && (profile.IsOwner || parseInt(id) === profile.Id)){
                await listUserServices(id);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[profile]);

    useEffect(() => {
        (async () => {
            if(profile && (profile.IsOwner || parseInt(id) === profile.Id)){
                await listUserTypes(id);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[profile]);

     // Fetch activity types
     useEffect(() => {
        (async () => {
            if(profile && (profile.IsOwner || parseInt(id) === profile.Id)){
                await listActivityTypes();
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[profile]);

    const loadingContent = (profile.IsOwner || parseInt(id) === profile.Id) && (fetchingUser || fetchingUserServices || fetchingUserTypes || fetchingActivityTypes);
    const userShiftCount = user && user.data ? (user.data.UserShiftCount || 0) + (user.data.UserShiftCountInPreviousSystem || 0) : 0;

    return (

        <div className='userdetail'>
            
            { loadingContent &&
                <Loader active inline='centered'/>
            }

            {/* if not owner or own profile redirect to access denied page */}
            {profile.Id && !profile.IsOwner && parseInt(id) !== profile.Id &&
                <Redirect to="/accessdenied" />
            }

            {!loadingContent &&
                <>
                {profile.IsOwner && !user.data.IsEmployee ?
                    <SubNavigation id={id} activePage='detail' />
                :
                    <h1>{t('heading.userinfo')}</h1>
                }
                <Container>
                    { user && user.data &&
                        <>
                        <Grid stackable columns={2}>
                            <Grid.Column>
                                <h2>{t('user.lastname')}</h2>
                                <div>{user.data.LastName}</div>
                            
                                <h2>{t('user.firstname')}</h2>
                                <div>{user.data.FirstName}</div>

                                <h2>{t('user.city')}</h2>
                                <div>{user.data.LocationName}</div>

                                <h2>{t('user.username')}</h2>
                                <div>{user.data.Username}</div>

                                {user.data.IsEmployee &&
                                    <div>{t('user.employee')}</div>
                                }

                                <h2>{t('user.email')}</h2>
                                <div>{user.data.EmailAddress}</div>

                                <h2>{t('user.phone')}</h2>
                                <div>{user.data.PhoneNumber}</div>

                                {user.data.IsEmployee ?
                                    <>
                                    <h2>{t('user.active')}</h2>
                                    <div>{user.data.IsActive ? t('general.yes') : t('general.no')}</div>
                                    </>
                                :
                                    <>
                                    <h2>{t('user.description')}</h2>
                                    <div>{user.data.Description}</div>

                                    <h2>{t('user.specialDiet')}</h2>
                                    <div>{user.data.SpecialDiet}</div>

                                    <h2>{t('user.startDate')} {t('user.basicCourseStartDate')}</h2>
                                    <div>{user.data.StartDate && new Date(user.data.StartDate).toLocaleDateString()}</div>
                                    </>
                                }

                            </Grid.Column>
                            <Grid.Column>
                                {!user.data.IsEmployee &&
                                    <>
                                    <h2>{t('user.remote')}</h2>
                                    <div>{user.data.IsRemoteWorker ? t('general.yes') : t('general.no')}</div>

                                    {user.data.IsRemoteWorker &&
                                        <>
                                        <h2>{t('user.mllPhoneNumber')}</h2>
                                        <div>{user.data.MllPhoneNumber}</div>
                                        <h2>{t('user.mllProperty')}</h2>
                                        <div>{user.data.MllProperty}</div>
                                        </>
                                    }

                                    <h2>{t('user.services')}</h2>
                                    {userServices.map(item =>
                                        <div key={item.Id}>{item.ServiceName}</div>
                                    )}

                                    <Grid columns={3} className='small-padding add-margin'>
                                        <Grid.Row>
                                            <Grid.Column width={4}>
                                                <h2>{t('user.channels')}</h2>
                                            </Grid.Column>
                                            <Grid.Column width={2}>
                                                {t('user.isPresent')}
                                            </Grid.Column>
                                            <Grid.Column>
                                                {t('user.isRemote')}
                                            </Grid.Column>
                                        </Grid.Row>
                                        {types.map(type => (
                                            userTypes.find(item => item.TypeId === type.Id) &&
                                            <Grid.Row>
                                                <Grid.Column width={4}>
                                                    {type.Abbreviation}
                                                </Grid.Column>
                                                <Grid.Column width={2}>
                                                    {userTypes.find(item => item.TypeId === type.Id) && userTypes.find(item => item.TypeId === type.Id).Present ?
                                                        t('general.yes')
                                                    :
                                                        t('general.no')
                                                    }
                                                </Grid.Column>
                                                <Grid.Column>
                                                    {userTypes.find(item => item.TypeId === type.Id) && userTypes.find(item => item.TypeId === type.Id).Remote ?
                                                        t('general.yes')
                                                    :
                                                        t('general.no')
                                                    }
                                                </Grid.Column>
                                            </Grid.Row>
                                        ))}
                                    </Grid>

                                    <h2>{t('user.activity')}</h2>
                                    <div>
                                    {user && user.data && user.data.UserActivityType && activityTypes && activityTypes.find(item => item.Id === user.data.UserActivityType) &&
                                        <>
                                        {user.data.ActivityChanged ?
                                            activityTypes.find(item => item.Id === user.data.UserActivityType).Name + ' (' + t('user.activitySet') + ' ' + new Date(user.data.ActivityChanged).toLocaleDateString() + ')'
                                        :
                                            activityTypes.find(item => item.Id === user.data.UserActivityType).Name
                                        }
                                        </>
                                    }
                                    </div>

                                    <h2>{t('user.active')}</h2>
                                    <div>{user.data.IsActive ? t('general.yes') : t('general.no')}</div>

                                    {user.data.KeepOnMailingList &&
                                        <h2>{t('user.onMailingList')}</h2>
                                    }

                                    {user.data.BreakStartDate &&
                                        <>
                                        <h2>{t('user.breakStartDate')}</h2>
                                        <div>{user.data.BreakStartDate && new Date(user.data.BreakStartDate).toLocaleDateString()}</div>
                                        </>
                                    }

                                    <h2>{t('user.nextOneToOne')}</h2>
                                    <div>{user.data.NextOneToOne && new Date(user.data.NextOneToOne).toLocaleDateString()}</div>
                                    </>
                                }
                            </Grid.Column>
                        </Grid>

                        {!user.data.IsEmployee &&
                            <>
                            <h2>{t('user.shiftCount')}: {userShiftCount}</h2>
                            {user.data.BadgeOfMeritEarned>0 &&
                                <Message className='align-center'>{t('user.badgeOfMeritInfo')}</Message>
                            }
                            {profile.IsOwner && 
                                <>
                                {user.data.ActivityAchieved ?
                                    <Message className='align-center' positive>{t('user.activityAchieved')}</Message>
                                :
                                    <Message className='align-center' negative>{t('user.activityNotAchieved')}</Message>
                                }
                                </>
                            }
                            </>
                        }
                        </>
                    }

                </Container>
                <Divider/>
                {profile.IsOwner && <Button onClick={() => history.push('/user/edit/' + id)}>{t('general.edit')}</Button>}
                {props.back ?
                    <Button onClick={() => history.goBack()}>{t('general.back')}</Button>
                :
                    <Button onClick={() => history.push(userDetailBackRoute || '/user/list')}>{t('general.backToUserList')}</Button>
                }
                </>
            }
        </div>
    )

}

export default UserDetail;
import react, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Divider, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import useListUsers from '../../Hooks/Users/useListUsers';
import useReadShift from '../../Hooks/Shifts/useReadShift';
import useListShiftTypes from '../../Hooks/Shifts/useListShiftTypes';
import useCreateShiftUser from '../../Hooks/ShiftUsers/useCreateShiftUser';
import { MessageContext } from '../../Context/MessageContext';
import Select from 'react-select';

const AddUserToShift = (props) => {

    const { t } = useTranslation();
    const { typeId, shiftId } = useParams();
    const { users, listUsers } = useListUsers();
    const [ personOnCall, setPersonOnCall] = useState('');
    const [ sendReminder, setSendReminder ] = useState(true);
    const { shift, readShift } = useReadShift();
    const { shiftTypes, listShiftTypes } = useListShiftTypes();
    const { createShiftUser } = useCreateShiftUser();
    const { messages, removeMessage, addMessage } = useContext(MessageContext);

    // Fetch shift
    useEffect(() => {
        (async () => {
            if(shiftId){
                await readShift(shiftId);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // Fetch shift
    useEffect(() => {
        (async () => {
            await listShiftTypes(shiftId);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    let date = null;
    let time = null;
    if(shift.StartTime && shift.EndTime) {
        date = new Date(shift.StartTime)
        time = date.getHours() + '.' + date.getMinutes().toString().padStart(2,'0')
        date = new Date(shift.EndTime)
        time += '-' + date.getHours() + '.' + date.getMinutes().toString().padStart(2,'0')
    }

    const shiftType = shiftTypes.find(item => item.Id === parseInt(typeId))
    
    // Fetch users
    useEffect(() => {
        (async () => {
            await listUsers();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const instructor =  users.find(item => item.Id === shift.InstructorId)
    let instructorName = ''
    if(instructor){
        instructorName = instructor.FirstName + ' ' + instructor.LastName
    }

    let persons = []
    users.map(item => {
        // show only active users
        if(item.IsActive){
            const person = {
                key: item.Id,
                label: item.LastName + ' ' + item.FirstName,
                value: item.Id
            }
            persons.push(person);
        }
        return item;
    })

    const handleSave = async () => {
        messages.map(msg => removeMessage(msg.id));

        //validate
        if(personOnCall === '') {
            addMessage({type: "error", header: t('message.fillInRequiredInformation'), body: t('message.requiredMarkedWithAsterisk')})
            window.scrollTo(0, 0)
        } else {
            //create shift user data
            let data = {
                userId: personOnCall,
                enrollmentDate: new Date().toISOString(),
                shiftTypeId: parseInt(typeId),
                sendShiftReminder: sendReminder
            }

            //save shift
            let result = null;
            result = await createShiftUser(shiftId,data);

            if(result.status===201){
                props.history.goBack();
            } else if(result.data.status===400 && result.data.code===-2){
                addMessage({type: "error", header: t('message.userAlreadyRegistered'), body: t('message.removeRegistrationFirst')})
                window.scrollTo(0, 0)
            }
        }
    }

    return (
        <div>
            <h1>{t('heading.addPersonToShift')}</h1>

            <h2>{t('shift.timing')}</h2>
            <div>{shift.Date && new Date(shift.Date).toLocaleDateString()} {time && t('general.at') + ' ' + time}</div>

            <h2>{t('shift.type')}</h2>
            <div>{shiftType && shiftType.TypeName}</div>

            <h2>{t('shift.location')}</h2>
            <div>{shift.LocationName}</div>

            <h2>{t('shift.supervisor')}</h2>
            <div>{instructorName}</div>

            <h1>{t('heading.personOnCall')}</h1>
            <Form>
                <div className="field"><label>{t('shift.selectPerson')}<sup><i className='icon asterisk'></i></sup></label>
                <Select 
                    value={persons.filter(item => item.value === personOnCall)}
                    onChange={person => setPersonOnCall(person.value)}
                    label={t('shift.selectPerson')}
                    options={persons}
                    placeholder={t('user.searchWithLastnameOrFirstname')}
                    menuPlacement='top'
                />
                </div>
                <Form.Checkbox
                    checked={sendReminder}
                    onChange={(e) => setSendReminder(!sendReminder)}
                    label={t('shift.sendReminderToUser')}
                />
            </Form>

            <Divider/>
            <Button onClick={() => handleSave()}>{t('shift.addPerson')}</Button>
            <Button onClick={() => props.history.goBack()}>{t('general.cancel')}</Button>
        </div>
    )
}

export default AddUserToShift;
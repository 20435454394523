import React, { useContext } from 'react';
import SubNavigation from '../../Layout/SubNavigation';
import { Form, Grid, Segment, Container, Divider, Button, Radio, Checkbox } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Question from '../../Reusable/Question';
import { StateContext } from '../../Context/StateContext';

function SpecificInformation(props) {

  const { t } = useTranslation();

  const { globalState, contactDataOK, saveContactData, highlightUnfilledRequiredFields } = useContext(StateContext);
  let questions = []
  if(globalState.service.type === 'vpn') questions = globalState.settings.find(item => item.type === 'vpn') || [];
  if(globalState.service.type === 'lnpn') questions = globalState.settings.find(item => item.type === 'lnpn') || [];

  //console.log(globalState.subjects);

  const setContactPrimaryPurpose = (obj) => {
    let newObj = {};
    if(globalState.contactPrimaryPurpose.find(item => item.ref === obj.ref)){
      // uncheck -> remove item from array
      newObj = globalState.contactPrimaryPurpose.filter(item => item.ref !== obj.ref)
    } else {
      //check -> add item to array
      newObj = globalState.contactPrimaryPurpose.concat(obj)
    }
    globalState.setContactPrimaryPurpose(newObj)
  }

  const setSubjectsRelatedToMedia = (value) => {
    const newObj = [...globalState.subjects];
    const index = newObj.findIndex(item => item.ref === value);
    newObj[index].isRelatedToMedia=!newObj[index].isRelatedToMedia;
    globalState.setSubjects(newObj);
  }

  const descriptionRequired = () =>  {
    //description is required if contact is related to media or certain subjects are selected
    if(globalState.contactRelatedToMedia.ref==='yes') return true;
    if(globalState.subjects.filter(item => item.descriptionRequired === true).length>0) return true;
    return false;
  }

  const descriptionSubtitle = () =>  {
    //description subtitle is formed based on other selections on form
    let first = true
    let subtitle = ""
    let filteredSubjects = globalState.subjects.filter(item => item.descriptionRequired === true)
    if(filteredSubjects.length>0) subtitle=t('heading.selectedSubjects');
    filteredSubjects.map(item => {
      first ? first=false : subtitle += ',';
      subtitle += ' ' + item.name.fi;
      return item;
    });
    if(filteredSubjects.length>0) subtitle += '. ' + t('heading.describeSubjects') + ' ';
    if(globalState.contactRelatedToMedia.ref==='yes') subtitle += t('heading.describeMedia');
    return subtitle;
  }

  return (
    <div>
      <SubNavigation activePage='specificinformation' />
      <Form>
        <Segment className='title'>
          <h1>{t('contact.specificInformation')}</h1>
        </Segment>
        <Grid stackable columns={2}>

          <Grid.Column>
            <Segment>
              <h1 className={highlightUnfilledRequiredFields && !globalState.primarySubject ? 'highlight' : undefined}>{questions.primarySubject.name.fi}{questions.primarySubject.required && <sup><i className='icon asterisk'></i></sup>}</h1>
              {globalState.subjects.map((subject,index)=>
                <Form.Field
                  key={index}
                  control={Radio}
                  label={subject.theme.name.fi + ': ' + subject.name.fi}
                  value={subject.ref}
                  checked={globalState.primarySubject.ref===subject.ref}
                  onChange={(e,{value}) => {
                    const x = globalState.subjects.find(item => item.ref === value)
                    globalState.setPrimarySubject({ ref: x.ref, name: { fi: x.name.fi }})
                  }}
                />
              )}

              <Question
                data={questions.contactRelatedToMedia || []} 
                selectedValue={globalState.contactRelatedToMedia.ref} 
                setSelectedValue={val => {
                  const x = questions.contactRelatedToMedia.options.find(s => s.ref === val)
                  globalState.setContactRelatedToMedia(x)
                }}
              />

              {globalState.contactRelatedToMedia.ref === 'yes' &&
                <>
                <h1 className={highlightUnfilledRequiredFields && globalState.subjects.filter(item => item.isRelatedToMedia===true).length===0 ? 'highlight' : undefined}>{questions.subjectsRelatedToMedia.name.fi}{questions.subjectsRelatedToMedia.required && <sup><i className='icon asterisk'></i></sup>}</h1>
                {globalState.subjects.map((subject,index)=>
                  <Form.Field
                    key={index}
                    control={Checkbox}
                    label={subject.theme.name.fi + ': ' + subject.name.fi}
                    value={subject.ref}
                    checked={subject.isRelatedToMedia}
                    onChange={(e,{value}) => setSubjectsRelatedToMedia(value)}
                  />
                )}
                </>
              }

            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <Question
                data={questions.contactDescription || []} 
                selectedValue={globalState.contactDescription} 
                setSelectedValue={globalState.setContactDescription}
                required={descriptionRequired()}
                subtitle={descriptionSubtitle()}
              />
              <Question
                data={questions.contactPrimaryPurpose || []} 
                selectedValue={globalState.contactPrimaryPurpose} 
                setSelectedValue={val => {
                  const x = questions.contactPrimaryPurpose.options.find(s => s.ref === val)
                  setContactPrimaryPurpose(x)
                }}
              />
            </Segment>
          </Grid.Column>

        </Grid>
        
        <Container>
            <Divider/>
            {contactDataOK().result ?
              <Button floated='right' onClick={() => saveContactData(props.history)}>{t('general.savecontact')}</Button>
            :
              <Button floated='right' onClick={() => props.history.push("/contact/referral")}>{t('general.nextstep')}</Button>
            }
        </Container>
      </Form>
    </div>
  );
}

export default SpecificInformation;

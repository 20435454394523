import React from 'react';
//import { NavLink } from 'react-router-dom';
//import { Container } from 'semantic-ui-react';

const Footer = (props) => {

    return (
        <footer className="app-footer">

        </footer>
    );

}

export default Footer;

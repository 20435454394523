import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, Link } from "react-router-dom";
import { Table, Pagination, Icon, Button } from 'semantic-ui-react';
import { ViewButton } from '../Buttons/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/pro-duotone-svg-icons';
import Utils from '../../Utils/utils';
import './PagedTable.css'

/** Combination of Semantic UI Table and Pagination
 * @param {array} header - Translated strings of header cells
 * @param {array} content - Table content as array of objects 
 * @param {array} contentKeys - Array of objects containing keys, actions and type of content fields rendered to table
 * @param {string} tableprops - Semantic UI table properties passed to component
 */
function PagedTable({header, content, contentKeys, pageSize = 10, resetPage, collapsable = false, showPagingOnTop, ...tableprops}) {

    const [ activePage, setActivePage ] = useState(0)
    const [ sortBy, setSortBy ] = useState({key: null, asc: true, type: 'string'})
    const [ sortedContent, setSortedContent ] = useState(content) 

    const start = activePage * pageSize;
    const end = parseInt(start) + parseInt(pageSize)
    const pagedContent = sortedContent.slice(start, end)

    function sortTableData(contentKey) {
        if (contentKey?.type !== 'linkButton' && content.length > 1) {
            setSortBy({
                key: contentKey?.key, 
                asc: sortBy.key === contentKey?.key ? !sortBy.asc : true,
                type: contentKey?.sortType || 
                    (contentKey?.type === 'date' && 'date') || 
                    'string'
            })
        }
    }

    useEffect(() => {
        const sortContent = (content) => {
            switch(sortBy.type) {
                case 'int':
                    return Utils.sortByPropertyAsInt(content, sortBy.key, sortBy.asc)
                case 'date':
                    return Utils.sortByDate(content, sortBy.key, sortBy.asc)
                default:
                    return Utils.sortByProperty(content, sortBy.key, sortBy.asc)
            }    
        }  
        setSortedContent(sortBy.key ? sortContent(content) : content);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortBy, content])

    useEffect(() => {
        setActivePage(0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetPage])

    return (
        <>
            {content.length > pageSize && showPagingOnTop &&
                <div className='pagination-container top'>
                    <Pagination 
                        defaultActivePage={activePage + 1} 
                        totalPages={Math.ceil(content.length / pageSize)} 
                        firstItem={null}
                        lastItem={null}
                        size='small'
                        ellipsisItem={window.innerWidth > 767 ? undefined : null}
                        onPageChange={(e, {activePage}) => setActivePage(activePage - 1)}
                    /> 
                </div>
            }
            <div className='table-wrapper'>
                <Table {...tableprops}>
                    <Table.Header>
                        <Table.Row>
                            { header && header.map((item, i) =>
                                <Fragment key={i}>
                                    {!contentKeys[i]?.hideCollapsed &&
                                        <Table.HeaderCell 
                                            className='sortable-table'
                                            width={contentKeys[i].cellWidth}
                                            onClick={() => sortTableData(contentKeys[i])}
                                        >
                                            {item}
                                            {sortBy.key === contentKeys[i].key && sortBy.asc && 
                                                <Icon name='caret up' />
                                            }
                                            {sortBy.key === contentKeys[i].key && !sortBy.asc && 
                                                <Icon name='caret down' />
                                            }
                                        </Table.HeaderCell>
                                    }
                                </Fragment>
                            )} 
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {pagedContent.map((row, i) => 
                            <PagedTableRow 
                                key={i}
                                header={header} 
                                row={row} 
                                contentKeys={contentKeys} 
                                collapsable={collapsable} 
                            />
                        )}
                    </Table.Body>
                </Table>
            </div>
            {content.length > pageSize && 
                <div className='pagination-container'>
                    <Pagination 
                        defaultActivePage={activePage + 1} 
                        totalPages={Math.ceil(content.length / pageSize)} 
                        firstItem={null}
                        lastItem={null}
                        size='small'
                        ellipsisItem={window.innerWidth > 767 ? undefined : null}
                        onPageChange={(e, {activePage}) => setActivePage(activePage - 1)}
                    /> 
                </div>
            }
        </>
    )
}

/** CellKeys:
 *      cellWidth:          Column width
 *
 *      key:                Displayed object key (Name, Id, Phone...)
 *      type:               Type of the cell (link, date, linkButton...)
 *      target:             Target url ("/contact/view/")
 *      targetKey:          Target url parameter key (Id, FileName...) this is added end of the url/target ("/contact/view/{Id}")
 *      action:             Function
 *      cellAlign:          Cell text align (center)
 *      hideCollapsed:      Hide cell if collapsed? (true / false)
 *      urlKey:             Key which defined url , urlKey: 'viewUrl' when viewUrl: '/contracts/business/view/'
 *
 *      Example objects with types:
 *      Link:               { key: 'title', type: 'link', target: '/contracts/business/view/', targetKey: 'id'}
 *      Url:                { key: 'title', type: 'url', urlKey:'viewUrl', targetKey: 'id'}
 *      External link: 
 *      Link button: 
 *      Url button:         { key: 'id', type: 'urlButton', urlKey:'viewUrl', cellAlign: 'left', targetKey: 'id' }
 *      Date:               { key: 'created', type: 'date' }
 *      Boolean: 
 *      Action: 
 *      Two keys: 
 */
function PagedTableRow({header, row, contentKeys, collapsable}) {

    const history = useHistory();

    const [ collapsed, setCollapsed ] = useState(collapsable ? true : false)

    const cellContent = (row, cellKey) => {
        switch(cellKey.type) {
            case 'link':
                return <Link to={`${cellKey.target}${row[cellKey.targetKey]}`}>{row[cellKey.key]}</Link>;
            case 'url':
                return <Link to={`${row[cellKey.urlKey]}${row[cellKey.targetKey]}`}>{row[cellKey.key]}</Link>;
            case 'externalLink':
                return <a href={`${cellKey.target}${row[cellKey.targetKey]}`} target='_blank' rel="noopener noreferrer">{row[cellKey.key]}</a>;                
            case 'linkButton':
                return <ViewButton onClick={() => history.push(`${cellKey.target}${row[cellKey.targetKey]}`)} />;
            case 'urlButton':
                return <ViewButton onClick={() => history.push(`${row[cellKey.urlKey]}${row[cellKey.targetKey]}`)} />;
            case 'date':
                return row[cellKey.key] ? new Date(row[cellKey.key]).toLocaleDateString() : '';
            case 'time':
                return new Date(row[cellKey.key]).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            case "datetime":
                return new Date(row[cellKey.key]).toLocaleDateString() + ' ' + new Date(row[cellKey.key]).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            case 'boolean':
                return row[cellKey.key] ? 'True' : 'False';
            case 'action':
                return <Link to='#' onClick={ (ev) => { ev.preventDefault(); cellKey.action(ev, row, cellKey); } } >{row[cellKey.key]}</Link>
            case 'actionButton':
                if(row[cellKey.disabled]){
                    return <Button disabled>{row[cellKey.key]}</Button>
                } else {
                    return <Button onClick={ (ev) => { ev.preventDefault(); cellKey.action(ev, row, cellKey); } } >{row[cellKey.key]}</Button>
                }
            case 'keyWithActionButton':
                if(row[cellKey.disabled]){
                    return <><div>{row[cellKey.key2]}</div><Button disabled>{row[cellKey.key]}</Button></>
                } else {
                    return <><div>{row[cellKey.key2]}</div><Button onClick={ (ev) => { ev.preventDefault(); cellKey.action(ev, row, cellKey); } } >{row[cellKey.key]}</Button></>
                }
            case 'twokeys':
                return `${row[cellKey.key]} ${row[cellKey.key2] ? row[cellKey.key2] : ''}`
            case "array":
                return row[cellKey.key].map((item,index) => <div key={index}>{item}</div>)
            case 'icons':
                return (
                    <>
                    <Icon name={row[cellKey.key]} className={row[cellKey.key2]} size='large'/>
                    {row[cellKey.key3] && <Icon name={row[cellKey.key]} className={row[cellKey.key3]} size='large'/>}
                    </>
                )
            case 'firstChars':
                if(row[cellKey.key] && row[cellKey.key].length>cellKey.limit){
                    return row[cellKey.key] ? `${row[cellKey.key].substring(0,cellKey.limit)}...` : '';
                } else {
                    return row[cellKey.key];
                }
            default: 
                return row[cellKey.key];
        }
    }

    return (
        <Fragment>
            {!collapsable || collapsed ?
                <Table.Row 
                    className={collapsable && collapsed ? 'collapsable collapsed' : ''}
                >
                    {contentKeys.map((cellKey, n) =>
                        <Fragment key={n}> 
                            {!cellKey.hideCollapsed &&
                            <Table.Cell 
                                textAlign={cellKey.cellAlign}
                                onClick={() => collapsable && n === 0 && setCollapsed(!collapsed)}
                                
                            >
                                {collapsable && n === 0 && <FontAwesomeIcon icon={faChevronCircleDown}/> }
                                {cellContent(row, cellKey)}
                            </Table.Cell>
                            }
                        </Fragment>     
                    )}  
                </Table.Row>
            :
                <Table.Row 
                    className={collapsable && !collapsed ? 'collapsable open' : ''}
                >
                    <Table.Cell colSpan={contentKeys.length}>
                    {contentKeys.map((cellKey, i) =>
                        <Fragment key={i}>
                            {collapsable && i === 0 && 
                                <div
                                    onClick={() => collapsable && i === 0 && setCollapsed(!collapsed)}
                                >
                                    <FontAwesomeIcon icon={faChevronCircleUp} />
                                </div>
                            } 
                            <div>
                                <span className='header'>
                                    {`${header[i]}: `}
                                </span>
                                {cellContent(row, cellKey)}
                            </div>
                        </Fragment>     
                    )}
                    </Table.Cell>  
                </Table.Row>
            }
        </Fragment>
    )
}

export default PagedTable;

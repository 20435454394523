import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PagedTable from '../../Reusable/Lists/PagedTable';
import { Divider, Button, Form, Grid } from 'semantic-ui-react';
import useListUsers from '../../Hooks/Users/useListUsers';
import useListActivityTypes from '../../Hooks/Settings/useListActivityTypes';
import { StateContext } from '../../Context/StateContext';
import { MessageContext } from '../../Context/MessageContext';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Select from 'react-select';
import { Link } from 'react-router-dom';

const UserList = (props) => {

    const { t } = useTranslation();
    const { globalState, locations, services, types, setUserDetailBackRoute } = useContext(StateContext);
    const { addMessage } = useContext(MessageContext);
    const { users, listUsers } = useListUsers();
    const { activityTypes, listActivityTypes } = useListActivityTypes();

    setUserDetailBackRoute('/user/list');

    let locationOptions = [{label: t('user.location-options.all'), value: ''}]
    locations.map(l => {
        if(l.Reference!=='remote'){
            const tmp = {
                label: l.Name,
                value: l.Id
            }
            locationOptions.push(tmp)
        }
        return l;
    })

    let serviceOptions = []
    services.map(s => {
        const option = {
            label: s.Name,
            value: s.Id
        }
        serviceOptions.push(option);
        return s;
    })

    let typeOptions = []
    types.filter(i => i.ServiceId).map(i => {
        const option = {
            label: i.Abbreviation,
            value: i.Id
        }
        typeOptions.push(option);
        return t;
    })

    // Fetch activity types
    useEffect(() => {
        (async () => {
            await listActivityTypes();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    let activityTypeOptions = [
        {label: t('general.all'), value: ''},
        {label: t('user.mailing-list'), value: 'mailing'},
        {label: t('user.all-active'), value: 'active'}
    ]
    activityTypes.map(i => {
        const option = {
            label: i.Name,
            value: i.Id
        }
        activityTypeOptions.push(option);
        return t;
    })

    // Fetch users
    useEffect(() => {
        (async () => {
            await listUsers();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    
    // add view url and city name to users
    useEffect(() => {
        users.map(user => {
            user.viewUrl = 'detail/';
            user.link=user.LastName;
            if(user.ActivityAchieved){
                user.iconName = "checkmark"
                user.iconColor = "green"
            } else {
                user.iconName = "checkmark"
                user.iconColor = "red"
            }
            return user;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[users,activityTypes]);
    
    // filter users by selected filters
    let filteredUsers = users;
    // name
    if(globalState.userName){
        filteredUsers = users.filter(user => user.LastName.toLowerCase().indexOf(globalState.userName.toLowerCase())===0 || user.FirstName.toLowerCase().indexOf(globalState.userName.toLowerCase())===0)
    };
    // location
    if(globalState.userLocation.value && globalState.userLocation.value!==''){
        filteredUsers = filteredUsers.filter(user => user.LocationId===globalState.userLocation.value);
    };
    // service
    if(globalState.userService && globalState.userService.length>0){
        filteredUsers = filteredUsers.filter(user => 
            user.UserServices && user.UserServices.some(item => globalState.userService.find(us => us.value === item.ServiceId) !== undefined)
        );
    };
    // types
    if(globalState.userType && globalState.userType.length>0){
        filteredUsers = filteredUsers.filter(user => 
            user.UserTypes && user.UserTypes.some(item => globalState.userType.find(ut => ut.value === item.TypeId) !== undefined && item.Present)
        );
    };
    // types remote
    if(globalState.userRemote && globalState.userRemote.length>0){
        filteredUsers = filteredUsers.filter(user => 
            user.UserTypes && user.UserTypes.some(item => globalState.userRemote.find(ur => ur.value === item.TypeId) !== undefined && item.Remote)
        );
    };
    // activity
    if(globalState.userActivityType.value && globalState.userActivityType.value!==''){
        if(globalState.userActivityType.value==='mailing'){
            // mailing list -> activity type value is greater than zero or user on break and on mailing list
            filteredUsers = filteredUsers.filter(user => 
                activityTypes.filter(item => item.Value>0).find(item => item.Id === user.ActivityTypeId) !== undefined ||
                (user.ActivityTypeId===activityTypes.find(at => at.RefName==='onBreak').Id && user.KeepOnMailingList)
            );
        } else if(globalState.userActivityType.value==='active'){
            // all active -> activity type value is greater than zero
            filteredUsers = filteredUsers.filter(user => 
                activityTypes.filter(item => item.Value>0).find(item => item.Id === user.ActivityTypeId) !== undefined
            );
        } else {
            filteredUsers = filteredUsers.filter(user => user.ActivityTypeId===globalState.userActivityType.value);
        }
    };

    // email addresses for copying to clipboard
    let emailList = ""
    filteredUsers.filter(item => item.EmailAddress).map(item => {
        emailList+=item.EmailAddress + ';'
        return item;
    });

    return (
        <div className="userlist min600">
            <Grid stackable columns={2} className="add-margin">
                <Grid.Column><h1>{t('heading.users')}</h1></Grid.Column>
                <Grid.Column><Button floated='right' onClick={() => props.history.push('/user/new')}>{t('user.add')}</Button></Grid.Column>
            </Grid>

            <div className='user-search'>
                <Form>
                    <Form.Input
                        value={globalState.userName} 
                        onChange={(e) => globalState.setUserName(e.target.value)} 
                        placeholder={t('user.searchWithLastnameOrFirstname')}
                    />
                    <Grid stackable columns={5}>
                        <Grid.Column>
                            <div className='field'>
                                <label>{t('user.city')}</label>
                                <Select
                                    options={locationOptions}
                                    onChange={globalState.setUserLocation}
                                    placeholder={t('user.location-options.all')}
                                    value={globalState.userLocation}
                                />
                            </div>
                        </Grid.Column>
                        <Grid.Column>
                            <div className='field'>
                                <label>{t('user.service')} {t('general.someOfSelected')}</label>
                                <Select
                                    options={serviceOptions}
                                    onChange={globalState.setUserService}
                                    placeholder={t('user.service-options.all')}
                                    isMulti={true}
                                    value={globalState.userService}
                                />
                            </div>
                        </Grid.Column>
                        <Grid.Column>
                            <div className='field'>
                                <label>{t('user.channel')} {t('general.someOfSelected')}</label>
                                <Select
                                    options={typeOptions}
                                    onChange={globalState.setUserType}
                                    placeholder={t('user.channel-options.all')}
                                    isMulti={true}
                                    value={globalState.userType}
                                />
                            </div>
                        </Grid.Column>
                        <Grid.Column>
                            <div className='field'>
                                <label>{t('user.remoteChannel')} {t('general.someOfSelected')}</label>
                                <Select
                                    options={typeOptions}
                                    onChange={globalState.setUserRemote}
                                    placeholder={t('user.channel-options.all')}
                                    isMulti={true}
                                    value={globalState.userRemote}
                                />
                            </div>
                        </Grid.Column>
                        <Grid.Column>
                            <div className='field'>
                                <label>{t('user.activity-mailing')}</label>
                                <Select
                                    options={activityTypeOptions}
                                    onChange={globalState.setUserActivityType}
                                    placeholder={t('general.all')}
                                    value={globalState.userActivityType}
                                />
                            </div>
                        </Grid.Column>
                    </Grid>
                </Form>
            </div>
                
            <PagedTable unstackable striped celled compact='very'
                header={[
                    t('user.lastname'),
                    t('user.firstname'),
                    t('user.username'),
                    t('user.city'),
                    t('user.activity'),
                    ''
                ]}
                content={filteredUsers}
                contentKeys={[
                    {key: 'link', type: 'url', urlKey:'viewUrl', cellAlign: 'left', targetKey: 'Id'},
                    {key: 'FirstName'},
                    {key: 'Username'},
                    {key: 'LocationName'},
                    {key: 'ActivityTypeName'},
                    {key: 'iconName', key2: 'iconColor', type: 'icons'},
                ]}
                pageSize='20'
                resetPage={globalState.userName || globalState.userLocation.value || globalState.userService.value || globalState.userType.value || globalState.userActivityType || globalState.userRemote.value }
            />

            <Divider />

            <CopyToClipboard 
                text={emailList}
                onCopy={() => {addMessage({type: "success", header: t('general.copied'), body: t('info.emailsCopiedToClipboard')});window.scrollTo(0,0)}}
            >
                <Button>{t('user.copyToClipboard')}</Button>
            </CopyToClipboard>
            <Link className='float-right' to='/user/badgeofmerit/list'>{t('badgeOfMerit.showBOMUsers')}</Link>
            
                
        </div>
    );

}

export default UserList;

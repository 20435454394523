import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Divider, Button, Message, Grid } from 'semantic-ui-react';
import useReadUser from '../../Hooks/Users/useReadUser';
import useReadOtherParticipation from '../../Hooks/Users/OtherParticipation/useReadOtherParticipation';
import useDeleteOtherParticipation from '../../Hooks/Users/OtherParticipation/useDeleteOtherParticipation';
import { useParams } from 'react-router-dom';
import { YesNoModal } from '../../Reusable/Modals/GeneralModal';

const OtherParticipationDetail = (props) => {

    const { t } = useTranslation();
    const { otherParticipation, readOtherParticipation } = useReadOtherParticipation();
    const { user, readUser } = useReadUser();
    const { deleteOtherParticipation } = useDeleteOtherParticipation();
    const { userId, participationId } = useParams();
    const [ modalOpen, setModalOpen ] = useState(false);

    //console.log(otherParticipation)

    useEffect(() => {
        (async() => {
            await readOtherParticipation(userId,participationId);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (async() => {
            if(otherParticipation && otherParticipation.data && otherParticipation.data.UserId){
                await readUser(otherParticipation.data.UserId);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otherParticipation]);

    const delOtherParticipation = () => {
        (async () => {
            let result = null;
            result = await deleteOtherParticipation(userId,participationId);
            if(result.status === 204){
                props.history.goBack();
            }
        })();
    }

    return (
        <div>
            <YesNoModal 
                type='delete'
                header={t('modal.confirmDelete')}
                text={t('modal.confirmDeleteOtherParticipation')}
                method={delOtherParticipation}
                open={modalOpen}
                setOpen={setModalOpen}
            />
           <Container>
                <h1>{t('heading.otherParticipation')}</h1>
                { otherParticipation && otherParticipation.data &&
                    <>
                    <h2>{t('heading.user')}</h2>
                    <div>{user.data && user.data.FirstName} {user.data && user.data.LastName}</div>

                    <h2>{t('otherParticipation.date')}</h2>
                    <div>{new Date(otherParticipation.data.Date).toLocaleDateString()}</div>

                    <h2>{t('otherParticipation.type')}</h2>
                    <div>{otherParticipation.data.ParticipationTypeName}</div>

                    <h2>{t('otherParticipation.description')}</h2>
                    <div>{otherParticipation.data.Description}</div>
                    </>
                }

            </Container>
            <Divider/>
            <Button onClick={() => props.history.push('/user/' + userId + '/participation/edit/' + participationId)}>{t('general.edit')}</Button>
            <Button onClick={() => setModalOpen(true)}>{t('general.delete')}</Button>
            <Button onClick={() => props.history.goBack()}>{t('general.back')}</Button>
        </div>
    )

}

export default OtherParticipationDetail;
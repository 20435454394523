import { useState } from 'react';
import Axios from 'axios';
import { useMsal } from "@azure/msal-react";
import Config from '../../Config/Config';

const useListAforisms = () => {

    const [ aforisms, setAforisms ] = useState([]);
    const rootPath = Config.api.rootPath;
    const { instance } = useMsal();

    /** List all settings */
    const listAforisms = async () => {
        try {
            
            const activeAccount = instance.getActiveAccount();
            const accounts = instance.getAllAccounts();
            
            if (!activeAccount && accounts.length === 0) return;

            const request = {
                scopes: Config.api.scopes,
                account: activeAccount || accounts[0]
            }

            const authResult = await instance.acquireTokenSilent(request);

            const options = {
                "headers": {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + authResult.accessToken
                }
            }

            const result = await Axios.get(rootPath + "/api/v1/aforisms", options);

            if (result && result.status && result.status === 200 && result.data) setAforisms(result.data);

            return result;

        } catch (error) {
            return error;
        }
    }

    return { aforisms, listAforisms };

}

export default useListAforisms;
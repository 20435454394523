import React from 'react';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import './styles.css' ;

const Loading = ({text}) => {
    return (
        <div id='loader'>
            <Segment>
                <Dimmer active>
                    <Loader size='massive'>{text}</Loader>
                </Dimmer>
            </Segment>
        </div>
    );
}

export default Loading;
import React, { useContext, useEffect, useState } from 'react';
import { Form, Button, Container, Divider, Grid, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Question from '../Reusable/Question';
import { StateContext } from './../Context/StateContext';
import { MessageContext } from '../Context/MessageContext';
import useListAforisms from '../Hooks/Aforisms/useListAforisms';
import Loading from '../Layout/Loading';

const SelectService = (props) => {

  const { t } = useTranslation();

  const { addMessage, removeMessage, messages } = useContext(MessageContext);
  const { globalState, clearContactData, profile, fetchingProfile } = useContext(StateContext);

  const [ service, setService ] = useState(globalState.service);
  const [ channel, setChannel ] = useState(globalState.channel);
  const [ aforism, setAforism ] = useState();

  let general = globalState.settings.find(item => item.type === 'general') || [];
  
  const { aforisms, listAforisms } = useListAforisms();

  useEffect(() => {
    (async () => {
      await listAforisms();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAforism(aforisms[Math.floor(Math.random() * aforisms.length)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aforisms]); 

  useEffect(()=> {
    globalState.setService('');
    globalState.setChannel('');
    clearContactData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async () => {
    messages.map(item => removeMessage(item.id));
    if(service.ref && channel.ref) {
      globalState.setService(service);
      globalState.setChannel(channel);
      props.history.push('/contact/contacttype');
    } else {
      addMessage({type: "error", header: t('message.fillInRequiredInformation'), body: t('message.requiredMarkedWithAsterisk')})
    }
  }

  if(!fetchingProfile && profile.length===0){
    return (<div><h1>{t('errors.userNotFound')}</h1></div>)
  }

  
  return (
    <div>
      {(globalState.settings.length === 0 || !aforism || !globalState.name) &&
        <Loading />
      }

      <Container className='greeting'>
        <h1>{t('general.greeting')}</h1>
      </Container>
      {aforism &&
        <Container className='aforism'>
          "{aforism.text}" - {aforism.author}
        </Container>
      }
      <Divider/>
      <Form onSubmit={onSubmit}>
        <Grid stackable columns={2}>
          <Grid.Column>
            <Segment>
              <Question
                data={general.service || []} 
                selectedValue={service.ref ? service.ref : ''} 
                setSelectedValue={val => {
                  const x = general.service.options.find(s => s.ref === val);
                  setService(x);
                }}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <Question 
                data={general.channel || []} 
                selectedValue={channel.ref ? channel.ref : ''} 
                setSelectedValue={val => {
                  const x = general.channel.options.find(s => s.ref === val);
                  setChannel(x);
                }} 
              />
            </Segment>
          </Grid.Column>
        </Grid>
          
        <Container>
          <Divider/>
          <a target='_blank' href='https://mllry.sharepoint.com/teams/msteams_088f12/SitePages/Home.aspx'>{t('general.homepage')}</a>
          <Button floated='right' type='submit'>
              {t('general.ready')}
          </Button>
        </Container>
            
      </Form>
    </div>
  );
}

export default SelectService;

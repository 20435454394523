import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Form,  Divider, Button, Grid, Loader } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import useReadUser from '../../Hooks/Users/useReadUser';
import useUpdateUser from '../../Hooks/Users/useUpdateUser';
import { MessageContext } from '../../Context/MessageContext';
import useListUserServices from '../../Hooks/Users/useListUserServices';
import useCreateUserService from '../../Hooks/Users/useCreateUserService';
import useDeleteUserService from '../../Hooks/Users/useDeleteUserService';
import useListUserTypes from '../../Hooks/Users/useListUserTypes';
import useCreateUserType from '../../Hooks/Users/useCreateUserType';
import useUpdateUserType from '../../Hooks/Users/useUpdateUserType';
import useDeleteUserType from '../../Hooks/Users/useDeleteUserType';
import useListActivityTypes from '../../Hooks/Settings/useListActivityTypes';
import { StateContext } from '../../Context/StateContext';

const EditUser = (props) => {

    const { t } = useTranslation();
    const { addMessage, removeMessage, messages } = useContext(MessageContext);
    const { id } = useParams();
    const { user, fetchingUser, readUser } = useReadUser();
    const { updateUser } = useUpdateUser();
    const { userServices, fetchingUserServices, listUserServices } = useListUserServices();
    const { createUserService } = useCreateUserService();
    const { deleteUserService } = useDeleteUserService();
    const { userTypes, fetchingUserTypes, listUserTypes } = useListUserTypes();
    const { createUserType } = useCreateUserType();
    const { updateUserType } = useUpdateUserType();
    const { deleteUserType } = useDeleteUserType();
    const { activityTypes, fetchingActivityTypes, listActivityTypes } = useListActivityTypes();
    const { locations, services, types } = useContext(StateContext);

    const [ firstname, setFirstname ] = useState('');
    const [ lastname, setLastname ] = useState('');
    const [ city, setCity ] = useState('');
    const [ username, setUsername ] = useState('');
    const [ emailAddress, setEmailAddress ] = useState('');
    const [ phoneNumber, setPhoneNumber ] = useState('');
    const [ startDate, setStartDate ] = useState('');
    const [ userActivityType, setUserActivityType ] = useState('');
    const [ mllPhoneNumber, setMllPhoneNumber ] = useState('');
    const [ mllProperty, setMllProperty ] = useState('');
    const [ description, setDescription ] = useState('');
    const [ specialDiet, setSpecialDiet ] = useState('');
    const [ nextOneToOne, setNextOneToOne ] = useState('');
    const [ keepOnMailingList, setKeepOnMailingList ] = useState(false);
    const [ setBreak, setSetBreak ] = useState(false);
    const [ breakStartDate, setBreakStartDate ] = useState('');
    const [ isActive, setIsActive ] = useState(false);
    const [ isEmployee, setIsEmployee ] = useState(false);
    const [ onCallRemote, setOnCallRemote ] = useState(false);
    const [ selectedServices, setSelectedServices ] = useState([]);
    const [ selectedTypes, setSelectedTypes ] = useState([]);
    const [ userShiftCountInPreviousSystem, setUserShiftCountInPreviousSystem ] = useState(0);

    // Fetch user
    useEffect(() => {
        (async () => {
            if (id) {
                await readUser(id);
            };
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        (async () => {
            if (id) {
                await listUserServices(id);
            };
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        (async () => {
            if (id) {
                await listUserTypes(id);
            };
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

     // Fetch activity types
     useEffect(() => {
        (async () => {
            await listActivityTypes();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    let activityTypeOptions = []
    activityTypes.map(at => {
        const tmp = {
            key: at.Id,
            text: at.Name,
            value: at.Id
        }
        activityTypeOptions.push(tmp)
        return at;
    })

    // Set user data
    useEffect(() => {
        (async () => {
            if (user.data && user.data.Id) {
                setFirstname(user.data.FirstName || '');
                setLastname(user.data.LastName || '');
                setCity(user.data.LocationId || '');
                setUsername(user.data.Username ||'');
                setEmailAddress(user.data.EmailAddress ||'');
                setIsActive(!user.data.IsActive ? false : true);
                setIsEmployee(!user.data.IsEmployee ? false : true);
                setOnCallRemote(user.data.IsRemoteWorker || false);
                setPhoneNumber(user.data.PhoneNumber || '');
                if(user.data.StartDate) setStartDate(new Date(user.data.StartDate).toLocaleDateString());
                setUserActivityType(user.data.UserActivityType || null);
                setMllPhoneNumber(user.data.MllPhoneNumber || '');
                setMllProperty(user.data.MllProperty || '');
                setDescription(user.data.Description || '');
                setSpecialDiet(user.data.SpecialDiet || '');
                if(user.data.NextOneToOne) setNextOneToOne(new Date(user.data.NextOneToOne).toLocaleDateString());
                setKeepOnMailingList(user.data.KeepOnMailingList || false);
                setSetBreak(user.data.BreakStartDate ? true : false);
                if(user.data.BreakStartDate) setBreakStartDate(new Date(user.data.BreakStartDate).toLocaleDateString());
                setUserShiftCountInPreviousSystem(user.data.UserShiftCountInPreviousSystem);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        (async () => {
            let array = []
            userServices.map(item => {
                array.push(item.ServiceId);
                return item;
            })
            setSelectedServices(array);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userServices]);

    useEffect(() => {
        (async () => {
            let array = []
            userTypes.map(item => {
                array.push({id: item.TypeId, present: item.Present, remote: item.Remote});
                return item;
            })
            setSelectedTypes(array);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userTypes]);

    let locationOptions = []
    locations.map(l => {
        if(l.Reference!=='remote'){
            const tmp = {
                key: l.Id,
                text: l.Name,
                value: l.Id
            }
            locationOptions.push(tmp)
        }
        return l;
    })

    const setServices = (value) => {
        const array = [...selectedServices]
        if(array.find(item => item === value)){
            // uncheck, remove item from array
            setSelectedServices(array.filter(item => item !== value))
        } else {
            // check, add item to array
            array.push(value)
            setSelectedServices(array)
        }
    }

    const setTypes = (value,type) => {
        const array = [...selectedTypes];
        const selectedItem = array.find(item => item.id === value);
        if(selectedItem){
            // type found, check if present or remote and change value
            if(type==='present'){
                selectedItem.present = !selectedItem.present;
            } else if(type==='remote') {
                selectedItem.remote = !selectedItem.remote;
            }
        } else {
            // type not found, add item to array
            if(type==='present'){
                array.push({id: value, present: true, remote: false});
            } else if(type==='remote') {
                array.push({id: value, present: false, remote: true});
            }
        }
        // save types that have present or remote selected
        setSelectedTypes(array.filter(item => item.present || item.remote));
    }

    const handleRemote = (isRemote) => {
        setOnCallRemote(isRemote)
        // delete remote types from selected user types
        let array = [...selectedTypes]
        array = array.map(item => {
            item.remote = false;
            return item;
        })
        setSelectedTypes(array)
    }

    const handleActivityChange = (value) => {
        setUserActivityType(value)

        //set isActive
        if(activityTypes.find(item => item.Id === value).RefName === 'onBreak' || activityTypes.find(item => item.Id === value).RefName === 'quit') {
            setIsActive(false)
        } else {
            setIsActive(true)
        }
    }

    const handleEmployeeChoice = () => {
        if(!isEmployee){
            addMessage({type: "warning", header: t('message.dataMayBeLost'), body: t('message.onCallDataMayBeLost')})
        }
        setIsEmployee(!isEmployee)
    }

    const handleSave = async () => {
        // remove messages
        messages.map(item => removeMessage(item.id));

        //validate
        if(!username || !firstname || !lastname || !city || !emailAddress || (!isEmployee && !userActivityType)) {
            addMessage({type: "error", header: t('message.fillInRequiredInformation'), body: t('message.requiredMarkedWithAsterisk')})
            window.scrollTo(0,0);
        } else {

            // Format dates
            let dateParts = []
            let startDateISO = null;
            if(startDate) {
                dateParts=startDate.split('.');
                startDateISO=new Date(dateParts[2] + "-" + dateParts[1].padStart(2,"0") + "-" + dateParts[0].padStart(2,"0")).toISOString();
            }
            
            let nextOneToOneISO = null;
            if(nextOneToOne) {
                dateParts=nextOneToOne.split('.');
                nextOneToOneISO=new Date(dateParts[2] + "-" + dateParts[1].padStart(2,"0") + "-" + dateParts[0].padStart(2,"0")).toISOString();
            }
            
            let breakStartDateISO = null;
            if(setBreak && breakStartDate) {
                dateParts=breakStartDate.split('.');
                breakStartDateISO=new Date(dateParts[2] + "-" + dateParts[1].padStart(2,"0") + "-" + dateParts[0].padStart(2,"0")).toISOString();
            }

            //create data
            let data = {}
            // some data is not saved for employees
            if(isEmployee){
                data = {
                    "id": parseInt(id),
                    "username": username,
                    "emailAddress": emailAddress.trim(),
                    "firstName": firstname,
                    "lastName": lastname,
                    "locationId": city,
                    "isActive": isActive,
                    "sendInvitation": true,
                    "isEmployee": isEmployee,
                    "isRemoteWorker": false,
                    "phoneNumber": phoneNumber,
                    "startDate": null,
                    "userActivityType": 0,
                    "mllPhoneNumber": '',
                    "mllProperty": '',
                    "description": '',
                    "specialDiet": '',
                    "nextOneToOne": null,
                    "keepOnMailingList": false,
                    "breakStartDate": null,
                    "userShiftCountInPreviousSystem": null
                }
            } else {
                data = {
                    "id": parseInt(id),
                    "username": username,
                    "emailAddress": emailAddress.trim(),
                    "firstName": firstname,
                    "lastName": lastname,
                    "locationId": city,
                    "isActive": isActive,
                    "sendInvitation": true,
                    "isEmployee": isEmployee,
                    "isRemoteWorker": onCallRemote,
                    "phoneNumber": phoneNumber,
                    "startDate": startDateISO,
                    "userActivityType": userActivityType,
                    "mllPhoneNumber": mllPhoneNumber,
                    "mllProperty": mllProperty,
                    "description": description,
                    "specialDiet": specialDiet,
                    "nextOneToOne": nextOneToOneISO,
                    "keepOnMailingList": keepOnMailingList,
                    "breakStartDate": breakStartDateISO,
                    "userShiftCountInPreviousSystem": parseInt(userShiftCountInPreviousSystem)
                }
            }
            //console.log(data)

            //save user
            let result = null;
            result = await updateUser(data);

            if(result.status === 400 && result.data.code === -2){
                addMessage({ type: "error", header: t('general.error'), body: t('errors.userExists') });
            }
        
            if(result.status === 200) {

                if(isEmployee){
                    // delete user services
                    selectedServices.map(item => {
                        result = deleteUserService(id,item,false);
                    })
                    // delete user types
                    userTypes.map(item => {
                        result = deleteUserType(id,item.Id,false);
                    })
                } else {
                    // save user services
                    services.map(item => {
                        if(selectedServices.find(s => s === item.Id)!==undefined && userServices.find(s => s.ServiceId===item.Id)===undefined){
                            // if selected and was not selected before, add
                            //console.log("lisätään " + item.Name)
                            data = { serviceId: item.Id }
                            result = createUserService(id,data,false);
                        } else if(selectedServices.find(s => s === item.Id)===undefined && userServices.find(s => s.ServiceId===item.Id)!==undefined){
                            // if not selected and was selected before, delete
                            //onsole.log("poistetaan " + item.Name)
                            result = deleteUserService(id,item.Id,false);
                        }
                        return item;
                    })
                    
                    // save user types
                    types.map(item => {
                        const selectedType = selectedTypes.find(s => s.id === item.Id)
                        if(selectedType!==undefined && userTypes.find(s => s.TypeId===item.Id)===undefined){
                            // if is selected and was not selected before, add
                            //console.log("lisätään " + item.Name)
                            data = { typeId: item.Id, remote: selectedType.remote, present: selectedType.present}
                            result = createUserType(id,data,false);
                        } else if(selectedType===undefined && userTypes.find(s => s.TypeId===item.Id)!==undefined){
                            // if not selected and was selected before, delete
                            //console.log("poistetaan " + item.Name)
                            result = deleteUserType(id,userTypes.find(s => s.TypeId===item.Id).Id,false);
                        } else if(selectedType!==undefined && userTypes.find(s => s.TypeId===item.Id)!==undefined){
                            // if is selected and was selected before, update
                            //console.log("päivitetään " + item.Name)
                            const userTypeId = userTypes.find(ut => ut.TypeId === item.Id).Id
                            data = { remote: selectedType.remote, present: selectedType.present}
                            result = updateUserType(id,userTypeId,data,false);
                        }
                        return item;
                    })
                }

                props.history.goBack();
            }
        }
    }

    const loadingContent = (fetchingUser || fetchingUserServices || fetchingUserTypes || fetchingActivityTypes);

    return (

            <div className='userform'>

                { loadingContent &&
                    <Loader active inline='centered'/>
                }
                {!loadingContent &&
                    <>
                    <Container className="userlist">
                        <h1>{t('heading.user')}</h1>
                        
                        <Form>

                            <Grid stackable columns={2}>
                                <Grid.Column>
                                    <h2>{t('user.lastname')}<sup><i className='icon asterisk'></i></sup></h2>
                                    <Form.Input value={lastname} onChange={(e) => setLastname(e.target.value)} />
                                
                                    <h2>{t('user.firstname')}<sup><i className='icon asterisk'></i></sup></h2>
                                    <Form.Input value={firstname} onChange={(e) => setFirstname(e.target.value)} />

                                    <h2>{t('user.city')}<sup><i className='icon asterisk'></i></sup></h2>
                                    <Form.Select value={city} options={locationOptions} onChange={(e, {value}) => setCity(value)} />

                                    <h2>{t('user.username')}<sup><i className='icon asterisk'></i></sup></h2>
                                    <Form.Input value={username} onChange={(e) => setUsername(e.target.value)} disabled />

                                    <Form.Checkbox
                                        label={t('user.employee')} 
                                        checked={isEmployee} 
                                        onChange={(e, {checked}) => handleEmployeeChoice()}
                                    />

                                    <h2>{t('user.email')}<sup><i className='icon asterisk'></i></sup></h2>
                                    <Form.Input value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />

                                    <h2>{t('user.phone')}</h2>
                                    <Form.Input value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />

                                    {!isEmployee &&
                                        <>
                                        <h2>{t('user.description')}</h2>
                                        <Form.TextArea
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                        />

                                        <h2>{t('user.specialDiet')}</h2>
                                        <Form.TextArea
                                            value={specialDiet}
                                            onChange={(e) => setSpecialDiet(e.target.value)}
                                        />

                                        <h2>{t('user.startDate')} {t('user.basicCourseStartDate')}</h2>
                                        <DateInput
                                            localization='fi'
                                            dateFormat={'DD.MM.YYYY'}
                                            value={startDate || ""}
                                            iconPosition="right"
                                            onChange={(ev, {value}) => setStartDate(value) }
                                            closable={true}
                                            popupPosition='bottom left'
                                        />
                                        </>
                                    }
                                    
                                    {isEmployee &&
                                        <Form.Checkbox
                                            label={t('user.active')} 
                                            checked={isActive} 
                                            onChange={(e, {checked}) => setIsActive(!isActive)}
                                        />
                                    }

                                </Grid.Column>
                                <Grid.Column>

                                    {!isEmployee &&
                                        <>
                                        <Form.Group inline>
                                            <h2 className='add-margin-right'>{t('user.remote')}</h2>
                                            <Form.Radio
                                                label={t('general.yes')}
                                                checked={onCallRemote}
                                                onChange={e => handleRemote(true)}
                                            />
                                            <Form.Radio
                                                label={t('general.no')}
                                                checked={!onCallRemote}
                                                onChange={e => handleRemote(false)}
                                            />
                                        </Form.Group>
                                        { onCallRemote &&
                                            <>
                                            <h2>{t('user.mllPhoneNumber')}</h2>
                                            <Form.Input value={mllPhoneNumber} onChange={(e) => setMllPhoneNumber(e.target.value)} />
                                            <h2>{t('user.mllProperty')}</h2>
                                            <Form.Input value={mllProperty} onChange={(e) => setMllProperty(e.target.value)} />
                                            </>
                                        }

                                        <h2>{t('user.services')}</h2>
                                        {services.map(item => (
                                            <Form.Checkbox
                                                key={item.Id}
                                                label={item.Name}
                                                value={item.Id}
                                                checked={selectedServices.find(s => s === item.Id)!== undefined}
                                                onChange={(e,{value}) => setServices(value)}
                                            />
                                        ))}

                                        <Grid columns={3} className='add-margin small-padding'>
                                            <Grid.Row>
                                                <Grid.Column width={4}>
                                                    <h2>{t('user.channels')}</h2>
                                                </Grid.Column>
                                                <Grid.Column width={2}>
                                                    {t('user.isPresent')}
                                                </Grid.Column>
                                                <Grid.Column>
                                                    {t('user.isRemote')}
                                                </Grid.Column>
                                            </Grid.Row>
                                            {types.filter(item => item.ServiceId !== null).map(item => (
                                                <Grid.Row>
                                                    <Grid.Column width={4}>
                                                        {item.Abbreviation}
                                                    </Grid.Column>
                                                    <Grid.Column width={2}>
                                                        <Form.Checkbox
                                                            key={item.Id + 'present'}
                                                            value={item.Id}
                                                            checked={selectedTypes.find(s => s.id === item.Id && s.present)!== undefined}
                                                            onChange={(e,{value}) => setTypes(value,'present')}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        {onCallRemote &&
                                                            <Form.Checkbox
                                                                key={item.Id + 'remote'}
                                                                value={item.Id}
                                                                checked={selectedTypes.find(s => s.id === item.Id && s.remote)!== undefined}
                                                                onChange={(e,{value}) => setTypes(value,'remote')}
                                                            />
                                                        }
                                                    </Grid.Column>
                                                </Grid.Row>
                                            ))}
                                        </Grid>

                                        <h2>{t('user.activity')}<sup><i className='icon asterisk'></i></sup></h2>
                                        <Form.Select
                                            value={userActivityType}
                                            options={activityTypeOptions}
                                            onChange={(e, {value}) => handleActivityChange(value)}
                                        />

                                        {activityTypes && userActivityType && activityTypes.find(item => item.Id === userActivityType).RefName === 'onBreak' ?
                                            <Form.Checkbox
                                                label={t('user.active')} 
                                                checked={isActive} 
                                                onChange={(e, {checked}) => setIsActive(!isActive)}
                                            />
                                        :
                                            <Form.Checkbox
                                                label={t('user.active')} 
                                                checked={isActive}
                                                disabled
                                            />
                                        }
                                        
                                        <Form.Checkbox
                                            label={t('user.keepOnMailingList')}
                                            checked={keepOnMailingList}
                                            onChange={e => setKeepOnMailingList(!keepOnMailingList)}
                                        />
                                        <Form.Checkbox
                                            label={t('user.setBreak')}
                                            checked={setBreak}
                                            onChange={e => setSetBreak(!setBreak)}
                                        />
                                        {setBreak &&
                                            <>
                                            <h2>{t('user.breakStartDate')}</h2>
                                            <DateInput
                                                localization='fi'
                                                dateFormat={'DD.MM.YYYY'}
                                                value={breakStartDate || ""}
                                                iconPosition="right"
                                                onChange={(ev, {value}) => setBreakStartDate(value) }
                                                closable={true}
                                                popupPosition='bottom left'
                                            />
                                            </>
                                        }

                                        <h2>{t('user.nextOneToOne')}</h2>
                                        <DateInput
                                            localization='fi'
                                            dateFormat={'DD.MM.YYYY'}
                                            value={nextOneToOne || ""}
                                            iconPosition="right"
                                            onChange={(ev, {value}) => setNextOneToOne(value) }
                                            closable={true}
                                            popupPosition='bottom left'
                                        />
                                        
                                        <h2>{t('user.shiftsInPreviousSystem')}</h2>
                                        <Form.Input value={userShiftCountInPreviousSystem} onChange={(e) => setUserShiftCountInPreviousSystem(e.target.value)} />
                                        </>
                                    }

                                </Grid.Column>
                            </Grid>
                        </Form>
                    </Container>
                    <Divider/>
                    <Button onClick={() => handleSave()}>{t('general.save')}</Button>
                    <Button onClick={() => props.history.goBack()}>{t('general.cancel')}</Button>
                    </>
                }
            </div>
    )

}

export default EditUser;
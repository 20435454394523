import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageContext } from '../../../Context/MessageContext';
import Axios from 'axios';
import Config from '../../../Config/Config';
import { useMsal } from "@azure/msal-react";

const useUpdateOneToOne = () => {

    const rootPath = Config.api.rootPath;
    const { instance } = useMsal();
    const { t } = useTranslation();
    const { addMessage, removeMessage, messages } = useContext(MessageContext);
    
    /** update one to one
    * @returns {array} - array of users */ 
    const updateOneToOne = async (userID,oneToOneID,body,showSuccess=true) => {
        try {

            messages.map(msg => removeMessage(msg.id));

            const msgId = addMessage({ type: "saving", header: t('general.moment'), body: t('info.savingOneToOne')});
            
            const activeAccount = instance.getActiveAccount();
            const accounts = instance.getAllAccounts();

            if (!activeAccount && accounts.length === 0) return;

            const request = {
                scopes: Config.api.scopes,
                account: activeAccount || accounts[0]
            }

            const authResult = await instance.acquireTokenSilent(request);

            const options = {
                "headers": {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + authResult.accessToken
                }
            }

            let result = null;

            result = await Axios.put(rootPath + "/api/v1/users/" + userID + '/onetoones/' + oneToOneID, body, options);

            removeMessage(msgId);

            if (result && result.status === 200) {
                addMessage({ type: "success", header: t('general.saved'), body: t('info.oneToOneSaved') });
                return result;
            }

            addMessage({ type: "error", header: t('general.error'), body: t('errors.savingOneToOneFailed') });
            window.scrollTo(0,0);
            return result;

        } catch (error) {
            if (Config.environment === 'development' || Config.environment === 'test') console.log(error);
            addMessage({ type: "error", header: t('general.error'), body: t('errors.savingOneToOneFailed') });
            window.scrollTo(0,0);
            if(error && error.response){
                return error.response;
            }
            return error;
        }
    }

    return { updateOneToOne };

}

export default useUpdateOneToOne;

import React from 'react';
import { Container } from 'semantic-ui-react';

const Content = (props) => {

    return (
        <Container className="app-page">
            {props.children}
        </Container>
    );

}

export default Content;
